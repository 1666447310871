import React from "react";

import { RollListItem } from "./RollListItem";
import { sortByPosition } from "../../../utils/utils";


export const RollList = ({ products }) => {

  return (
    <ul className="main-goods__list">
      {products.toList().sort(sortByPosition).map(product => (
        <RollListItem roll={product} key={product.get('id')}/>
      ))}
    </ul>
  )
};
