import { createAction } from "@reduxjs/toolkit";

import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  DELETE_ADDRESS,
  DELETE_ADDRESS_FAILED,
  DELETE_ADDRESS_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_FAILED,
  LOGOUT_SUCCESS,
  REQUEST_PASSWORD
} from "./customerConstants";


export const login = createAction(LOGIN);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginFailed = createAction(LOGIN_FAILED);

export const logout = createAction(LOGOUT);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const logoutFailed = createAction(LOGOUT_FAILED);

export const deleteAddress = createAction(DELETE_ADDRESS);
export const deleteAddressSuccess = createAction(DELETE_ADDRESS_SUCCESS);
export const deleteAddressFailed = createAction(DELETE_ADDRESS_FAILED);

export const changePassword = createAction(CHANGE_PASSWORD);
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS);
export const changePasswordFailed = createAction(CHANGE_PASSWORD_FAILED);

export const requestPassword = createAction(REQUEST_PASSWORD);
