import React from "react";
import {useSelector} from "react-redux";

import {DessertProductQuantity} from "./DessertProductQuantity";
import {cartSelectors} from "../../cart/_core/cartSelectors";
import {WidthRatioImageContainer} from "../../common/ratioContainers/WidthRatioImageContainer";
import {titleFormatter} from "../../../utils/titleFormatter";
import {DessertProductPrice} from "./DessertProductPrice";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";
import {useHistory} from "react-router-dom";


export const DessertListItem = ({dessert, freeCookieCount}) => {
    const history = useHistory();
    const open = () => history.push(`/dessert-detail/${dessert.id}`);
    const cartItem = useSelector(state => cartSelectors.cartItem(state, dessert));
    const productInCart = cartItem.count ? 'incart' : 'none';

    return (
        <li className="secondary-goods__list-item" data-item={productInCart}>
            <div className="secondary-goods__item">
                <h3 className="secondary-goods__title">
                    <div>
                        <span>{titleFormatter(dessert.title)}</span>
                        {
                            !!freeCookieCount && dessert.subtype === PRODUCT_TYPES.dessert.subtypes.cookie.id
                            && <span className="secondary-goods__free">{freeCookieCount}</span>
                        }
                    </div>
                </h3>
                <DessertProductPrice dessert={dessert} freeCookieCount={freeCookieCount}/>
                <WidthRatioImageContainer
                    ratio={1}
                    publicImages={dessert.publicImages}
                    type="main"
                    onClick={open}
                    className="main-goods__picture"/>


                <DessertProductQuantity product={dessert}/>
                <article className="main-goods__chars">
                    <p>{dessert.get('announce')}</p>
                </article>
            </div>
        </li>
    );
};
