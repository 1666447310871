import { CART_UPDATE_REQUESTED, CART_UPDATED, CART_UPDATED_WITH_ERROR } from "./cartConstants";
import { LOAD_HOUSES } from "../../catalogs/_core/catalogConstants";
import { CartModel } from "../../../models/Cart/CartModel";


const initialState = new CartModel();

export const cartReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CART_UPDATE_REQUESTED:
      return state.set('updating', true).set('status', {error: null, warning: null});

    case CART_UPDATED:
      return payload.set('updating', false);

    case CART_UPDATED_WITH_ERROR:
      return state.set('updating', false);

    case LOAD_HOUSES:
      return state.set('status', {error: null, warning: null});

    default:
      return state
  }
};
