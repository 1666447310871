import { __, toBool } from "../../utils/utils";
import { CartItemModel } from "../../models/Cart/CartItemModel";
import { CartItemMapModel } from "../../models/Cart/CartItemsMapModel";


export const orderItemCreator = (data) => {
  return new CartItemModel({
    id: __(data.product_id),
    code: __(data.code),

    title: __(data.title),
    type: __(data.product_type),
    subtype: __(data.product_subtype),
    announce: __(data.announce),
    size: __(data.item_size),

    isOnTrade: toBool(data.is_on_trade), //это можно купить (т.е. сейчас гипотетически может участвовать в торговых отношениях)
    isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart), //это разрешено купить с указанными параметрами

    count: 1,
    productPrice: __(data.product_price) / 10000,
    discountAmount: __(data.discount_amount) / 10000,
    discountPercentSuggested: __(data.discount_percent_suggested) / 10000,
    realizationPrice: __(data.realization_price) / 10000,
  });
}

export const orderItemMapCreator = data => {
  const cartItemMap = new CartItemMapModel();

  const items = cartItemMap.withMutations(map => {
    Object.values(data).forEach(item => {

      const type = item.product_subtype || item.product_type;
      const itemKey = `${item.product_id}-${item.item_size}`;
      const cartProductTypeMap = map.get(type);
      const cartProduct = cartProductTypeMap.get(itemKey);

      if (cartProduct) {
        map.set(type, cartProductTypeMap.set(itemKey, cartProduct.addItem(orderItemCreator(item))));
      } else {
        map.set(type, cartProductTypeMap.set(itemKey, orderItemCreator(item)));
      }
    })
  });

  return items;
};
