import React from "react";

import { Image } from "../image/Image";

import "./ratio-container.css";


export const WidthRatioImageContainer = ({ ratio, publicImages, type, onClick, style, className }) => {
  return (
    <div
      className={`width-ratio-container ${className}`}
      style={{ paddingBottom: `${100 / ratio}%`, ...style }}
      onClick={onClick}
    >
      <Image className="width-ratio-container__content"
						 publicImages={publicImages}
						 type={type}
			/>
		</div>
	);
};
