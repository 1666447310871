import { fetchApiProcess } from "../../utils/http";
import { rollsProcess } from "./creators/rollCreators";
import { garnishesProcess } from "./creators/garnisheCreators";
import { drinksProcess } from "./creators/drinkCreators";
import { dessertsProcess } from "./creators/dessertCreators";
import { ingredientsProcess } from "./creators/ingredientCreators";
import { warmersProcess } from "./creators/warmersCreators";
import {PRODUCT_PSEUDO_TYPES, PRODUCT_TYPES} from "./productBaseConstants";
import { pokesProcess } from "./creators/pokeCreators";
import { soupsProcess } from "./creators/soupCreators";
import { bentosProcess } from "./creators/bentoCreators";
import { rollsetsProcess } from "./creators/rollsetCreators";
import {suggestedProductProcess} from "./creators/suggestedProductCreators";

const productsRoutes = {
  [PRODUCT_TYPES.roll.id]: 'products/rolls',
  [PRODUCT_TYPES.rollset.id]: 'products/rollsets',
  [PRODUCT_TYPES.poke.id]: 'products/pokes',
  [PRODUCT_TYPES.soup.id]: 'products/soups',
  [PRODUCT_TYPES.bento.id]: 'products/bentos',
  [PRODUCT_TYPES.garnish.id]: 'products/garnishes',
  [PRODUCT_TYPES.drink.id]: 'products/drinks',
  [PRODUCT_TYPES.dessert.id]: 'products/desserts',
  [ PRODUCT_TYPES.warmer.id ]: 'products/warmers',

  [PRODUCT_PSEUDO_TYPES.filter.id]: 'products/filters',
  [PRODUCT_PSEUDO_TYPES.ingredient.id]: 'products/filters',
  [PRODUCT_TYPES.suggestedProduct.id]: 'products/suggested-products',
};

const product = (type, process, params = null) => {
  if (params === null) {
    params = { params: { order: 'position:asc', } }
  }

  return fetchApiProcess(
    productsRoutes[type], process, params
  );
};

const suggestedProducts = () => product([ PRODUCT_TYPES.suggestedProduct.id ], suggestedProductProcess, {version: 'v4.0.0'})
const rolls = () => product([ PRODUCT_TYPES.roll.id ], rollsProcess, { version: 'v4.0.0' });
const rollsets = () => product([ PRODUCT_TYPES.rollset.id ], rollsetsProcess, { version: 'v4.0.0' });
const pokes = () => product([ PRODUCT_TYPES.poke.id ], pokesProcess, { version: 'v4.0.0' });
const soups = () => product([ PRODUCT_TYPES.soup.id ], soupsProcess, { version: 'v4.0.0' });
const bentos = () => product([ PRODUCT_TYPES.bento.id ], bentosProcess, { version: 'v4.0.0' });
const garnishes = () => product([ PRODUCT_TYPES.garnish.id ], garnishesProcess, { version: 'v4.0.0' });
const drinks = () => product([ PRODUCT_TYPES.drink.id ], drinksProcess, { version: 'v4.0.0' });
const desserts = () => product([ PRODUCT_TYPES.dessert.id ], dessertsProcess, { version: 'v4.0.0' });
const warmers = () => product([ PRODUCT_TYPES.warmer.id ], warmersProcess, { version: 'v4.0.0' });

const ingredients = () => fetchApiProcess(
  'products/ingredients', ingredientsProcess, { params: { order: 'title:asc', } }
);

const filters = () => fetchApiProcess(
  'products/filters', ingredientsProcess, { params: { order: 'title:asc', } }
);

export const productCallers = {
  rollsets,
  rolls,
  pokes,
  soups,
  bentos,
  garnishes,
  drinks,
  desserts,
  ingredients,
  filters,
  warmers,
  suggestedProducts,
};


