import React from "react";
import {Provider} from "react-redux";
import * as Sentry from '@sentry/react';

import {store} from "../../../../store/redux/reduxStore";
import {AppEntry} from "./AppEntry";
import {enabledSentry, initSentry} from "../../../sentry";

const RootEntry = () => {
	if (enabledSentry) {
		initSentry();
	}

	return (
		<Provider store={store}>
			<Sentry.ErrorBoundary>
				<AppEntry/>
			</Sentry.ErrorBoundary>
		</Provider>
	)
};

export default RootEntry
