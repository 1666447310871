import { OrderedMap } from "immutable";

import { StreetModel } from "../../../models/Address/Street/StreetModel";


export const streetsProcess = data => OrderedMap().withMutations(map => {
  if (data) {
		Object.entries(data).forEach(([id, item]) => map.set(id, streetCreator(id, item)));
  }
});

export const streetCreator = (id, item) => new StreetModel({
  value: id,
  label: item
});
