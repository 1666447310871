import React, {useState} from "react";

import {RollsetListItem} from "./RollsetListItem";
import {sortByPosition} from "../../../utils/utils";
import {useSelector} from "react-redux";
import {productSelectors} from "../_core/productSelectors";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";


export const RollsetList = (showAll = false) => {
    const rollsets = useSelector(productSelectors[PRODUCT_TYPES.rollset.id]);
    const [state, setState] = useState({
        showAll: false,
    });
    const handleShowAll = () => {
        setState({
            showAll: !state.showAll,
        })
    };

    const renderCollapseDetailStatus = () => {
        return state.showAll ? <span className='triangle-open-all'></span> :
            <span className='triangle-close-all'></span>
    }

    const screenWidth = window.innerWidth
    let limit = 3;
    if (!state.showAll) {
        if (screenWidth < 480) {
            limit = 2;
        } else if (screenWidth <= 580) {
            limit = 3;
        }
    }

    let rollsetsList = rollsets.toList().sort(sortByPosition)
    if (!state.showAll) {
        rollsetsList = rollsetsList.slice(0, limit)
    }

    let collapseLabel = !state.showAll ? "показать все сеты" : "скрыть сеты";

    return (
        <div>
            <ul className="main-goods__list">
                {rollsetsList.map(product => (
                    <RollsetListItem rollset={product} key={product.get('id')}/>
                ))}
            </ul>

            <div className="btn-show-all-wrapper">
                <p onClick={handleShowAll} className='collapse-description-wrapper'>
                    <span>{collapseLabel}</span> {renderCollapseDetailStatus()}
                </p>
            </div>
        </div>
    )
};
