import {fetchApi, fetchApiProcess} from "../../utils/http";
import {settingsModelCreator} from "./settingCreators";
import {slideProcess} from "./slideCreators";
import {paymentProcess} from "./paymentCreators";
import {vacancyMapProcess} from "./vacancyCreators";
import {answersMapProcess} from "./answersCreators";
import {blockProductMapProcess} from "./blockProductCreators";


export const systemCallers = {
	settings: () => fetchApiProcess('settings', settingsModelCreator, {
		version: 'v4.0.0'
	}),
	slides: () => fetchApiProcess(['content', 'slides'], slideProcess, {
		version: 'v4.0.0'
	}),
	content: () => fetchApi(['content', 'static'], {
		version: 'v4.0.0'
	}),

	payments: () => fetchApiProcess(['content', 'payments'], paymentProcess, {
		version: 'v4.0.0'
	}),

	vacancies: () => fetchApiProcess(['content', 'vacancies'], vacancyMapProcess, {
		version: 'v4.0.0'
	}),

	answers: () => fetchApiProcess(['content', 'answers'], answersMapProcess, {
		version: 'v4.0.0'
	}),

	blockProducts: () => fetchApiProcess(['content', 'block-products'], blockProductMapProcess, {
		version: 'v4.0.0'
	}),
};
