import { all, call, put, takeEvery } from "redux-saga/effects";

import { api } from "../../../api/api";
import { featureSagaCreator } from "../../../utils/featureSagaCreator";
import {
	FETCH_PREORDER_INTERVALS, RESET_PREORDER_INTERVALS,
} from "./preorderConstants";
import { preorderIntervalLoaded, resetPreorderIntervals } from "./preorderActions";

export function* resetPreorderIntervalsSaga()  {
	yield put(resetPreorderIntervals());
}

export function* preorderIntervalsSaga() {
	const {result} = yield call(api.preorder.intervals);
	// todo add with error handle
	if (result) {
		yield put(preorderIntervalLoaded(result));
	}
}

export const preorderIntervalSaga = featureSagaCreator({
	init: preorderIntervalsSaga,
	workers: function* () {
		yield all([
			takeEvery(FETCH_PREORDER_INTERVALS, preorderIntervalsSaga),
			takeEvery(RESET_PREORDER_INTERVALS, preorderIntervalsSaga),
		])
	},
});
