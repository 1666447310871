import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { settingSelectors } from "../components/settings/_core/settingSelectors";
import { SectionTitle } from "../components/common/sectionTitle/SectionTitle";


export const PaymentFailurePage = () => {

  const content = useSelector(settingSelectors.content);

  const history = useHistory();
  if (history.location.search) {
    history.replace('/online/fail');
  }

  return (
    <div className="content-page">
      <SectionTitle title="оплата по заказу не завершена"/>
      <div dangerouslySetInnerHTML={{ __html: content.order_message_online_error }}/>
      <br/>
    </div>
  );
};
