import { OrderedMap } from "immutable";

import { __ } from "../../utils/utils";
import VacancyModel from "../../models/Vacancy/VacancyModel";


export const vacancyModelCreator = data => new VacancyModel({
  active: __(data.active),
  description: __(data.description),
  id: __(data.id),
  position: __(data.position),
  title: __(data.title),
});

export const vacancyMapProcess = data => OrderedMap().withMutations(vacancyMap => {
  Object.values(data).forEach(vacancy => vacancyMap.set(__(vacancy.id), vacancyModelCreator(vacancy)))
});
