import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import plural from "plural-ru";
import { productSelectors } from "../_core/productSelectors";
import { DessertList } from "./DessertList";
import { SectionTitle } from "../../common/sectionTitle/SectionTitle";
import { Anchor } from "../../common/Anchor";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import {catalogSelectors} from "../../catalogs/_core/catalogSelectors";
import {SectionDescription} from "../../common/sectionDescription/SectionDescription";
import {cartSelectors} from "../../cart/_core/cartSelectors";


import {Infobox} from "../../common/notifications/infobox/Infobox";
import { closePopup, openPopup } from "../../common/popups/_core/popupActions";
import {POPUP_COMPONENTS} from "../../common/popups/popupsConfig";
import {useHistory} from "react-router-dom";
import {pageSelectors} from "../../../pages/_core/pagesSelectors";

export const DessertSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const popup  = useSelector(pageSelectors.popup);
    const cakes = useSelector(productSelectors[PRODUCT_TYPES.dessert.subtypes.cake.id]);
    const cookie = useSelector(productSelectors[PRODUCT_TYPES.dessert.subtypes.cookie.id]);
    const desertList = cakes.merge(cookie)
    const pies = useSelector(productSelectors[PRODUCT_TYPES.dessert.subtypes.pie.id]);
    let sectionDescription = useSelector(catalogSelectors.dessertSectionDescription)
    const freeItemsRemaining = useSelector(cartSelectors.additionalData).freeItemsRemaining;
    const freeCookieCount = freeItemsRemaining.get('cookie')
    const displayPies = !!pies.size
    const displayHint = !!freeCookieCount
    const nbsp = '\xa0';

    useEffect(() => {
        if (history.location.pathname.includes('dessert')) {
            const dessertId = history.location.pathname.split('/')[2];
            dispatch(openPopup({
                type: POPUP_COMPONENTS.dessertPopup.type,
                props: { dessertId }
            }))
        } else {
            if (popup.type === POPUP_COMPONENTS.dessertPopup.type) {
                dispatch(closePopup())
            }
        }
    }, [history.location.pathname])

    return (
        <>
            <div className="secondary-goods">
                <Anchor id="dessert" top={-120}/>
                {displayPies && <DessertList productList={pies} freeCookieCount={0}/>}
                <SectionTitle title="десерты"/>
                         {sectionDescription && sectionDescription.description && <SectionDescription description={sectionDescription.description}/>}

                {displayHint && (
                    <Infobox className="notification notification--goods">
                        {`в вашем заказе ${freeItemsRemaining.get('cookie')}${nbsp}${plural(freeItemsRemaining.get('cookie'), 'комплект', 'комплекта', 'комплектов')} суп и поке лайт, вы можете выбрать `}
                        {!!freeItemsRemaining.get('cookie') &&
                            `${freeItemsRemaining.get('cookie')}${nbsp}${plural(freeItemsRemaining.get('cookie'), 'порцию', 'порции', 'порций')} рыбок с малиновым кремом`
                        }

                        {` по цене 1${nbsp}копейка`}
                    </Infobox>
                )}
                
                <DessertList productList={desertList} freeCookieCount={freeCookieCount}/>
            </div>
        </>
    )
}
