import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { addItemToCart, removeItemFromCart } from "../../cart/_core/cartActions";
import { cartSelectors } from "../../cart/_core/cartSelectors";
import { Error } from "../../common/notifications/error/Error";


export const DefaultNotInStockComponent = () => (
  <div className="main-goods-quantity">
    <Error descriptionClassName="fs-m" description="нет в наличии"/>
  </div>
);

export const DefaultNotAvailableForAddressComponent = () => (
  <div className="main-goods-quantity">
    <Error descriptionClassName="fs-m" description="недоступно для заказа по данному адресу"/>
  </div>
);

export const ProductQuantity = ({ product, size, count, children, isSuggested = 0 }) => {
  const dispatch = useDispatch();
  const cartItem = useSelector(s => cartSelectors.cartItem(s, product));

  const handleAddItem = () => {
    dispatch(addItemToCart({
      type: product.get('type'),
      subtype: product.get('subtype'),
      id: product.get('id'),
      size: size,
      isSuggested: isSuggested
    }))
  };

  const handleRemoveItem = () => {
    if (!count && !cartItem.count) {
    	return null;
    }


    dispatch(removeItemFromCart({
      type: product.get('type'),
      subtype: product.get('subtype'),
      id: product.get('id'),
      size: size,
    }))
  };

  const child = React.cloneElement(children, {
    handleRemoveItem,
    handleAddItem,
    count: count || cartItem.count || 0,
  });

  return (
    <>
      {child}
    </>
  )
};
