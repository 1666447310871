import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Anchor } from "../../common/Anchor";
import { RollsetList } from "./RollsetList";
import { SectionTitle } from "../../common/sectionTitle/SectionTitle";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import { closePopup, openPopup } from "../../common/popups/_core/popupActions";
import { POPUP_COMPONENTS } from "../../common/popups/popupsConfig";
import { pageSelectors } from "../../../pages/_core/pagesSelectors";
import { productSelectors } from "../_core/productSelectors";
import { catalogSelectors } from "../../catalogs/_core/catalogSelectors";
import { SectionDescription } from "../../common/sectionDescription/SectionDescription";


export const RollsetSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const popup = useSelector(pageSelectors.popup);
  const rollsets = useSelector(productSelectors[PRODUCT_TYPES.rollset.id]);

  useEffect(() => {
    if (history.location.pathname.includes('rollset-detail')) {
      const rollsetId = history.location.pathname.split('/')[2];
      dispatch(openPopup({
        type: POPUP_COMPONENTS.rollsetPopup.type,
        props: {rollsetId}
      }))
    } else {
      if (popup.type === POPUP_COMPONENTS.rollsetPopup.type) {
        dispatch(closePopup())
      }
    }
  }, [history.location.pathname])
  let sectionDescription = useSelector(catalogSelectors.rollsetSectionDescription)

  return (
    <>
      {!!rollsets.size && <div className="main-goods">
        <Anchor id={PRODUCT_TYPES.rollset.id} top={-120}/>
        <SectionTitle title={PRODUCT_TYPES.rollset.multy}/>
        <RollsetList/>
        {sectionDescription && sectionDescription.description &&<SectionDescription description={sectionDescription.description}/>}
      </div>}
    </>
  );
}
