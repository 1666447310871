import React from "react";
import {useSelector} from "react-redux";

import {PickupMap} from "../components/maps/PickupMap";
import {SectionTitle} from "../components/common/sectionTitle/SectionTitle";
import {settingSelectors} from "../components/settings/_core/settingSelectors";
import {scrollToTop} from "../utils/scrollToAnchor";


export const PickupPage = () => {
	const content = useSelector(settingSelectors.content);
	scrollToTop()

	return (
		<div className="content-page">
			<SectionTitle title="пункты самовывоза"/>
			<div dangerouslySetInnerHTML={{__html: content.pickup_contacts}}/>
			<PickupMap/>
			<div dangerouslySetInnerHTML={{__html: content.pickup}}/>
			<br/>
		</div>
	)
};
