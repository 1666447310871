import React from "react";

export const RollsetProductList = ({products}) => {
  return (
    <ul>
      {Object.entries(products.toJS())
        .map(([productType, productsData]) => Object.values(productsData).map(
          productData => <li className='rollset-item' key={productData.id}>
            {`#${productData.code} ${productData.title}`}
            &nbsp;
            {`${productData.count*8}`}
            &nbsp;
            {`шт`}
          </li>
        ))}
    </ul>
  )
};
