import Immutable from "immutable";
import {applyMiddleware, compose, createStore} from "redux";
import createSagaMiddleware from "redux-saga";

import {history} from "../../system/router/routerHistory";
import rootReducer from "../../system/root/core/rootReducer";
import rootDispatch from "../../system/root/core/rootDispatch";
import {rootSaga} from "../../system/root/core/rootSagas";
import routerMiddleware from "../../system/router/routerMiddleware";
import {sentryReduxEnhancer} from "../../system/sentry";


console.log('Store configuration: store/redux/configureReduxStore.production.jsx');

const sagaMiddleware = createSagaMiddleware();

export const prodStore = createStore(
	rootReducer(history),
	Immutable.Map(),
	compose(
		applyMiddleware(
			routerMiddleware,
			sagaMiddleware,
		),
		sentryReduxEnhancer)
);

rootDispatch.setStore(prodStore);
sagaMiddleware.run(rootSaga);
