import React from "react";

import {
	DefaultNotAvailableForAddressComponent,
	DefaultNotInStockComponent,
	ProductQuantity
} from "../productQuantity/ProductQuantity";
import { useSelector } from "react-redux";
import { settingSelectors } from "../../settings/_core/settingSelectors";


const CookieProductComponent = ({ product, handleRemoveItem, handleAddItem, count }) => {
  const settings = useSelector(settingSelectors.settings);

  if (settings.baskedDisabled) {
    return null;
  }

	if (!product.isOnTrade) {
		return (<DefaultNotInStockComponent />);
	}

	if (!product.isSuitableForCurrentCart) {
		return (<DefaultNotAvailableForAddressComponent />);
	}

  return (
    <div className="secondary-goods-quantity">
      <button className="secondary-goods-quantity__controls"
              data-button-type="minus"
              onClick={handleRemoveItem}>
        –
      </button>
      <strong className="secondary-goods-quantity__numbers">{count}</strong>
      <button className="secondary-goods-quantity__controls"
              data-button-type="plus"
              onClick={handleAddItem}
      >+
      </button>
    </div>
  )
};

export const CookieProductQuantity = (props) => {
  return (
    <ProductQuantity {...props}>
      <CookieProductComponent product={props.product}/>
    </ProductQuantity>
  )
};
