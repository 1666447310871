import Immutable from "immutable";

import { PRODUCT_TYPES } from "../../api/products/productBaseConstants";


const CartItemMapRecord = Immutable.Record({
  [PRODUCT_TYPES.roll.id]: Immutable.Map(),
  [PRODUCT_TYPES.rollset.id]: Immutable.Map(),
  [PRODUCT_TYPES.dessert.id]: Immutable.Map(),
  [PRODUCT_TYPES.poke.id]: Immutable.Map(),
  [PRODUCT_TYPES.bento.id]: Immutable.Map(),
  [PRODUCT_TYPES.soup.id]: Immutable.Map(),
  [PRODUCT_TYPES.drink.id]: Immutable.Map(),

  [PRODUCT_TYPES.dessert.subtypes.cake.id]: Immutable.Map(),
  [PRODUCT_TYPES.dessert.subtypes.cookie.id]: Immutable.Map(),
  [PRODUCT_TYPES.dessert.subtypes.pie.id]: Immutable.Map(),

  [PRODUCT_TYPES.poke.subtypes.standard.id]: Immutable.Map(),
  [PRODUCT_TYPES.poke.subtypes.light.id]: Immutable.Map(),
  [PRODUCT_TYPES.poke.subtypes.sashimi.id]: Immutable.Map(),

  [PRODUCT_TYPES.garnish.subtypes.garnish.id]: Immutable.Map(),
  [PRODUCT_TYPES.garnish.subtypes.sauce.id]: Immutable.Map(),
  [PRODUCT_TYPES.garnish.subtypes.stick.id]: Immutable.Map(),

  [PRODUCT_TYPES.warmer.subtypes.coffee.id]: Immutable.Map(),
  [PRODUCT_TYPES.warmer.subtypes.tea.id]: Immutable.Map(),
  [PRODUCT_TYPES.warmer.subtypes.other.id]: Immutable.Map(),
});

export class CartItemMapModel extends CartItemMapRecord {
  //
}
