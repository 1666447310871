import { createAction } from "@reduxjs/toolkit";

import {
	ADD_ITEM_TO_CART,
	APPLY_ADDRESS,
	APPLY_PICKUP,
	CART_PROCESSED,
	CART_PROCESSED_WITH_ERROR,
	CART_PROCESSING,
	CART_PROCESSING_APPROVED,
	CART_UPDATE_REQUESTED,
	CART_UPDATED,
	CART_UPDATED_WITH_ERROR,
	REMOVE_ITEM_FROM_CART,
	REMOVE_ITEM_GROUP_FROM_CART,
	SET_CART_ADDRESS,
	STREET_CHANGED,
	UPDATE_CART_ADDRESS,
	UPDATE_CART_CLIENT_NAME,
	UPDATE_CART_CLIENT_PHONE,
	UPDATE_CART_COMMENT,
	UPDATE_CART_ENTRANCE,
	UPDATE_CART_FLAT,
	UPDATE_CART_FLOOR,
	UPDATE_CART_INTERCOM,
	UPDATE_CART_NO_CONTACT,
	UPDATE_CART_PAYMENT,
	UPDATE_CART_PICKUP_DELAY,
	UPDATE_CART_PREORDER,
	UPDATE_CART_PREPARE_CHARGE,
	TOGGLE_CART_CONFIRM_PRIVACY_POLICY_RULES,
	TOGGLE_CART_CONFIRM_PRIVACY_POLICY_ORDER_DATA,
	TOGGLE_CART_CONFIRM_PRIVACY_POLICY_SMS_PROCESS_ORDER,
	TOGGLE_CART_CONFIRM_PRIVACY_POLICY_FEEDBACK_SERVICE,
} from "./cartConstants";


export const applyDelivery = createAction(APPLY_PICKUP);
export const applyAddress = createAction(APPLY_ADDRESS);
export const streetChanged = createAction(STREET_CHANGED);

export const addItemToCart = createAction(ADD_ITEM_TO_CART);
export const removeItemFromCart = createAction(REMOVE_ITEM_FROM_CART);
export const removeItemGroupFromCart = createAction(REMOVE_ITEM_GROUP_FROM_CART);

export const cartProcessed = createAction(CART_PROCESSED);
export const cartProcessedWithError = createAction(CART_PROCESSED_WITH_ERROR);
export const cartProcessing = createAction(CART_PROCESSING);
export const cartProcessingApproved = createAction(CART_PROCESSING_APPROVED);

export const updateCartAddress = createAction(UPDATE_CART_ADDRESS);
export const setCartAddress = createAction(SET_CART_ADDRESS);
export const updateCartFlat = createAction(UPDATE_CART_FLAT);
export const updateCartFloor = createAction(UPDATE_CART_FLOOR);
export const updateCartEntrance = createAction(UPDATE_CART_ENTRANCE);
export const updateCartIntercom = createAction(UPDATE_CART_INTERCOM);

export const updateCartClientName = createAction(UPDATE_CART_CLIENT_NAME);
export const updateCartClientPhone = createAction(UPDATE_CART_CLIENT_PHONE);

export const updateCartNoContact = createAction(UPDATE_CART_NO_CONTACT);
export const updateCartPickupDelay = createAction(UPDATE_CART_PICKUP_DELAY);
export const updateCartPreorder = createAction(UPDATE_CART_PREORDER);
export const updateCartComment = createAction(UPDATE_CART_COMMENT);

export const updareCartPayment = createAction(UPDATE_CART_PAYMENT);
export const updareCartPrepareCharge = createAction(UPDATE_CART_PREPARE_CHARGE);

export const cartUpdateRequested = createAction(CART_UPDATE_REQUESTED);
export const cartUpdated = createAction(CART_UPDATED);
export const cartUpdateWithError = createAction(CART_UPDATED_WITH_ERROR);


export const toggleCartConfirmPrivacyPolicyRules = createAction(TOGGLE_CART_CONFIRM_PRIVACY_POLICY_RULES)
export const toggleConfirmPrivacyPolicyOrderData = createAction(TOGGLE_CART_CONFIRM_PRIVACY_POLICY_ORDER_DATA);
export const toggleConfirmPrivacyPolicySmsProcessOrder = createAction(TOGGLE_CART_CONFIRM_PRIVACY_POLICY_SMS_PROCESS_ORDER);
export const toggleConfirmPrivacyPolicyFeedbackService = createAction(TOGGLE_CART_CONFIRM_PRIVACY_POLICY_FEEDBACK_SERVICE);

