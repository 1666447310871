import React from "react";

import { SectionTitle } from "../components/common/sectionTitle/SectionTitle";
import { AnswersList } from "../components/answers/AnswersList";


export const AnswersPage = () => {
        return (
            <div className="content-page">
                <SectionTitle title="вопросы и ответы" />
                <AnswersList/>
            </div>
        );
    }
;


