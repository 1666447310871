import { PAYMENT_METHOD } from "../formComponents/PaymentInput";
import {CART_TIME_TYPES} from "../../_core/cartConstants";
import moment from "moment";


export const orderValidation = {
	confirmPrivacyPolicy: ({cart = ''}) => {
		return !cart.customerInfo.isConfirmPrivacyPolicyRules;
	},

	invalidPhone: ({cart = ''}) => {
		return !cart.customerInfo.phone.slice(4).match(/^(?:17|25|29|33|44)(?:[0-9]{7})$/);
	},

	isOurPhone: ({cart = ''}) => {
		return !cart.customerInfo.phone.slice(4).match(/^(?:17|25|29|33|44)(?=(?!7556655))(?=(?!6040000))[0-9]{7}$/);
	},

	emptyName: ({cart = ''}) => {
		return !cart.customerInfo.name;
	},

	emptyAddress: ({cart = {}}) => {
		return !(cart.shipping.address.street && cart.shipping.address.house);
	},

	emptyPayment: ({cart = {}}) => {
    return !cart.priceSpecification.paymentType;
  },

  noTimeForPickup: ({cart = {}}) => {
    return (cart.shipping.shippingType === 'pickup') &&
			(cart.shipping.timeDetails.timeType === CART_TIME_TYPES.runtime) && !cart.shipping.timeDetails.delay;
  },

  noValidPaymentMethod: ({cart = {}}) => {
			return cart.shipping.noContactOnDelivery &&
				( cart.priceSpecification.paymentType === PAYMENT_METHOD.cash.id ||
					cart.priceSpecification.paymentType === PAYMENT_METHOD.bank_card.id )
  },

	notAvailablePreorderTime: ({cart = {}, preorderIntervals = {}}) => {
		if (cart.shipping.timeDetails.timeType === 'preorder') {
			const preorderDateTime = moment(cart.shipping.timeDetails.date + ' ' + cart.shipping.timeDetails.time)
			debugger
			if (
				preorderIntervals?.data[cart.shipping.timeDetails.date]
				&& preorderIntervals?.data[cart.shipping.timeDetails.date][preorderDateTime.format('HH')]
				&& preorderIntervals?.data[cart.shipping.timeDetails.date][preorderDateTime.format('HH')][preorderDateTime.format('mm')]
				&& preorderIntervals?.data[cart.shipping.timeDetails.date][preorderDateTime.format('HH')][preorderDateTime.format('mm')].delivery
			) {
				return false
			}

			return true
		}

		return false;
	}
};


export const applyOrderValidationRules = (rulesArray, object) => {
  let validationPassed = true;
  let resultErrorMessage = '';
  rulesArray.forEach(([ rule, errorMessage ]) => {
    if (validationPassed && rule(object)) {
      validationPassed = false;
      resultErrorMessage = errorMessage;
    }
  });
  return { validationPassed, errorMessage: resultErrorMessage };
};
