import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTES } from "../../../../system/router/routeConstants";
import { catalogSelectors } from "../../../catalogs/_core/catalogSelectors";
import {Image} from "../../image/Image";


export const InformationLinks = () => {
	const vacancies = useSelector(catalogSelectors.vacancies);

	return (
		<div className="my-2 tab:my-4 m-auto w-[300px] tab:w-[436px] flex justify-between text-t12 tab:text-t18">
			<div>
				<Link className="hover:text-textHover" to={{pathname: ROUTES.deliveryZone.path}} >
					зона доставки
				</Link>
			</div>
			<div>
				<Link className="hover:text-textHover" to={{pathname: ROUTES.pickup.path}} >
					самовывоз
				</Link>
			</div>
			<div>
				<Link className="hover:text-textHover" to={ROUTES.information.path}>
					информация
				</Link>
			</div>
			{!!vacancies.size && (
				<div>
					<a className="hover:text-textHover" href={'https://v.pzz.by/'}>
						вакансии
						<div
							className="ml-1 inline-block font-Roboto inline-block bg-counter rounded-full w-[17px] h-[17px] tab:w-[24px] tab:h-[24px] text-textWhite text-t12 tab:text-t18 text-center font-bold">
							<div className="inline-block relative top-[2px] tab:top-[1px]">{vacancies.size}</div>
						</div>
					</a>
				</div>
			)}
		</div>
	)
}
