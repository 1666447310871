import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { updateCartPickupDelay } from "../../_core/cartActions";
import { cartSelectors } from "../../_core/cartSelectors";
import { ToEntranceWarnings } from "../validationComponents/ToEntranceWarnings";
import { IsPaymentDeliveryWarning } from "../validationComponents/IsPaymentDeliveryWarning";
import { Error } from "../../../common/notifications/error/Error";
import { CART_SHIPPING_TYPES } from "../../_core/cartConstants";
import {catalogSelectors} from "../../../catalogs/_core/catalogSelectors";

const TIME_OPTIONS = ['15', '20', '25', '30', '35', '40', '45', '50'];

const getAvailableDeliveryTime = (currentManufacture) => {
	const timeArray = [
		...(currentManufacture?.isPickupSlowed ? [] : ['15', '20']),
		...['25', '30', '35', '40', '45', '50']
	];

	if (!currentManufacture?.workingTimeInfo) {
		return timeArray.map(item => ({
			value: item,
			disabled: false,
		}));
	}

	const availableTime = timeArray.filter(time => {
		const openedAt = moment(currentManufacture.workingTimeInfo.pickup.openAt).add(15, 'm');
		const closedAt = moment(currentManufacture.workingTimeInfo.pickup.closeAt);
		const pickupDelayTime = moment().add(+time, 'm');

		if (time === '25') {
			return (currentManufacture.isPickupSlowed && currentManufacture.workingTimeInfo.pickup.isOpen)
				? currentManufacture.workingTimeInfo.pickup.isOpen
				: currentManufacture.workingTimeInfo.pickup.isOpen
					? closedAt.isAfter(pickupDelayTime)
					: openedAt.isBefore(pickupDelayTime);
		}
		if (time === '15') {
			return currentManufacture.workingTimeInfo.pickup.isOpen
		}
		return currentManufacture.workingTimeInfo.pickup.isOpen
			? closedAt.isAfter(pickupDelayTime)
			: openedAt.isBefore(pickupDelayTime);
	});

	return TIME_OPTIONS.map(timeOption => ({
		value: timeOption,
		disabled: !availableTime.includes(timeOption),
	}))
};


export const PickupInput = ({setDeliveryTime}) => {
	const dispatch = useDispatch();
	const cartSettings = useSelector(cartSelectors.settings);
	const isPickup = useSelector(cartSelectors.isPickup);
	const isPreorder = useSelector(cartSelectors.isPreorder);
	const shipping = useSelector(cartSelectors.shipping);
	const manufactures = useSelector(catalogSelectors.pickupManufactures);
	const staticManufacture = manufactures.get(parseInt(cartSettings.manufactureInfo.manufactureId))
    const manufactureInfo = isPickup ? staticManufacture : cartSettings.manufactureInfo
	const selfDeliveryTime = shipping.timeDetails.delay;
	const isDelivery = shipping.shippingType === "delivery";

	const [pickupTimes, setPickupTimes] = useState(getAvailableDeliveryTime(manufactureInfo));

	useEffect(() => {
		if (!isDelivery) {
			setDeliveryTime(true);
		}
		const timerId = setInterval(() => {
			setPickupTimes(getAvailableDeliveryTime(manufactureInfo));
		}, 60 * 1000);
		return () => clearInterval(timerId);
	}, [isDelivery, setDeliveryTime, cartSettings.manufactureInfo])

	useEffect(() => {
		setPickupTimes(getAvailableDeliveryTime(manufactureInfo));
	}, [cartSettings.manufactureInfo]);

	const handleClick = (time, disabled) => () => {
		if (disabled) {
			return null;
		}
		setDeliveryTime(true);
		dispatch(updateCartPickupDelay({
			pickup_delay: time,
		}))
	};

	if (!isPickup) {
		return null;
	}


	// settings.delivery45 ? 15 : 15 conditions could be changed in future
	if (
		!(isPreorder)
		&& cartSettings.manufactureInfo.workingTimeInfo
		&& !cartSettings.manufactureInfo.workingTimeInfo.pickup.isOpen
		&& cartSettings.manufactureInfo.workingTimeInfo.pickup.openAt
	) {
		if (
			(
				shipping.shippingType === CART_SHIPPING_TYPES.pickup
				&& moment(cartSettings.manufactureInfo.workingTimeInfo.pickup.openAt).isAfter(
					cartSettings.manufactureInfo.isPickupSlowed
						? moment().add(25, 'm')
						: moment().add(35, 'm'))
			) ||
			(
				shipping.shippingType === CART_SHIPPING_TYPES.delivery
				// && (!cartSettings.manufactureInfo.onStopAt || moment(cartSettings.manufactureInfo.onStopAt).isAfter(pinnedAt))
				// && moment(cartSettings.manufactureInfo.workingTimeInfo.openAt).isAfter(
				// 	settings.delivery45
				// 		? moment().add(15 + (shipping.timeDetails.isDelayIncreased ? 30 : 0), 'm')
				// 		: moment().add(15 + (shipping.timeDetails.isDelayIncreased ? 30 : 0), 'm'))
			)
		) {
			return (
				<Error descriptionClassName="fs-m"
							 description="выберите время предварительного заказа"
							 className="p-l mb-s"/>
			)
		}
	}

	return (
		<div className="form-group">

			<ToEntranceWarnings/>
			<IsPaymentDeliveryWarning/>

			<>
				<br/>
				<div className="form-label">время самовывоза</div>
				{
					(!selfDeliveryTime && !isPreorder)
						? (<Error descriptionClassName="fs-m"
							description="укажите, через сколько минут вы планируете забрать заказ"
							className="p-l mb-s"/>) : null
				}

				<ul className="form-list-radio">
					{pickupTimes.map(time => (
						<li className="form-list-radio__list-item" key={time.value}>
							<label className="form-list-radio__item">
								<input className="form-list-radio__radio"
									   type="radio"
									   name={`payment-method-${time.value}`}
									   onChange={handleClick(time.value, time.disabled)}
									   checked={selfDeliveryTime === time.value}/>
								<span className="form-list-radio__value"
									  disabled={time.disabled}>
                                        {time.value}
                                    </span>
							</label>
						</li>
					))}
				</ul>
			</>
		</div>
	);
};
