import React from "react";
import { useSelector } from "react-redux";

import {
	DefaultNotAvailableForAddressComponent,
	DefaultNotInStockComponent,
	ProductQuantity
} from "../productQuantity/ProductQuantity";
import { settingSelectors } from "../../settings/_core/settingSelectors";


const DrinkProductComponent = ({ product, handleRemoveItem, handleAddItem, count }) => {
  const settings = useSelector(settingSelectors.settings);

  if (settings.baskedDisabled) {
    return null;
  }

	if (!product.isOnTrade) {
		return (<DefaultNotInStockComponent />);
	}

	if (!product.isSuitableForCurrentCart) {
		return (<DefaultNotAvailableForAddressComponent />);
	}

  return (
    <>
      {
        count ? (
          <div className="additional-goods-quantity">
            <button className="additional-goods-quantity__controls" data-button-type="minus"
                    onClick={handleRemoveItem}>–
            </button>
            <strong className="additional-goods-quantity__numbers">{count}</strong>
            <button className="additional-goods-quantity__controls" data-button-type="plus" onClick={handleAddItem}>+
            </button>
          </div>
        ) : (
          <div className="additional-goods-quantity">
            <button className="additional-goods-quantity__controls" data-button-type="plus" onClick={handleAddItem}>+
            </button>
          </div>
        )
      }
    </>
  )
};

export const DrinkProductQuantity = (props) => {
  return (
    <ProductQuantity {...props}>
      <DrinkProductComponent product={props.product}/>
    </ProductQuantity>
  )
};
