import Immutable from "immutable";
import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";

import { sharedReducer } from "../../../components/shared/core/sharedReducer";
import { pagesReducer } from "../../../pages/_core/pagesReducer";
import { rootConstants } from "./rootConstants";
import { routerHistoryReducer } from "../../router/routerHistoryReducer";


export default (history) => combineReducers({
  router: connectRouter(history),
  routerHistory: routerHistoryReducer,
  root: rootReducer,
  shared: sharedReducer,
  pages: pagesReducer,
})

const initialState = Immutable.fromJS({
  init: false,
  down: false,
  loading: false,
});

function rootReducer(state = initialState, { type, payload })
{
  switch (type) {
    case rootConstants.LOADING:
      return state.withMutations(state => state.set('loading', payload).set('init', true));
    case rootConstants.DOWN:
      return state.withMutations(state => state.set('loading', payload).set('init', true).set('down', true));
    default:
      return state
  }
}
