import { Record } from "immutable";

import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";

const BentoInfoRecord = Record({
  amount: null,
  calories: null,
  carbohydrates: null,
  fats: null,
  price: 10,
  proteins: null,
  priceAvoided: 0
});

export class BentoInfo extends BentoInfoRecord {
  //
}

const BentoModelRecord = Record({
  active: null,
  ageLimit: null,
  announce: null,
  isOnTrade: null,
  isSuitableForCurrentCart: null,
  position: null,
  price: 0,
  releaseDayOfWeek: 0,
  publicImages: {},
  id: null,
  title: '',

  hasMedium: false,
  big: new BentoInfo(),
  medium: new BentoInfo(),

  type: PRODUCT_TYPES.bento.id,
});

export default class BentoModel extends BentoModelRecord {
  //
}
