import React from "react";
import { useSelector } from "react-redux";

import { catalogSelectors } from "../catalogs/_core/catalogSelectors";
import { VacanciesListItem } from "./VacanciesListItem";
import { sortByPosition } from "../../utils/utils";


export const VacanciesList = () => {
  const vacancies = useSelector(catalogSelectors.vacancies);

  if (!vacancies.size) {
    return (
      <>
        в данный момент у компании нет актуальных вакансий
      </>
    )
  }

  return (
    <>
      {
        vacancies
          .valueSeq()
          .toArray()
          .sort(sortByPosition)
          .map(vacancy => (
            <VacanciesListItem vacancy={vacancy} key={vacancy.get('id')}/>
          ))
      }
      <br/>
    </>
  )
};
