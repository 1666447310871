import React from "react";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";


export const CartOtherProductList = ({ other }) => {
  return (
    <>
      {other.valueSeq().map(otherItem => (
        <CartListItem key={`drink_${otherItem.get('id')}`}
                      item={otherItem}
					  type={PRODUCT_TYPES.warmer.id}
					  subtype={PRODUCT_TYPES.warmer.subtypes.other.id}
                      imageType="alt"
					/>
				))}
		</>
	)
};
