import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { closePopup } from "../../../common/popups/_core/popupActions";
import { productSelectors } from "../../_core/productSelectors";
import { popupSelector } from "../../../common/popups/_core/popupSelectors";
import { CircleBackground } from "../../../common/circleBackground/CircleBackground";
import { Image } from "../../../common/image/Image";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";
import {DessertProductPrice} from "../DessertProductPrice";
import {DessertProductQuantity} from "../DessertProductQuantity";
import { ROUTES } from "../../../../system/router/routeConstants";


export const DessertPopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dessertId = useSelector(popupSelector.props).dessertId;
  const cakes = useSelector(productSelectors[PRODUCT_TYPES.dessert.subtypes.cake.id]);
  const cookie = useSelector(productSelectors[PRODUCT_TYPES.dessert.subtypes.cookie.id]);
  const pie = useSelector(productSelectors[PRODUCT_TYPES.dessert.subtypes.pie.id]);
  const deserts = cakes.merge(cookie).merge(pie)
  const dessert = deserts.get(dessertId);
  const handleClick = () => {
    history.push(ROUTES.main.path);
    dispatch(closePopup());
  };

  return (
    <div className="popup popup--product popup--show">

      <button
        onClick={handleClick}
        className="popup__close"
        type="button"
        title="закрыть">&times;
      </button>

      <div className="popup-outer">
        <div className="popup-product">
          <h1 className="popup-product__title">{dessert.title.toLowerCase()}</h1>

          <ul className="popup-product-types">
            <DessertProductPrice type="big" noAmount dessert={dessert}/>
          </ul>

          <Image className="popup-product__picture"
                 publicImages={dessert.publicImages}
                 type="popup"
                 height={600} width={600}
          />

          <DessertProductQuantity product={dessert} type={PRODUCT_TYPES.dessert.id}/>

          <article className="popup-product__chars">
            <p>{dessert.get('announce')}</p>
          </article>
          <ul className="popup-product-ingredients">
            <li className="popup-product-ingredients__list-item">вес {dessert.info.amount}</li>
            <li className="popup-product-ingredients__list-item">калории {dessert.info.calories} ккал</li>
            <li className="popup-product-ingredients__list-item">углеводы {dessert.info.carbohydrates} г</li>
            <li className="popup-product-ingredients__list-item">белки {dessert.info.proteins} г</li>
            <li className="popup-product-ingredients__list-item">жиры {dessert.info.fats} г</li>
          </ul>
        </div>
      </div>
      <CircleBackground className="hide-on-mobile"/>
    </div>
  );
};
