import { Record } from "immutable";

import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";


const GarnishModelRecord = Record({
	active: null,
	amount: null,
    id: null,
	position: null,

	calories: 0,
	carbohydrates: 0,
	fats: 0,
	proteins: 0,

	price: 0,
	publicImages: {},
    title: null,

	isOnTrade: null,
	isSuitableForCurrentCart: null,

	subtype: PRODUCT_TYPES.garnish.subtypes.garnish.id,
	type: PRODUCT_TYPES.garnish.id,
});

export class GarnishModel extends GarnishModelRecord {
  //
}
