import { Record } from "immutable";

const DessertInfoRecord = Record({
	amount: null,
	calories: null,
	carbohydrates: null,
	fats: null,
	proteins: null,
});

export class DessertInfo extends DessertInfoRecord {
	//
}

