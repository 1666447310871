import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { priceBYN } from "../../../../utils/formatHelpers";
import { PAYMENT_METHOD } from "./PaymentInput";
import { cartSelectors } from "../../_core/cartSelectors";
import { updareCartPrepareCharge } from "../../_core/cartActions";

export const TotalInput = () => {
    const dispatch = useDispatch();
    const priceSpecification = useSelector(cartSelectors.priceSpecification) || {};
    const [value, setValue] = useState(priceSpecification.prepareChangeFor);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleBlur = () => {
        dispatch(updareCartPrepareCharge({
            prepare_change_for: value,
        }));
    }
    let discountTitle = 'скидка'
    if (priceSpecification.discountState?.get('promo') === 'preorder_pickup_discount') {
        discountTitle = 'скидка на предварительный заказ'
    }

    return (
        <div className="form-payments-and-total-general" style={{marginLeft: '20px'}}>
            {!!priceSpecification.discountItemsAmount && (
                <>
                    <span className="form-payments-and-total-general__label">стоимость:</span>
                    <strong className="form-payments-and-total-general__total">{priceBYN(priceSpecification.rawItemsPrice)}</strong><br/>

                    <span className="form-payments-and-total-general__label">{discountTitle}:</span>
                    {
                        !!priceSpecification.discountState.get('percent') && (
                            <strong className="form-payments-and-total-general__total">{priceSpecification.discountState.get('percent')}%</strong>
                        )
                    }
                    {
                        !priceSpecification.discountState.get('percent') && (
                            <strong className="form-payments-and-total-general__total">{priceSpecification.discountItemsAmount}</strong>
                        )
                    }
                    <br/>
                </>
            )}

            <div>
                <span className="form-payments-and-total-general__label">итого:</span>
                <strong className="form-payments-and-total-general__total">{priceBYN(priceSpecification.orderTotal)}</strong><br/>
                <small className="form-payments-and-total-general__note">оплата в белорусских рублях</small>
            </div>


            {priceSpecification?.paymentType === PAYMENT_METHOD.cash.id && (
                <div className="form-payments-and-total__cash-payment-renting">
                    <div className="label">
                        С какой суммы подготовить сдачу?
                    </div>
                    <div className="input">
                        <input className="form-input form-size-full"
                               type="text"
                               value={value}
                               onBlur={handleBlur}
                               onChange={handleChange}/>
                    </div>
                </div>
            )}
        </div>
    )
};
