import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { productSelectors } from "../../../products/_core/productSelectors";
import {PRODUCT_TYPES} from "../../../../api/products/productBaseConstants";



export const  ProductMenuListItem = ({active, hash, isNew, title = "", Icon, id}) => {
  const products = useSelector(productSelectors[id]);
  let size = 0;
  switch (id) {
    case PRODUCT_TYPES.dessert.id:
      size = products[PRODUCT_TYPES.dessert.subtypes.cookie.id].size + products[PRODUCT_TYPES.dessert.subtypes.cake.id].size
      break;
    case PRODUCT_TYPES.poke.id:
      size = products[PRODUCT_TYPES.poke.subtypes.standard.id].size
          + products[PRODUCT_TYPES.poke.subtypes.light.id].size
          + products[PRODUCT_TYPES.poke.subtypes.sashimi.id].size
      break;
    default:
      size = products.size;
  }

  return (
    <>
      {!!size && (
        <div className="relative">
          <Link
            to={{
              hash: hash,
              pathname: '/'
            }}
            className={`hover:no-underline hover:text-textHover ${active ? "text-textHover" : "text-textBlack" }`}
            data-item="sushi">
            {isNew && <div className="absolute top-[-10px] tab:top-[-16px] left-[4px] tab:left-[5px] text-textWhite text-t11 tab:text-t17">нью</div> }
            <div>{title ? title.toLowerCase() : <Icon className="tab:scale-150 relative tab:top-[-6px] hover:fill-textHover"/>}</div>
          </Link>
        </div>
      )}
    </>
  )
};
