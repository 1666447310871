import { createAction } from "@reduxjs/toolkit";

import {
  FEEDBACK_TROUBLES_LOADED,
  FEEDBACK_TROUBLES_LOADED_WITH_ERROR,
  LOAD_FEEDBACK_TROUBLES
} from "./feedackTroublesConstants";


export const loadFeedbackTroubles = createAction(LOAD_FEEDBACK_TROUBLES);
export const feedbackTroublesLoaded = createAction(FEEDBACK_TROUBLES_LOADED);
export const feedbackTroublesLoadedWithError = createAction(FEEDBACK_TROUBLES_LOADED_WITH_ERROR);
