import { CS } from "../../../utils/modules/reselect";


const root = state => state.get('root');

export const rootSelectors = {
	init: CS(root, root => root.get('init')),
	loading: CS(root, root => root.get('loading')),
	down: CS(root, root => root.get('down')),
};
