import React from 'react';
import { useSelector } from "react-redux";

import stopInformerIcon from '../../../../assets/images/stop_informer.png'
import { settingSelectors } from "../../settings/_core/settingSelectors";

import css from "./stop-informer.css";

export const StopInformer = () => {
    const stopInformer = useSelector(settingSelectors.settings).stopInformer;

    if (!stopInformer) {
        return null;
    }

    return (
        <div className="header-stop-informer">
            <div className="header-stop-informer__informer-icon-container">
                <img src={stopInformerIcon} alt='Stop Informer' className="header-stop-informer__informer-icon"/>
            </div>
            <div className="header-stop-informer__informer-text">
                <div>
                    В данный момент Godzilla работает с максимальной загрузкой, обслуживание некоторых районов приостановлено. <br/>
                    Спасибо за понимание.
                </div>
            </div>
        </div>
    )
}
