import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Anchor } from "../../common/Anchor";
import { PokeList } from "./PokeList";
import { SectionTitle } from "../../common/sectionTitle/SectionTitle";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import { closePopup, openPopup } from "../../common/popups/_core/popupActions";
import { POPUP_COMPONENTS } from "../../common/popups/popupsConfig";
import { pageSelectors } from "../../../pages/_core/pagesSelectors";
import {catalogSelectors} from "../../catalogs/_core/catalogSelectors";
import {SectionDescription} from "../../common/sectionDescription/SectionDescription";


export const PokeSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const popup  = useSelector(pageSelectors.popup);

    useEffect(() => {
        if (history.location.pathname.includes('poke')) {
            const pokeId = history.location.pathname.split('/')[2];
            dispatch(openPopup({
                type: POPUP_COMPONENTS.pokePopup.type,
                props: { pokeId }
            }))
        } else {
            if (popup.type === POPUP_COMPONENTS.pokePopup.type) {
                dispatch(closePopup())
            }
        }
    }, [history.location.pathname])
    let sectionDescription = useSelector(catalogSelectors.pokeSectionDescription)

    return (
        <div className="main-goods">
            <Anchor id={PRODUCT_TYPES.poke.id} top={-120}/>

            <SectionTitle title={PRODUCT_TYPES.poke.name}/>
            {sectionDescription && sectionDescription.description && <SectionDescription description={sectionDescription.description}/>}
            <PokeList />
        </div>
    );
}
