import React from "react";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";


export const CartSauceProductList = ({ sauce }) => {
  return (
    <>
      {sauce.valueSeq().map(sauceItem => (
        <CartListItem key={`sauce_${sauceItem.get('id')}`}
                      item={sauceItem}
					  hideAnnounce
                      type={PRODUCT_TYPES.garnish.id}
                      subtype={PRODUCT_TYPES.garnish.subtypes.sauce.id}
				/>
			))}
		</>
	)
};
