import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Anchor } from "../../common/Anchor";
import { SoupList } from "./SoupList";
import { SectionTitle } from "../../common/sectionTitle/SectionTitle";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import { closePopup, openPopup } from "../../common/popups/_core/popupActions";
import { POPUP_COMPONENTS } from "../../common/popups/popupsConfig";
import { pageSelectors } from "../../../pages/_core/pagesSelectors";
import {SectionDescription} from "../../common/sectionDescription/SectionDescription";
import {catalogSelectors} from "../../catalogs/_core/catalogSelectors";
import {PromoCookieBanner} from "../../common/promoCookieBanner/PromoCookieBanner";
import {settingSelectors} from "../../settings/_core/settingSelectors";



export const SoupSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const popup  = useSelector(pageSelectors.popup);
    const settings = useSelector(settingSelectors.settings);

    useEffect(() => {
        if (history.location.pathname.includes('soup')) {
            const soupId = history.location.pathname.split('/')[2];
            dispatch(openPopup({
                type: POPUP_COMPONENTS.soupPopup.type,
                props: { soupId }
            }))
        } else {
            if (popup.type === POPUP_COMPONENTS.soupPopup.type) {
                dispatch(closePopup())
            }
        }
    }, [history.location.pathname])
    let sectionDescription = useSelector(catalogSelectors.soupSectionDescription)

    return (
        <div className="main-goods">
            <Anchor id={PRODUCT_TYPES.soup.id} top={-120}/>
            <SectionTitle title={PRODUCT_TYPES.soup.multy}/>
            {sectionDescription && sectionDescription.description && <SectionDescription description={sectionDescription.description}/>}
            {!settings.promoCookieDisabled && <PromoCookieBanner/>}
            <SoupList/>
        </div>
    );
}
