import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteAddress } from "../../../customer/_core/customerActions";
import { changePopupProps } from "../../../common/popups/_core/popupActions";
import { popupSelector } from "../../../common/popups/_core/popupSelectors";
import { Button } from "../../../common/button/Button";
import { ADDRESS_POPUP_FORMS } from "../addressPopupConstants";


export const AddressPopupDeleteConfirmaionForm = () => {
  const dispatch = useDispatch();
  const addressToDelete = useSelector(popupSelector.props).addressToDelete;

  const handleDelete = () => {
    dispatch(deleteAddress({ address_id: addressToDelete.id }));
  };

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(changePopupProps({
      myAddressForm: ADDRESS_POPUP_FORMS.selectAddress
    }))
  };

	return (
		<div className="popup-form">

			<div style={{color: '#ffefe6'}}>
				вы действительно хотите удалить адрес
				<span style={{whiteSpace: 'nowrap'}}> {addressToDelete.fullAddress} ?</span>
			</div>
			<div>
				<div className="popup-form-button">
					<Button className="btn-small btn-primary"
                  onClick={handleDelete}>
            удалить
          </Button>
        </div>
        <div className="form-footer-action">
          <a href="#" onClick={handleCancel}>отмена</a>
        </div>
      </div>
    </div>
  )
};
