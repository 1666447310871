import { __ } from "../../../utils/utils";
import CartAddressModel from "../../../models/Cart/CartAddressModel";


export const addressCreator = data => {
    return new CartAddressModel({
        street: __(data.street_title),
        streetId: __(data.street_id),
        house: __(data.house_title),
        houseId: __(data.house_id),
        flat: __(data.flat),
        intercom: __(data.intercom),
        entrance: __(data.entrance),
        floor: __(data.floor),
        toEntrance: __(data.to_entrance),
    });
}
