import { fromJS } from "immutable";

import { ORDER_LOADED, ORDER_LOADED_WITH_ERROR, ORDER_UPDATED, ORDER_UPDATED_WITH_ERROR } from "./orderConstants";
import { OrderModel } from "../../../models/Order/OrderModel";


const initialState = new OrderModel();

export const orderReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ORDER_LOADED:
            return payload;

        case ORDER_UPDATED:
            return payload;

        case ORDER_LOADED_WITH_ERROR:
            return fromJS({
                error: payload
            });

        case ORDER_UPDATED_WITH_ERROR:
            return fromJS({
                error: payload
            });

        default:
            return state
    }
};
