import React from "react";
import marked from "marked";

import "./warning.css";


export const Warning = ({ description, className, children, style }) => {
  if (!description && !children) {
    return null;
  }

  if (description) {
    return (
      <div className={`warning ${className}`} style={style}>
        <div className={`warning-content ${className}`}
             style={style}
             dangerouslySetInnerHTML={{ __html: marked(description) }}/>
      </div>
    )
  }

  return (
    <div className="warning" style={style}>
      <div className={`warning-content ${className}`}>
        {children}
      </div>
    </div>
  )
};
