import React from "react";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";


export const CartCoffeeProductList = ({ coffee }) => {
  return (
    <>
      {coffee.valueSeq().map(coffeeItem => (
        <CartListItem key={`drink_${coffeeItem.get('id')}`}
                      item={coffeeItem}
					  type={PRODUCT_TYPES.warmer.id}
					  subtype={PRODUCT_TYPES.warmer.subtypes.coffee.id}
                      imageType="alt"
					/>
				))}
		</>
	)
};
