import { createAction } from "@reduxjs/toolkit";

import { rootConstants } from "./rootConstants";


export const rootActions = {
  down: createAction(rootConstants.DOWN),
  loading: createAction(rootConstants.LOADING),

  bypassParamsRequested: createAction(rootConstants.BYPASS_PARAMS_REQUESTED),
  bypassParamsLoaded: createAction(rootConstants.BYPASS_PARAMS_LOADED),
  bypassParamsLoadedWithError: createAction(rootConstants.BYPASS_PARAMS_LOADED_WITH_ERROR),
};

