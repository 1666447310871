import { combineReducers } from "redux-immutable";
import Immutable from "immutable";

import { addressErrorReducer } from "../../address/_core/addressReducer";
import { cartReducer } from "../../cart/_core/cartReducer";
import { currentManufactureReducer } from "../../currentManufacture/_core/manufactureReducer";
import { orderReducer } from "../../order/_core/orderReducer";
import { DELETE_ADDRESS_SUCCESS, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT_SUCCESS } from "./customerConstants";


export const customerInfoReducer = (state = Immutable.Map({}), {type, payload}) => {
	switch (type) {
		case LOGIN_SUCCESS:
			return Immutable.Map(payload);

		case LOGIN_FAILED:

			return Immutable.Map({
				error: payload.message
			});

		case LOGOUT_SUCCESS:
			return Immutable.Map({});

		case DELETE_ADDRESS_SUCCESS:
			return state.set('addresses', state.get('addresses').delete(+payload));

		default:
			return state
	}
};

export const customerReducer = combineReducers({
  cart: cartReducer,
  addressError: addressErrorReducer,
  customerInfo: customerInfoReducer,
  order: orderReducer,
});

