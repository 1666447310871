import { createAction } from "@reduxjs/toolkit";
import {
  FETCH_PREORDER_INTERVALS,
  PREORDER_INTERVALS_LOADED,
  PREORDER_INTERVALS_LOADED_WITH_ERROR,
  RESET_PREORDER_INTERVALS
} from "./preorderConstants";

export const fetchPreorderIntervals = createAction(FETCH_PREORDER_INTERVALS);
export const preorderIntervalLoaded = createAction(PREORDER_INTERVALS_LOADED);
export const preorderIntervalLoadedWithError = createAction(PREORDER_INTERVALS_LOADED_WITH_ERROR);

export const resetPreorderIntervals = createAction(RESET_PREORDER_INTERVALS);
