import Immutable from "immutable";

import { ADDRESS_FORM_MODIFICATION_HANDLED, ADDRESS_FORM_MODIFIED, RESET_CART_PAGE, } from "./cartPageConstants";
import {
	CART_PROCESSED,
	CART_PROCESSED_WITH_ERROR,
	CART_PROCESSING, CART_UPDATED
} from "../../../components/cart/_core/cartConstants";


const initialState = Immutable.Map({
	addressFormModified: false,
	isOrderSubmitted: false,
	isOrderProcessing: false,
	orderError: '',
});

export const cartPageReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case ADDRESS_FORM_MODIFIED:
			return state.set('addressFormModified', true);

		case ADDRESS_FORM_MODIFICATION_HANDLED:
			return state.set('addressFormModified', false);

		case CART_PROCESSED:
			return state.set('isOrderSubmitted', true).set('isOrderProcessing', false);

		case CART_PROCESSED_WITH_ERROR:
			if (!payload) {
				return state.set('isOrderProcessing', false);
			}
			const error = typeof payload === "string" ? payload : Object.values(payload).flat()[0];
			return state.set('orderError', error).set('isOrderProcessing', false);

    case CART_PROCESSING:
      return state.set('isOrderProcessing', true).set('orderError', null);

    case RESET_CART_PAGE:
      return initialState;

		case CART_UPDATED:
			return state.set('orderError', null);

		default:
			return state
	}
};
