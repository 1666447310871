import { fetchApiProcess } from "../../utils/http"
import { manufactureMapProcess, simplifiedManufactureModelCreator } from "./manufactureCreators"


export const manufactureCallers = {
  manufactures: () => fetchApiProcess([ 'manufactures', 'sushi' ], manufactureMapProcess, {
    version: 'v4.0.0',
  }),

  resolveManufacture: (id) => fetchApiProcess([ 'manufactures', 'house', id ], simplifiedManufactureModelCreator, {
    version: 'v4.0.0',
  }),
};


