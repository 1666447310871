import { __, toBool } from "../../utils/utils";
import { orderItemMapCreator } from "./orderItemCreators";
import { OrderModel } from "../../models/Order/OrderModel";
import { cartPriceSpecificationCreator, cartShippingCreator, customerInfoCreator } from "../cart/cartCreators";


const orderFeedbackCreator = (data) => {
  if (!data) return (
      {
        comment: null,
        conclusion: null,
        troubles: null,
      }
  );

  return ({
    comment: __(data.comment),
    conclusion: __(data.conclusion), //“ok” / “troubles” / null,
    troubles: __(data.troubles),
  })
}

export const orderCreator = data => {
  return new OrderModel({
    num: __(data.num),
    sync: __(data.sync),
    hash: __(data.hash),

    customerInfo: customerInfoCreator(data.customer_info),
    items: orderItemMapCreator(data.items),
    shipping: cartShippingCreator(data.shipping),
    priceSpecification: cartPriceSpecificationCreator(data.price_specification),

    additionalData: {
      originalComment: __(data.additional_data?.original_comment),
      bepaidRedirectUrl: __(data.additional_data?.bepaid_redirect_url),
      confirmedByOperator: toBool(data.additional_data?.confirmed_by_operator),
    },

    feedback: orderFeedbackCreator(data.feedback)
  });
};

