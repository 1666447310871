import { __, toBool } from "../../utils/utils";
import SettingsModel from "../../models/Settings/SettingsModel";


export const settingsModelCreator = data => new SettingsModel({
  baskedDisabled: data.basked_disabled,

  halvaDisabled: toBool(data.halva_disabled),
  halvaPreorderDisabled: toBool(data.halva_preorder_disabled),
  delivery45: toBool(data.is_delivery_45),
  isWorkingTime: toBool(data.is_working_time),

  minimalPrice: ( __(data.minimal_price, { defaultValue: 0 }) / 10000 ).toFixed(2),
	minimalPriceAction: ( __(data.minimal_price_action, { defaultValue: 0 }) / 10000 ).toFixed(2),
	minimalPriceActionTimeFrom: __(data.minimal_price_action_time_from),
	minimalPriceActionTimeTo: __(data.minimal_price_action_time_to),

  nonContactDeliveryDisabled: toBool(data.non_contact_delivery_disabled),
  promoCookieDisabled: toBool(data.promo_cookie_disabled),
  onlineDisabled: toBool(data.online_disabled),
  onlinePreorderDisabled: toBool(data.online_preorder_disabled),
  paymentDyCardDisabled: toBool(data.payment_by_card_disabled),
  showTestControls: toBool(data.show_test_controls),
  stopInformer: toBool(data.stop_informer),
  workTimeFrom: data.work_time_from,
  workTimeTo: data.work_time_to,
  isFeedbackEnabled: data.is_feedback_enabled,
});
