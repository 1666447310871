import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { applyAddress } from "../../../cart/_core/cartActions";
import { customerSelectors } from "../../../customer/_core/customerSelectors";
import { changePopupProps } from "../../../common/popups/_core/popupActions";
import { AddressPopupHeader } from "./internalComponents/AddressPopupHeader";
import { Error } from "../../../common/notifications/error/Error";
import { Warning } from "../../../common/notifications/warning/Warning";
import { ADDRESS_POPUP_FORMS } from "../addressPopupConstants";
import { Infobox } from "../../../common/notifications/infobox/Infobox";

import "../styles/my-address-tab.css";


const ErrorAddressBlock = ({handleDeleteAddress, address}) => {
    return (
        <Error
            title={address.fullAddress}
            className="m-centered"
            style={{
                marginBottom: 0,
                padding: '5px 15px',
                borderRadius: '6px',
                maxWidth: '960px'
            }}>
            <div>
                {address.error}
                <div className="pickup-nav-remove">
                    <button className="pickup-nav-remove__btn" type="button"
                            onClick={handleDeleteAddress(address)}>удалить адрес
                    </button>
                </div>
            </div>
        </Error>
    )
}

const WarningAddressBlock = ({handleSelectAddress, handleDeleteAddress, address}) => {
    return (
        <Warning style={{
            borderRadius: '6px',
            lineHeight: '24px',
            padding: '10px 20px',
            margin: '0 auto'
        }}>
            <button className="pickup-nav__item"
                    style={{marginBottom: '10px', padding: '10px 20px'}}
                    type="button"
                    onClick={handleSelectAddress(address)}>
                <span>{address.fullAddress}</span>
            </button>
            <div>
                {address.warning}
            </div>
            <div className="pickup-nav-remove">
                <button className="pickup-nav-remove__btn" type="button"
                        onClick={handleDeleteAddress(address)}>удалить адрес
                </button>
            </div>
        </Warning>
    )
}

const AddessBlock = ({handleSelectAddress, handleDeleteAddress, address}) => {
    return (
        <>
            <button className="pickup-nav__item"
                    style={{marginBottom: '10px', padding: '10px 20px'}}
                    type="button"
                    onClick={handleSelectAddress(address)}>
                <span>{address.fullAddress}</span>
            </button>
            <div>
                {address.warning}
            </div>
            <div className="pickup-nav-remove">
                <button className="pickup-nav-remove__btn" type="button"
                        onClick={handleDeleteAddress(address)}>удалить адрес
                </button>
            </div>
        </>
    )
}

export const AddressPopupSelectMyAddressForm = () => {
    const dispatch = useDispatch();
    const customerInfo = useSelector(customerSelectors.customerInfo);

    const handleDeleteAddress = (address) => () => {
        dispatch(changePopupProps({
            myAddressForm: ADDRESS_POPUP_FORMS.deleteiConfirmaion,
            addressToDelete: address
        }))
    };

    const handleSelectAddress = (address) => () => {
        dispatch(applyAddress(address.get('id')));
    };

    return (
        <>
            <AddressPopupHeader/>

            <ul className="pickup-nav">

                {!customerInfo.get('addresses').size && (
                    <Infobox className="m-centered"
                             description="адрес автоматически появится в списке при оформлении следующей доставки"/>
                )}

                {!!customerInfo.get('addresses').size && customerInfo.get('addresses').valueSeq().map(address => (
                    <li className="pickup-nav__list-item" key={address.get('id')}>
                        {address.error
                            ? <ErrorAddressBlock address={address} handleDeleteAddress={handleDeleteAddress}/>
                            : address.warning
                                ? <WarningAddressBlock address={address} handleDeleteAddress={handleDeleteAddress} handleSelectAddress={handleSelectAddress}/>
                                : <AddessBlock address={address} handleDeleteAddress={handleDeleteAddress} handleSelectAddress={handleSelectAddress}/>
                        }
                    </li>
                ))}

            </ul>
        </>
    )
};
