import {OrderedMap} from "immutable";

import {__, toBool} from "../../../utils/utils";
import PokeModel, { PokeInfo } from "../../../models/Products/Poke/PokeModel";
import {PRODUCT_TYPES} from "../productBaseConstants";

const pokeInfoCreator = data => new PokeInfo({
  calories: __(data.calories),
  carbohydrates: __(data.carbohydrates),
  fats: __(data.fats),
  proteins: __(data.proteins),
  price: __(data.price) / 10000,
  amount: __(data.amount),
});

export const pokeCreator = data => new PokeModel({
	announce: __(data.announce),
	code: __(data.code),
	id: __(data.id),
	position: __(data.position),
	publicImages: data.public_images,
	title: __(data.title),
	isOnTrade: toBool(data.is_on_trade),
	isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
	hasMedium: toBool(data.has_medium),
	info: pokeInfoCreator(data),
	subtype: __(data.poke_type),
});

const createPokeSubtypeFilter = (data, subtype, creator) => OrderedMap().withMutations(map => {
	Object.entries(data)
		.filter(([id, item]) => item.poke_type === subtype)
		.forEach(([id, item]) => {
			map.set(id, creator(item))
		})
});

export const pokesProcess = (data) => {
	return {
		[PRODUCT_TYPES.poke.subtypes.standard.id]: createPokeSubtypeFilter(data, PRODUCT_TYPES.poke.subtypes.standard.id, pokeCreator),
		[PRODUCT_TYPES.poke.subtypes.light.id]: createPokeSubtypeFilter(data, PRODUCT_TYPES.poke.subtypes.light.id, pokeCreator),
		[PRODUCT_TYPES.poke.subtypes.sashimi.id]: createPokeSubtypeFilter(data, PRODUCT_TYPES.poke.subtypes.sashimi.id, pokeCreator),
	}
};

