import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { login } from "../../../customer/_core/customerActions";
import { changePopupProps } from "../../../common/popups/_core/popupActions";
import { Button } from "../../../common/button/Button";
import { ADDRESS_POPUP_FORMS } from "../addressPopupConstants";


export const AddressPopupLoginForm = () => {
	const dispatch = useDispatch();

	const [ state, setState ] = useState({
    phone: '',
    password: '',
  });

  const handlePhoneChange = (e) => {
    setState({
      ...state,
      phone: e.target.value
    })
  };

  const handlePasswordChange = (e) => {
    setState({
      ...state,
      password: e.target.value
    })
  };

  const handleSubmit = () => {
    dispatch(login(state));
  };

  const handleOpenPasswordRequestForm = (e) => {
    e.preventDefault();
    dispatch(changePopupProps({
      myAddressForm: ADDRESS_POPUP_FORMS.requestPassword
    }))
  };

	return (
		<div className="popup-form">

			<div className="form-group">
				<div className="form-label form-label--alt">номер в международном формате</div>
				<div className="form-with-sidelabel form-size-long">
					<input className="form-input form-size-full form-input-big"
								 autoComplete="username"
								 name="phone"
								 onChange={handlePhoneChange}
								 value={state.phone}
								 type="tel"/>

					<div className="form-with-sidelabel-label">
						<div className="form-with-sidelabel-label__inner">например:<br/>+375296040000</div>
					</div>
				</div>
			</div>

			<div className="form-group">
				<div className="form-label form-label--alt">пароль</div>
				<input className="form-input form-size-long form-input-big"
							 type="password"
							 name="password"
							 autoComplete="password"
							 onChange={handlePasswordChange}
							 value={state.password}
				/>
			</div>

			<div className="popup-form-button">
        <Button className="btn-small btn-primary"
                onClick={handleSubmit}>
          войти
        </Button>
      </div>
      <div className="form-footer-action">
        <a href="#" onClick={handleOpenPasswordRequestForm}>выслать пароль</a>
      </div>
    </div>
  )
};
