import React from "react";

import "./product-code.css";


export const ProductCode = ({ code }) => {
  return (
    <>
      <span className="product-code__hash">#</span>
      <span className="product-code__code">{code}</span>&nbsp;
    </>
  )
};
