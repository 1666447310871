import React from "react";
import { useSelector } from "react-redux";

import { cartSelectors } from "../../../cart/_core/cartSelectors";
import { GarnishProductQuantity } from "./GarnishProductQuantity";
import { WidthRatioImageContainer } from "../../../common/ratioContainers/WidthRatioImageContainer";
import { titleFormatter } from "../../../../utils/titleFormatter";
import {GarnishProductPrice} from "../GarnishProductPrice";
import {useHistory} from "react-router-dom";


export const GarnishListItem = ({ garnish }) => {
  const history = useHistory();
  const cartItem = useSelector(state => cartSelectors.cartItem(state, garnish));
  const freeGarnishesCount = useSelector(cartSelectors.additionalData).freeItemsRemaining.get('garnish');
  const productInCart = cartItem.count ? 'incart' : 'none';
  const open = () => history.push(`/garnish-detail/${garnish.id}`);

  return (
    <li className="secondary-goods__list-item" data-item={productInCart}>
      <div className="secondary-goods__item">
        <h3 className="secondary-goods__title">
          <span>{titleFormatter(garnish.title)}</span>
        </h3>

				<GarnishProductPrice garnish={garnish} freeGarnishesCount={freeGarnishesCount} />

        <WidthRatioImageContainer
          onClick={open}
          ratio={600 / 476}
          publicImages={garnish.publicImages}
          type="main"
          className="secondary-goods__picture"/>

        <GarnishProductQuantity product={garnish}/>
      </div>
    </li>
  );
};
