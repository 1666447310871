import React from "react";

import { PokeListItem } from "./PokeListItem";
import { sortByPosition } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { productSelectors } from "../_core/productSelectors";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";


export const PokeList = () => {
  const pokesStandard = useSelector(productSelectors[PRODUCT_TYPES.poke.subtypes.standard.id]);
  const pokesLight = useSelector(productSelectors[PRODUCT_TYPES.poke.subtypes.light.id]);
  const pokesSashimi = useSelector(productSelectors[PRODUCT_TYPES.poke.subtypes.sashimi.id]);
  const pokes = pokesStandard.merge(pokesLight).merge(pokesSashimi)

  return (
    <ul className="main-goods__list">
      {pokes.toList().sort(sortByPosition).map(product => (
        <PokeListItem poke={product} key={product.get('id')}/>
      ))}
    </ul>
  )
};
