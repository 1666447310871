import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {cartSelectors} from "../../_core/cartSelectors";
import {updateCartClientName} from "../../_core/cartActions";
import {addressFormModified} from "../../../../pages/_core/cartPage/cartPageActions";
import {cartPageSelectors} from "../../../../pages/_core/cartPage/cartPageSelectors";


export const NameInput = () => {
	const dispatch = useDispatch();
	const userName = useSelector(cartSelectors.customerInfo).name;
	const isAddressFormModified = useSelector(cartPageSelectors.addressFormModified);

	const [name, setName] = useState(userName);

	useEffect(() => {
		setName(userName);
	}, [userName])

	const handleInputChange = (e) => {
		e.preventDefault();
		setName(e.target.value);
		if (!isAddressFormModified) {
			dispatch(addressFormModified());
		}
	};

	const handleBlur = (e) => {
		dispatch(updateCartClientName({
			name: e.target.value.toString()
		}))
	};

	return (
		<div className="form-group form-required">
			<div className="form-label">ваше имя</div>
			<input
				onBlur={handleBlur}
				onChange={handleInputChange}
				className="form-input form-size-normal"
				type="text"
				value={name}
			/>
		</div>
	);
}
