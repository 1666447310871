import {OrderedMap} from "immutable";

import {__, toBool} from "../../../utils/utils";
import SoupModel, { SoupInfo } from "../../../models/Products/Soup/SoupModel";


const soupInfoCreator = data => new SoupInfo({
  calories: __(data.calories),
  carbohydrates: __(data.carbohydrates),
  fats: __(data.fats),
  proteins: __(data.proteins),
  price: __(data.price) / 10000,
  amount: __(data.amount),
});

export const soupCreator = data => new SoupModel({
	announce: __(data.announce),
	code: __(data.code),
	id: __(data.id),
	position: __(data.position),
	publicImages: data.public_images,
	title: __(data.title),
	isOnTrade: toBool(data.is_on_trade),
	isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
	hasMedium: toBool(data.has_medium),

  info: soupInfoCreator(data),
});

export const  soupsProcess = (data) => {
	return (new OrderedMap()).withMutations(map => {
		Object.entries(data).forEach(([id, item]) => {
			map.set(id, soupCreator(item))
		})
	})
};
