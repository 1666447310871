import React from "react";

import { priceBYN } from "../../../utils/formatHelpers";


const PRODUCT_SIZES = {
    'medium': {
        portion: '4 шт',
    },
    'big': {
        portion: '8 шт',
    }
};

export const RollPriceInfo = ({productType, type}) => {
    return (
        <li className="main-goods-types__list-item">
            <div className="main-goods__price-box">
                <strong className="main-goods__price">{priceBYN(productType.price)}</strong>
                <span className="main-goods__portion">{PRODUCT_SIZES[type].portion}</span>
            </div>
        </li>
    )
};
