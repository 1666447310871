const decorator = (str) => 'cart/' + str;

export const CART_TIME_TYPES = {
	preorder: 'preorder',
	runtime: 'runtime'
}

export const CART_SHIPPING_TYPES = {
	pickup: 'pickup',
	delivery: 'delivery'
};

export const APPLY_PICKUP = decorator('APPLY_PICKUP');
export const APPLY_ADDRESS = decorator('APPLY_ADDRESS');
export const ADD_ITEM_TO_CART = decorator('ADD_ITEM_TO_CART');
export const REMOVE_ITEM_FROM_CART = decorator('REMOVE_ITEM_FROM_CART');
export const REMOVE_ITEM_GROUP_FROM_CART = decorator('REMOVE_ALL_ITEM_FROM_CART');

export const CART_PROCESSED = decorator('CART_PROCESSED');
export const CART_PROCESSED_WITH_ERROR = decorator('CART_PROCESSED_WITH_ERROR');
export const CART_PROCESSING = decorator('CART_PROCESSING_STARTED');
export const CART_PROCESSING_APPROVED = decorator('CART_PROCESSING_APPROVED');

export const UPDATE_CART_ADDRESS = decorator('UPDATE_CART_ADDRESS');
export const SET_CART_ADDRESS = decorator('SET_CART_ADDRESS');
export const UPDATE_CART_FLAT = decorator('UPDATE_CART_FLAT');
export const UPDATE_CART_FLOOR = decorator('UPDATE_CART_FLOOR');
export const UPDATE_CART_ENTRANCE = decorator('UPDATE_CART_ENTRANCE');
export const UPDATE_CART_INTERCOM = decorator('UPDATE_CART_INTERCOM');

export const UPDATE_CART_CLIENT_NAME = decorator('UPDATE_CART_CLIENT_NAME');
export const UPDATE_CART_CLIENT_PHONE = decorator('UPDATE_CART_CLIENT_PHONE');

export const UPDATE_CART_NO_CONTACT = decorator('UPDATE_CART_NO_CONTACT');
export const UPDATE_CART_PICKUP_DELAY = decorator('UPDATE_CART_PICKUP_DELAY');
export const UPDATE_CART_PREORDER = decorator('UPDATE_CART_PREORDER');
export const UPDATE_CART_COMMENT = decorator('UPDATE_CART_COMMENT');

export const UPDATE_CART_PAYMENT = decorator('UPDATE_CART_PAYMENT');
export const UPDATE_CART_PREPARE_CHARGE = decorator('UPDATE_CART_PREPARE_CHARGE');

export const CART_UPDATE_REQUESTED = decorator('CART_UPDATE_REQUESTED');
export const CART_UPDATED = decorator('CART_UPDATED');
export const CART_UPDATED_WITH_ERROR = decorator('CART_UPDATED_WITH_ERROR');

export const STREET_CHANGED = decorator('STREET_CHANGED');

export const TOGGLE_CART_CONFIRM_PRIVACY_POLICY_RULES = decorator('TOGGLE_CART_CONFIRM_PRIVACY_POLICY_RULES');
export const TOGGLE_CART_CONFIRM_PRIVACY_POLICY_ORDER_DATA = decorator('TOGGLE_CART_CONFIRM_PRIVACY_POLICY_ORDER_DATA')
export const TOGGLE_CART_CONFIRM_PRIVACY_POLICY_SMS_PROCESS_ORDER = decorator('TOGGLE_CART_CONFIRM_PRIVACY_POLICY_SMS_PROCESS_ORDER')
export const TOGGLE_CART_CONFIRM_PRIVACY_POLICY_FEEDBACK_SERVICE = decorator('TOGGLE_CART_CONFIRM_PRIVACY_POLICY_FEEDBACK_SERVICE')