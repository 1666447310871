import { OrderedMap } from "immutable";
import { __, toBool } from "../../../utils/utils";
import {DessertInfo} from "../../../models/Products/Dessert/DessertInfo";
import {PRODUCT_TYPES} from "../productBaseConstants";
import CakeModel from "../../../models/Products/Dessert/CakeModel";
import CookieModel from "../../../models/Products/Dessert/CookieModel";
import PieModel from "../../../models/Products/Dessert/PieModel";


export const dessertInfoCreator = data => new DessertInfo({
  calories: __(data.calories),
  carbohydrates: __(data.carbohydrates),
  fats: __(data.fats),
  proteins: __(data.proteins),
  amount: __(data.amount),
});

export const cakeCreator = data => {
	return new CakeModel({
		id: __(data.id),
		announce: __(data.announce),
		price: __(data.price) / 10000,
		title: __(data.title),
		publicImages: data.public_images,
		info: dessertInfoCreator(data),
		position: __(data.position),
		isOnTrade: toBool(data.is_on_trade),
		isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
	});
}

export const cookieCreator = data => {
	return new CookieModel({
		id: __(data.id),
		announce: __(data.announce),
		price: __(data.price) / 10000,
		title: __(data.title),
		publicImages: data.public_images,
		info: dessertInfoCreator(data),
		position: __(data.position),
		isOnTrade: toBool(data.is_on_trade),
		isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
	});
}

export const pieCreator = data => {
	return new PieModel({
		id: __(data.id),
		announce: __(data.announce),
		price: __(data.price) / 10000,
		title: __(data.title),
		publicImages: data.public_images,
		info: dessertInfoCreator(data),
		position: __(data.position),
		isOnTrade: toBool(data.is_on_trade),
		isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
	});
}

const createDessertSubtypeFilter = (data, subtype, creator) => OrderedMap().withMutations(map => {
	Object.entries(data)
		.filter(([id, item]) => item.dessert_type === subtype)
		.forEach(([id, item]) => {
			map.set(id, creator(item))
		})
});

export const dessertsProcess = (data) => {
	return {
		[PRODUCT_TYPES.dessert.subtypes.cake.id]: createDessertSubtypeFilter(data, PRODUCT_TYPES.dessert.subtypes.cake.id, cakeCreator),
		[PRODUCT_TYPES.dessert.subtypes.cookie.id]: createDessertSubtypeFilter(data, PRODUCT_TYPES.dessert.subtypes.cookie.id, cookieCreator),
		[PRODUCT_TYPES.dessert.subtypes.pie.id]: createDessertSubtypeFilter(data, PRODUCT_TYPES.dessert.subtypes.pie.id, pieCreator),
	}
};
