import React from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import "./SubmitPrivacyPolicySection.css";
import {
    toggleCartConfirmPrivacyPolicyRules,
} from "../../_core/cartActions";
import {customerSelectors} from "../../../customer/_core/customerSelectors";
import {ROUTES} from "../../../../system/router/routeConstants";
import {cartPageSelectors} from "../../../../pages/_core/cartPage/cartPageSelectors";

export const SubmitPrivacyPolicySection = ({deliveryTimeSelected}) => {
    const dispatch = useDispatch();
    const cart = useSelector(customerSelectors.cart);
    const orderError = useSelector(cartPageSelectors.orderError);

    const onChangeConfirmPrivacyPolicyRules = () => {
        dispatch(toggleCartConfirmPrivacyPolicyRules());
    };

    if (cart.customerInfo.isConfirmedPrivacyPolicyRules) {
        return null;
    }
    return (
        <div className="form-privacy-policy-wrapper">
            <div>
                <label
                    className={!cart.customerInfo.isConfirmPrivacyPolicyRules && orderError ? 'form-privacy-policy-warning' : ''}>
                    <input checked={cart.customerInfo.isConfirmPrivacyPolicyRules} type="checkbox"
                           onClick={onChangeConfirmPrivacyPolicyRules}/>
                </label>
            </div>
            <p className='form-finish-button__note'>— Я даю <Link
                to={{hash: '#open-modal', pathname: '/cart'}}>согласие</Link> на
                обработку своих персональных данных в соответствии с <Link
                    to={{pathname: ROUTES.privatPolicy.path}}> Политикой обработки персональных данных ООО
                    «Лёгкий ужин»</Link>
            </p>
        </div>

    );
};
