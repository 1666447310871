import React, {useState} from "react";
import Carousel, {autoplayPlugin, Dots} from "@brainhubeu/react-carousel";

import {Image} from "../common/image/Image";

import "@brainhubeu/react-carousel/lib/style.css";
import "./InfoSlider.css";
import {useSelector} from "react-redux";
import {settingSelectors} from "./_core/settingSelectors";

const prepareSlides = (slides) => slides.valueSeq().toArray()
    .sort((a, b) => a.get('position') > b.get('position') ? 1 : -1)
    .map(slide => (
            <div key={'slide-' + slide.get('id')}>
                {!!slide.get('link') ? (
                    <a href={slide.get('link')}
                       target={['/', '#'].includes(slide.get('link')?.charAt(0)) ? "_self" : ""}>
                        <Image type="main" publicImages={slide.publicImages}/>
                    </a>
                ) : (
                    <Image type="main" publicImages={slide.publicImages}/>
                )}
            </div>
        )
    )

export const InfoSlider = () => {
    const slides = useSelector(settingSelectors.slides);
    const [state, setState] = useState({
        value: 0,
        slides: prepareSlides(slides)
    });
    const onchange = (value) => {
        if (state.value !== value && !isNaN(value)) {
            setState({
                value: value,
                slides: state.slides
            })
        }
    };

    if (!slides || slides.size === 0) {
        return null;
    }
    const plugins = slides.size > 1 ? [
        'infinite',
        {
            resolve: autoplayPlugin,
            options: {
                interval: 10000,
            }
        },
    ] : []


    return (
        <div className="hide-on-4cards" data-type="slider">
            <div className="main-slider">
                <div className="main-slider-area">
                    <div className="main-slider-area__item">
                        <Carousel plugins={plugins} value={state.value} slides={state.slides} onChange={onchange}/>
                        <Dots value={state.value} onChange={onchange} number={slides.size}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
