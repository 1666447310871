import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { CartProductQuantity } from "../CartProductQuantity";
import { priceBYN } from "../../../utils/formatHelpers";
import { Image } from "../../common/image/Image";
import { productSelectors } from "../../products/_core/productSelectors";
import { removeItemGroupFromCart } from "../_core/cartActions";
import {cartSelectors} from "../_core/cartSelectors";
import {CART_SHIPPING_TYPES} from "../_core/cartConstants";
import {settingSelectors} from "../../settings/_core/settingSelectors";
import {ProductCode} from "../../products/productCode/ProductCode";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";


export const CartListItem = ({
                               item, type, subtype, imageType, size, hideAnnounce, onClick = () => {
  }
                             }) => {
  const dispatch = useDispatch();

  const publicImages = useSelector(productSelectors[subtype || type]).get(item.get('id'))?.publicImages;
  const renderProductCode = (code) => <ProductCode code={code}/>

  const handleRemoveItemGroup = () => {
    dispatch(removeItemGroupFromCart({
      type,
      id: item.get('id'),
      size,
    }));
  };

    const shipping = useSelector(cartSelectors.shipping);
    const isPreorder = useSelector(cartSelectors.isPreorder);
    const isDelivery = shipping.shippingType === CART_SHIPPING_TYPES.delivery
    const settings = useSelector(settingSelectors.settings);

    let message = ''
    if (isDelivery) {
      if (isPreorder) {
        message = 'если мы не доставим ваш заказ в выбранный вами получасовой интервал'
      } else {
        message = `если мы не доставим ваш заказ за ${settings.isDelivery45 ? '45' : '60'} минут`
      }
    } else {
        message = 'если заказ на самовывоз не будет готов к выбранному времени'
    }

  return (
    <li className="cart-table__list-item">

      <Image className="cart-table__picture" publicImages={publicImages} height={75} imageType={imageType}
             onClick={onClick}/>

      <div className="cart-table__content">
        <h3 className="cart-table__title" onClick={onClick}>
          <a>
            {item.ageLimit ? `${item.ageLimit}+ ` : ''}
              {item.type === PRODUCT_TYPES.roll.id ? renderProductCode(item.code) : ''} {item.get('title').toLowerCase()}
          </a>
        </h3>
        {!hideAnnounce && (
          <p>{item.get('announce')}</p>
        )}
        { item.fiascoCandidate && (<div className="py-2 tab:w-[90%] pc:px-0 pc:w-[400px] text-t12 tab:text-t14 pc:text-t17 leading-4">
            {message}, вы получите данное блюдо
          по цене {priceBYN(item.productPrice - item.moneyAmountSavedIfFree)}
        </div>)}
      </div>

      <CartProductQuantity product={item} size={size || item.size} count={item.count}/>
      <div className="cart-table-price">
        {item.get('isOnTrade') && item.get('isSuitableForCurrentCart') ? (
          <div className="cart-table-price__box">
            {item.discountAmount
              ? (
                <>
                  <div className="cart-table-price__realization">{priceBYN(item.productPrice)}</div>
                  <div className="cart-table-price__realization_inner">
                    {priceBYN(item.discountedPrice)}
                  </div>
                </>
              )
              : (
                <div className="cart-table-price__inner">
                  {priceBYN(item.realizationPrice)}
                </div>
              )}
          </div>
        ) : (
          <button
            className="cart-table-delete-button"
            onClick={handleRemoveItemGroup}
            type="button"
            title="Удалить">удалить
            <div className="cart-table-delete-button__timer">&times;</div>
          </button>
        )}
      </div>

    </li>
  );
};
