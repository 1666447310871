import {trimLeadingUrlSlash} from "./utils";

export const sanitizeUrl = url => url.replace(/[^:]\/\/+/g, '/').replace(/(\/$)|(^\/)/g, '');

const parseJSON = response => response.json();
const checkStatus = (response) => {
	return response.status >= 200 && response.status < 300
		? response
		: response.json().then(json => Promise.reject(json))
};
const handleServerError = error => {
	return {error: error.response || 'server error'}
};

export const getUrlParams = function (url) {
	let params = {};
	let parser = document.createElement('a');
	parser.href = url;
	let query = parser.search.substring(1);
	let vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};

const jsonToHttp = query => {
	if (query) {
		return Object.entries(query)
			.map(([k, v]) => {
				if (v !== undefined && v !== null && !Number.isNaN(v)) {
					return encodeURIComponent(k) + '=' + encodeURIComponent(v)
				}
				return null
			})
			.filter(v => v !== null)
			.join('&')
	}
	return ''
};

const createRequest = (params) => {
	const defaultParams = {
		method: 'GET',
		credentials: 'include',
		body: {},
		headers: {},
	};
	const {method, body, credentials, headers} = Object.assign(defaultParams, params);
	let request = {
		method: method,
		mode: 'cors',
		credentials: credentials,
		headers: Object.assign({
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8',
		}, headers),
	};
	if (method !== 'GET') {
		request.body = jsonToHttp(body);
		request.headers = Object.assign(request.headers, {
			'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
		})
	}
	return request
};

const defaultPorts = {
	http: '80',
	https: '443'
};

export const apiProxyHostProtocol = process.env.REACT_APP__API_PROXY_HOST_PROTOCOL || 'http';
export const apiProxyHostAddress = process.env.REACT_APP__API_PROXY_HOST_ADDRESS || 'site2.dev.pzzby.com';
export const apiProxyHostPort = '' + (process.env.REACT_APP__API_PROXY_HOST_PORT || defaultPorts[apiProxyHostProtocol]);
export const apiProxyUri = process.env.REACT_APP__API_PROXY_URI || '/api/';
export const fqApiProxyHost = apiProxyHostProtocol + '://' + apiProxyHostAddress + (apiProxyHostPort === defaultPorts[apiProxyHostProtocol] ? '' : ':' + apiProxyHostPort);
export const fqApiProxyUrl = fqApiProxyHost + '/' + sanitizeUrl(apiProxyUri);
export const staticDataHostEnabled = process.env.REACT_APP__STATIC_DATA_HOST_ENABLED || 0;
export const staticDataHostProtocol = process.env.REACT_APP__STATIC_DATA_HOST_PROTOCOL || 'https';
export const staticDataHostAddress = process.env.REACT_APP__STATIC_DATA_HOST_ADDRESS || 'static.godzilla.by';
export const staticDataHostPort = '' + (process.env.REACT_APP__STATIC_DATA_HOST_PORT || defaultPorts[staticDataHostProtocol]);
export const staticDataUri = process.env.REACT_APP__STATIC_DATA_URI || '';
export const fqStaticDataHost = staticDataHostProtocol + '://' + staticDataHostAddress + (staticDataHostPort === defaultPorts[staticDataHostProtocol] ? '' : ':' + staticDataHostPort);
export const fqStaticDataUrl = fqStaticDataHost + '/' + trimLeadingUrlSlash(sanitizeUrl(staticDataUri));


export const apiBypassHostProtocol = process.env.REACT_APP__API_BYPASS_HOST_PROTOCOL || 'http';
export const apiBypassHostAddress = process.env.REACT_APP__API_BYPASS_HOST_ADDRESS || 'app2.dev.pzzby.com';
export const apiBypassHostPort = '' + (process.env.REACT_APP__API_BYPASS_HOST_PORT || defaultPorts[apiBypassHostProtocol]);
export const apiBypassUri = process.env.REACT_APP__API_BYPASS_URI || '/api/';
export const fqApiBypassHost = apiBypassHostProtocol + '://' + apiBypassHostAddress + (apiBypassHostPort === defaultPorts[apiBypassHostProtocol] ? '' : ':' + apiBypassHostPort);
export const fqApiBypassUrl = fqApiBypassHost + '/' + sanitizeUrl(apiBypassUri);

export const isBypassEnabled = process.env.REACT_APP__API_BYPASS_ENABLED === '1';
export const isProxyEnabled = !isBypassEnabled;

export const fqApiUrl = isProxyEnabled ? fqApiProxyUrl : fqApiBypassUrl;
export const apiVersion = isProxyEnabled ? process.env.REACT_APP__API_PROXY_VERSION : process.env.REACT_APP__API_BYPASS_VERSION;

const debugInfo = {'client_gdz_version': process.env.npm_package_version,};


export const fetchCaller = (url, request) => {
	const controller = new AbortController()
	// 15 second timeout:
	setTimeout(() => controller.abort(), 15000)

	return fetch(url, {...request, signal: controller.signal})
		.then(checkStatus)
		.then(parseJSON)
}

export const fetchRequest = (resource, requestSettings = {}) => {
	const request = createRequest(requestSettings);

	resource = Array.isArray(resource) ? sanitizeUrl(resource.join('/')) : sanitizeUrl(resource);

	let url = `${fqApiUrl}/${requestSettings?.version || apiVersion || 'v4.0.0'}/${resource}`;
	const params = {
		...debugInfo,
		cart_id: localStorage.getItem('cartId') || '',
		...requestSettings.params
	};
	const paramsToHttp = jsonToHttp(params);

	if (paramsToHttp) {
		url = `${url}?${paramsToHttp}`
	}
	return fetchCaller(url, request)
};

export const fetchApi = (resource, requestSettings = {}) => {
	return fetchRequest(resource, requestSettings)
		.then(result => ({result: result.response.data}))
		.catch(handleServerError)
};

export const fetchApiProcess = (resource, func, requestSettings = {}) => {
	return fetchRequest(resource, requestSettings)
		.then(result => ({result: func(result.response.data)}))
		.catch(handleServerError)
};
