import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { closePopup } from "../../../common/popups/_core/popupActions";
import { productSelectors } from "../../_core/productSelectors";
import { popupSelector } from "../../../common/popups/_core/popupSelectors";
import { CircleBackground } from "../../../common/circleBackground/CircleBackground";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";
import {RollsetPriceInfo} from "../RollsetPriceInfo";
import {RollsetProductQuantity} from "../RollsetProductQuantity";
import { ROUTES } from "../../../../system/router/routeConstants";
import {RollsetPopupProductList} from "./components/RollsetPopupProductList";



export const RollsetPopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rollsetId = useSelector(popupSelector.props).rollsetId;
  const rollset = useSelector(productSelectors[PRODUCT_TYPES.rollset.id]).get(rollsetId);

  const handleClick = () => {
    history.push(ROUTES.main.path);
    dispatch(closePopup());
  };

  return (
    <div className="popup popup--product popup--show">

      <button
        onClick={handleClick}
        className="popup__close"
        type="button"
        title="закрыть">&times;
      </button>

      <div className="popup-outer">
        <div className="popup-product">
          <h1 className="popup-product__title">{rollset.title.toLowerCase()}</h1>

          <ul className="popup-product-types">
            <RollsetPriceInfo type="big" noAmount productType={rollset}/>
          </ul>
          <article className="popup-product__chars">
            <p>{rollset.get('announce')}</p>
          </article>
          <RollsetPopupProductList products={rollset.children}/>
          <RollsetProductQuantity product={rollset} type={PRODUCT_TYPES.rollset.id}/>
          <ul className="popup-product-ingredients">
            <li className="popup-product-ingredients__list-item">вес {rollset.info.amount}</li>
            <li className="popup-product-ingredients__list-item">калории {rollset.info.calories} ккал</li>
            <li className="popup-product-ingredients__list-item">углеводы {rollset.info.carbohydrates} г</li>
            <li className="popup-product-ingredients__list-item">белки {rollset.info.proteins} г</li>
            <li className="popup-product-ingredients__list-item">жиры {rollset.info.fats} г</li>
          </ul>
        </div>
      </div>
      <CircleBackground className="hide-on-mobile"/>
    </div>
  );
};
