import React from "react";
import { useSelector } from "react-redux";

import { SectionTitle } from "../../common/sectionTitle/SectionTitle";
import { productSelectors } from "../_core/productSelectors";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import { WarmerList } from "./WarmerList";
import { cartSelectors } from "../../cart/_core/cartSelectors";


export const WarmerSection = () => {
    const coffee = useSelector(productSelectors[PRODUCT_TYPES.warmer.subtypes.coffee.id]);
    const tea = useSelector(productSelectors[PRODUCT_TYPES.warmer.subtypes.tea.id]);
    const other = useSelector(productSelectors[PRODUCT_TYPES.warmer.subtypes.other.id]);
    const isPickup = useSelector(cartSelectors.isPickup);

    const availableTea = tea.filter(i => i.isOnTrade && i.isSuitableForCurrentCart);
    const availableCoffee = coffee.filter(i => i.isOnTrade && i.isSuitableForCurrentCart);
    const availableOther = other.filter(i => i.isOnTrade && i.isSuitableForCurrentCart);

    if ( (availableCoffee.size + availableTea.size + availableOther.size) === 0 ) return null;
    if ( !isPickup ) return null;

    return (
        <div className="main-goods">
            {!!availableCoffee.size && (
                <>
                    <SectionTitle title="Кофе"/>
                    <WarmerList products={coffee} />
                </>
            )}

            {!!availableTea.size && (
                <>
                    <SectionTitle title="Чай"/>
                    <WarmerList products={tea} />
                </>
            )}

            {!!availableOther.size && (
                <WarmerList products={other} />
            )}
        </div>
    );
}
