import { CS } from "../../../utils/modules/reselect";

import { sharedSelectors } from "../../shared/core/sharedSelectors";


export const settingSelectors = {
  settings: CS(sharedSelectors.settings, settings => settings.get('settings')),
  slides: CS(sharedSelectors.settings, settings => settings.get('slides')),
  content: CS(sharedSelectors.settings, settings => settings.get('content')),
  payments: CS(sharedSelectors.settings, settings => settings.get('payments')),
};
