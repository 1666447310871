import { Record } from "immutable";

const ManufactureWorkTimeInfoDataModelRecord = Record({
	closeAt: null,
	openAt: null,
	isOpen: null
});

export class ManufactureWorkTimeInfoDataModel extends ManufactureWorkTimeInfoDataModelRecord {
  //
}
