import React from "react";
import {useSelector} from "react-redux";

import {customerSelectors} from "../../customer/_core/customerSelectors";
import {popupSelector} from "../../common/popups/_core/popupSelectors";
import {AddressPopupChangePasswordForm} from "./myAddressTabs/AddressPopupChangePasswordForm";
import {AddressPopupDeleteConfirmaionForm} from "./myAddressTabs/AddressPopupDeleteConfirmaionForm";
import {AddressPopupLoginForm} from "./myAddressTabs/AddressPopupLoginForm";
import {AddressPopupLogoutForm} from "./myAddressTabs/AddressPopupLogoutForm";
import {AddressPopupRequestPasswordForm} from "./myAddressTabs/AddressPopupRequestPasswordForm";
import {AddressPopupSelectMyAddressForm} from "./myAddressTabs/AddressPopupSelectMyAddressForm";
import {PopupNotification} from "./myAddressTabs/internalComponents/PopupNotification";
import {ADDRESS_POPUP_FORMS} from "./addressPopupConstants";
import {Error} from "../../common/notifications/error/Error";


export const AddressPopupMyAdressTab = () => {
	const customerInfo = useSelector(customerSelectors.customerInfo);
	const popupError = useSelector(popupSelector.props).error;
	const currentForm = useSelector(popupSelector.props).myAddressForm || (
		!!customerInfo.get('phone') ? ADDRESS_POPUP_FORMS.selectAddress : ADDRESS_POPUP_FORMS.login
	);

	return (
		<>
			<PopupNotification className="mv-m"/>
			<Error description={popupError} className="m-centered mv-m" />

			{currentForm === ADDRESS_POPUP_FORMS.login && (
				<AddressPopupLoginForm/>
			)}

			{currentForm === ADDRESS_POPUP_FORMS.logout && (
				<AddressPopupLogoutForm/>
			)}

			{currentForm === ADDRESS_POPUP_FORMS.requestPassword && (
				<AddressPopupRequestPasswordForm/>
			)}

			{currentForm === ADDRESS_POPUP_FORMS.selectAddress && (
				<AddressPopupSelectMyAddressForm/>
			)}

			{currentForm === ADDRESS_POPUP_FORMS.changePassword && (
				<AddressPopupChangePasswordForm/>
			)}

			{currentForm === ADDRESS_POPUP_FORMS.deleteiConfirmaion && (
				<AddressPopupDeleteConfirmaionForm/>
			)}
		</>
	)
};
