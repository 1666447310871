import { all, put, select, takeEvery } from "redux-saga/effects";

import { featureSagaCreator } from "../../../utils/featureSagaCreator";
import { addressFormModificationHandled } from "./cartPageActions";
import { cartPageSelectors } from "./cartPageSelectors";
import { CART_UPDATED } from "../../../components/cart/_core/cartConstants";


function* cartUpdatedSaga()
{
  const cartFormModified = yield select(cartPageSelectors.addressFormModified);

  if (cartFormModified) {
    yield put(addressFormModificationHandled());
  }
}

export const cartPageSaga = featureSagaCreator({
	workers: function* () {
		yield all([
			takeEvery(CART_UPDATED, cartUpdatedSaga),
		])
	},
});

