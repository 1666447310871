import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./SubmitSection.css";
import { cartProcessing } from "../../_core/cartActions";
import { customerSelectors } from "../../../customer/_core/customerSelectors";
import { cartPageSelectors } from "../../../../pages/_core/cartPage/cartPageSelectors";
import { ROUTES } from "../../../../system/router/routeConstants";
import { ManufactureOnStopError } from "../validationComponents/ManufactureOnStopError";
import { Error } from "../../../common/notifications/error/Error";
import { Button } from "../../../common/button/Button";
import { cartSelectors } from "../../_core/cartSelectors";
import { Warning } from "../../../common/notifications/warning/Warning";
import {SubmitPrivacyPolicySection} from "./SubmitPrivacyPolicySection";


const ScrollToTopButton = ({scrollToTop}) => (
  <div className="form-finish-order__backto">
    <a href="#" onClick={scrollToTop}>вернуться к редактированию заказа</a>
  </div>
)

export const SubmitSection = ({deliveryTimeSelected}) => {
  const dispatch = useDispatch();
  const cart = useSelector(customerSelectors.cart);
  const isOrderProcessing = useSelector(cartPageSelectors.isOrderProcessing);
  const orderError = useSelector(cartPageSelectors.orderError);
  const addressError = useSelector(customerSelectors.addressError);
  const manufactureInfo = useSelector(cartSelectors.manufactureInfo);

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 380,
      behavior: "smooth"
    });
  };

    const submitOrderHandler = () => {
        dispatch(cartProcessing({deliveryTimeSelected}));
    };

    const renderError = () => {
        if (cart.hasItemToDelete) {
            return (
                <>
                    <Error className="m-centered"
                           description="В заказе есть товары, которых нет в наличии,
							 или которые не могут быть доставлены по выбранному адресу.
								Удалите их прежде чем оформить заказ."/>

                </>
            )
        }

        if (cart.status.error || orderError) {
            return (
                <>
                    <Error className="m-centered" description={cart.status.error || orderError}/>

                </>
            )
        }
    }

  // if (cart.hasItemToDelete) {
  //   return (
  //     <>
  //       <Error className="m-centered"
  //              description="В заказе есть товары, которых нет в наличии,
	// 						 или которые не могут быть доставлены по выбранному адресу.
	// 							Удалите их прежде чем оформить заказ."/>
  //       <div className="form-finish-order">
  //         <ScrollToTopButton scrollToTop={scrollToTop}/>
  //       </div>
  //     </>
  //   )
  // }
  //
  // if (cart.status.error || orderError) {
  //   return (
  //     <>
  //       <Error className="m-centered" description={cart.status.error || orderError}/>
  //       <div className="form-finish-order">
  //         <ScrollToTopButton scrollToTop={scrollToTop}/>
  //       </div>
  //     </>
  //   )
  // }
  return (
    <>

      <Warning description={addressError || cart.status.warning} className="m-centered mv-m"/>
      <ManufactureOnStopError curentManufacture={manufactureInfo}/>

      <div className="form-finish-order">
        <ScrollToTopButton scrollToTop={scrollToTop}/>

        <div className="form-finish-button">
          <div className="form-finish-button__action">


              <div className="form-finish-button__action-wrapper">
                  {renderError()}

                  <SubmitPrivacyPolicySection/>
                  <Button
                      onClick={submitOrderHandler}
                      disabled={isOrderProcessing || addressError || cart.hasItemToDelete ||
                          (manufactureInfo.manufactureId && manufactureInfo.error)}
                      className="btn-primary">оформить
                  </Button>


                  <div className="form-finish-note-container">
                      <div className="form-privacy-policy-wrapper">
                          <p className="form-finish-button__note">Нажимая эту кнопку, Вы соглашаетесь
                              <Link to={{
                                  pathname: ROUTES.rules.path
                              }}> с правилами оказания услуг
                              </Link>

                              {cart.additionalData.ageLimit
                                  ? (
                                      <>
                                          <span>и подтверждаете, что вам {cart.additionalData.ageLimit} или более лет</span>
                                          <span>для получения заказа может потребоваться документ, удостоверяющий возраст</span>
                                      </>
                                  ) : null
                              }
                          </p>
                      </div>
                      <div className="form-privacy-policy-wrapper">
                          <p className="form-finish-button__note">Нажимая эту кнопку, Вы соглашаетесь с <Link to={{pathname: ROUTES.privatPolicy.path}}>политикой обработки персональных данных</Link> и даете свое согласие на обработку персональных данных</p>
                      </div>

                  </div>
              </div>

          </div>
        </div>
      </div>
    </>
  );
};
