import React, {Component} from "react";

import "./PrivatPolicyPopup.css";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../../system/router/routeConstants";

export class PrivatPolicyPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isConfirm: localStorage.getItem('privat_policy'),
        }
    }

    confirm = () => {
        localStorage.setItem('privat_policy', 1)
        this.setState({isConfirm: true,})
    };

    detail = () => {
            localStorage.setItem('privat_policy', 1)
            this.setState({isConfirm: true,})
    }



    render() {

        return this.state.isConfirm ? null : (<div className="privat-policy-wrapper">
                <div className="body-section privat-policy-container">
                    <p className="privat-policy-description">Для обеспечения удобства пользователей сайта используются cookies</p>
                    <div className='privacy-policy-actions-wrapper'>
                        <div className='privacy-policy-actions-container'>
                            <button onClick={this.confirm} id='btnPrivacyPolicyConfirm' className="btn btn-primary btn-privat-policy-confirm"
                                    type="submit">принять
                            </button>
                            <button onClick={this.confirm} id='btnPrivacyPolicyCancel' className="btn btn-primary btn-privat-policy-confirm"
                                    type="submit">отклонить
                            </button>
                        </div>
                        <Link id='linkCookie' to={{pathname: ROUTES.privatPolicy.path, hash: 'cookie'}} >подробнее</Link>
                    </div>
                </div>
            </div>)
    }
}
