import React from "react";
import { useSelector } from "react-redux";

import { settingSelectors } from "../components/settings/_core/settingSelectors";
import { SectionTitle } from "../components/common/sectionTitle/SectionTitle";
import {scrollToTop} from "../utils/scrollToAnchor";


export const PaymentsPage = () => {
  const content = useSelector(settingSelectors.content);
    scrollToTop()

  return (
    <div className="content-page">
      <SectionTitle title="способы оплаты"/>
      <div className="for-editor">
        <div dangerouslySetInnerHTML={{ __html: content.payments }}/>
      </div>
    </div>
  );
};
