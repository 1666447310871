import { OrderedMap } from "immutable";

import { __ } from "../../utils/utils";
import AnswerModel from "../../models/Answer/AnswerModel";


export const answersCreators = data => {
    return new AnswerModel({
        active: __(data.active),
        description: __(data.description),
        id: __(data.id),
        position: __(data.position),
        title: __(data.title),
        anchor: __(data.anchor)
    });
}

export const answersMapProcess = data => OrderedMap().withMutations(vacancyMap => {
    Object.values(data).forEach(vacancy => vacancyMap.set(__(vacancy.id), answersCreators(vacancy)))
});
