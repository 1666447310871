import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { requestPassword } from "../../../customer/_core/customerActions";
import { changePopupProps } from "../../../common/popups/_core/popupActions";
import { ADDRESS_POPUP_FORMS } from "../addressPopupConstants";


export const AddressPopupRequestPasswordForm = () => {
  const dispatch = useDispatch();
  const [ phone, setPhone ] = useState('');

  const handlePhoneChange = (e) => {
    setPhone(e.target.value)
  };

  const handlePasswordRequest = () => {
    dispatch(requestPassword({ phone }));
  };

  const handleOpenLoginForm = (e) => {
    e.preventDefault();
    dispatch(changePopupProps({
      myAddressForm: ADDRESS_POPUP_FORMS.login
    }))
  };

	return (
		<>
			<div className="popup-form">
				<div className="form-group">
					<div className="form-label form-label--alt">номер в международном формате</div>
					<div className="form-with-sidelabel form-size-long">
						<input className="form-input form-size-full form-input-big"
									 autoComplete="tel"
									 onChange={handlePhoneChange}
									 type="tel"/>
						<div className="form-with-sidelabel-label">
							<div className="form-with-sidelabel-label__inner">например:<br/>+375296040000</div>
						</div>
					</div>
				</div>
				<div className="popup-form-button">
					<button className="btn btn-small btn-primary"
                  type="submit"
                  onClick={handlePasswordRequest}>
            выслать новый пароль
          </button>
        </div>
        <div className="form-footer-action">
          <a href="#" onClick={handleOpenLoginForm}>вернуться к авторизации</a>
        </div>
      </div>
    </>
  )
};
