import React from "react";

import {
	DefaultNotAvailableForAddressComponent,
	DefaultNotInStockComponent,
	ProductQuantity
} from "../productQuantity/ProductQuantity";
import { useSelector } from "react-redux";
import { settingSelectors } from "../../settings/_core/settingSelectors";


const DesertProductComponent = ({ product, handleRemoveItem, handleAddItem, count }) => {
  const settings = useSelector(settingSelectors.settings);

  if (settings.baskedDisabled) {
    return null;
  }

	if (!product.isOnTrade) {
		return (<DefaultNotInStockComponent />);
	}

	if (!product.isSuitableForCurrentCart) {
		return (<DefaultNotAvailableForAddressComponent />);
	}

  return (
    <div className="secondary-goods-quantity">
        <button className="main-goods-quantity__controls"
                data-button-type="minus"
                onClick={handleRemoveItem}>–
        </button>
        <strong className="main-goods-quantity__numbers">{count}</strong>
        <button className="main-goods-quantity__controls"
                data-button-type="plus"
                onClick={handleAddItem}>+
        </button>
    </div>
  )
};

export const DessertProductQuantity = (props) => {
  return (
    <ProductQuantity {...props}>
      <DesertProductComponent product={props.product}/>
    </ProductQuantity>
  )
};
