import { useEffect } from "react";

export function useHeaderScrollHandler() {
    useEffect(() => {
        const navMain = document.getElementsByClassName('header__outer')[0];
        const navBig = document.getElementsByClassName('header__inner')[0];
        const maxScreenH = 425;
        const minScreenH = 90;
        const maxTabletH = 425;
        const minTabletH = 110;
        const maxMobileH = 280;
        const minMobileH = 80;
        let maxH = 0;
        let minH = 0;

        const resizeHeader = () => {

            const winScrollTop = window.scrollY;
            if (window.innerWidth > 979) {
                maxH = maxScreenH;
                minH = minScreenH;
            } else if (window.innerWidth < 980 && window.innerWidth > 579) {
                maxH = maxTabletH;
                minH = minTabletH;
            } else {
                maxH = maxMobileH;
                minH = minMobileH;
            }

            let scrollDiff = maxH - winScrollTop;
            let navDiff = maxH - minH;

            if (winScrollTop < (maxH + 1)) {
                // $body.classList.remove('body-header-minimal');
                if (winScrollTop < (navDiff + 1)) {
                    navMain.style.height = `${scrollDiff}px`;
                } else {
                    navMain.style.height = `${minH}px`;
                }
                navBig.style.marginTop = `${winScrollTop * -1}px`;

            } else {
                navBig.style.marginTop = `${maxH * -1}px`;
                navMain.style.height = `${minH}px`;
                // $body.classList.add('body-header-minimal')
            }

        };

        window.addEventListener('scroll', resizeHeader);
        window.addEventListener('resize', resizeHeader);

        resizeHeader();
    })
}
