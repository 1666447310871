import {OrderedMap} from "immutable";

import {__, toBool} from "../../../utils/utils";
import BentoModel from "../../../models/Products/Bento/BentoModel";
import { PokeInfo } from "../../../models/Products/Poke/PokeModel";

const bentoInfoCreator = data => new PokeInfo({
  calories: __(data.calories),
  carbohydrates: __(data.carbohydrates),
  fats: __(data.fats),
  proteins: __(data.proteins),
  price: __(data.price) / 10000,
});
export const bentoCreator = data => new BentoModel({
	announce: __(data.announce),
	code: __(data.code),
	id: __(data.id),
	position: __(data.position),
	publicImages: data.public_images,
	title: __(data.title),
  releaseDayOfWeek: __(data.release_day_of_week),

	isOnTrade: toBool(data.is_on_trade),
	isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
	hasMedium: toBool(data.has_medium),

	info: new bentoInfoCreator(data),
});

export const bentosProcess = (data) => {
	return (new OrderedMap()).withMutations(map => {
		Object.entries(data).forEach(([id, item]) => {
			map.set(id, bentoCreator(item))
		})
	})
};
