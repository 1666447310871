import React from "react";

import { useSelector } from "react-redux";
import { settingSelectors } from "../components/settings/_core/settingSelectors";
import {scrollToTop} from "../utils/scrollToAnchor";


export const ServiceRulesPage = () => {
    const content = useSelector(settingSelectors.content);
    scrollToTop()

    return (
        <div className="content-page">
            <div dangerouslySetInnerHTML={{__html: content.rules}}/>
        </div>
    );
}
