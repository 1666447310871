import { CS } from "../../../utils/modules/reselect";
import { customerSelectors } from "../../customer/_core/customerSelectors";
import { productSelectors } from "../../products/_core/productSelectors"

const getProps = (state, props) => props;
const shipping = CS(customerSelectors.cart, cart => cart?.shipping);

export const cartSelectors = {
	additionalData: CS(customerSelectors.cart, cart => cart?.additionalData),
	customerInfo: CS(customerSelectors.cart, cart => cart?.customerInfo),
	details: CS(customerSelectors.cart, cart => cart?.details),
	items: CS(customerSelectors.cart, cart => cart?.items),
	priceSpecification: CS(customerSelectors.cart, cart => cart?.priceSpecification),
	settings: CS(customerSelectors.cart, cart => cart?.settings),
	shipping,
	status: CS(customerSelectors.cart, cart => cart?.status),

	address: CS(customerSelectors.cart, cart => cart?.shipping?.address),
	isPickup: CS(shipping, ({shippingType}) => shippingType === 'pickup'),
	isPreorder: CS(shipping, s => s.timeDetails.timeType === 'preorder'),
	manufactureInfo: CS(customerSelectors.cart, cart => cart.settings.manufactureInfo),
	hasItems: CS(customerSelectors.cart, cart => !!cart.items.toSeq().reduce((result, productType) => result + productType.size, 0)),
	cartItem: CS(
		customerSelectors.cart,
		getProps,
		(cartState, product) => {
			const productType = product.get('subtype') || product.get('type');
			return cartState?.items?.get(productType)?.get(+product.get('id')) || {}
		}
	),

  	rollCount: CS([customerSelectors.cart, productSelectors.rollset], (cart, rollsets) => {
		  return cart?.items?.roll.reduce((acc, roll) => acc + roll.count, 0)
			  + cart?.items?.rollset.reduce((acc, rollset) => acc + rollsets.get(rollset.id)?.info?.rollsCount * rollset.count, 0)
	  }),
  	updating: CS(customerSelectors.cart, cart => cart?.updating),
	pinnedAt: CS(customerSelectors.cart, cart => cart?.details?.pinnedAt),
};
