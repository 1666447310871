import {PRODUCT_TYPES, ROLL_SIZES} from "../api/products/productBaseConstants";

export const sendOrderDoneGoogleTagAnalyticReport = (order) => {
  if (!window.dataLayer) {
    throw new Error('No Google Tag Data Layer defined');
  }

  const getVariant = (item => {
    switch (item.type) {
      case PRODUCT_TYPES.roll.id:
        return ROLL_SIZES[item.size].displayTitle;
      case PRODUCT_TYPES.drink.id:
        return item.announce;
      default:
        return '';
    }
  });

  const ItemsToSent =
    Object.values(order.items.toObject())
      .map(section => Object.values(section.toObject())
        .map((item, key) => (
          {
            id: item.type + ':'+ item.id,
            price: Number((item.realizationPrice).toFixed(2)),
            name: item.title,
            category: item.type,
            variant: getVariant(item),
            quantity: item.count,
          })))
      .flat(2);

  window.dataLayer.push({
    'event': 'ecom',
    'ecommerce': {
      'purchase': {
        'actionField': {
          'id': `${order.sync}.${order.num}${order.hash}`,
          'currency': 'BYN',
          'affiliation': 'godzilla.by',
          'revenue': Number((order.priceSpecification.orderTotal).toFixed(2)),
        },
        'products': ItemsToSent
      }
    }
  });
    window.dataLayer.push({
        'event': 'uuid_push',
        'client_data': { 'uuid': order.customerInfo.clientUidHash }
    });
}
