import React, { useState } from "react";
import "./vacancies-list.css";

export const VacanciesListItem = ({ vacancy }) => {
  const [ open, setOpen ] = useState(false);

  const toggleVacancy = () => {
    setOpen(!open);
  };

  return (
    <>
      <h3 onClick={toggleVacancy}
          style={{ fontSize: "32px", cursor: "pointer", color: "#637c6c", marginTop: "10px" }}>{vacancy.title}
      </h3>
      {open && ( <div className={"vacancy-container"} dangerouslySetInnerHTML={{ __html: vacancy.description }}/> )}
    </>
  )
};
