import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {changePassword} from "../../../customer/_core/customerActions";
import {changePopupProps} from "../../../common/popups/_core/popupActions";
import {Button} from "../../../common/button/Button";
import {ADDRESS_POPUP_FORMS} from "../addressPopupConstants";
import {customerSelectors} from "../../../customer/_core/customerSelectors";


export const AddressPopupChangePasswordForm = () => {
	const dispatch = useDispatch();
	const customerInfo = useSelector(customerSelectors.customerInfo);
	const [password, setPassword] = useState('');

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handlePasswordRequest = () => {
		dispatch(changePassword(password));
	};

	const handleOpenSelectAddressForm = (e) => {
		e.preventDefault();
		dispatch(changePopupProps({
			myAddressForm: ADDRESS_POPUP_FORMS.selectAddress
		}))
	};

	return (
		<>
			<div className="popup-form">

				<div className="form-group">
					<div className="form-label form-label--alt">новый пароль</div>
					<div className="form-with-sidelabel form-size-long">

						<input style={{display: 'none'}}
									 autoComplete="username"
									 name="phone"
									 defaultValue={customerInfo.get('phone')}
									 type="tel"/>

						<input className="form-input form-size-full form-input-big"
									 autoComplete="password"
									 name="password"
									 onChange={handlePasswordChange}
									 type="password"/>
					</div>
				</div>
				<div className="popup-form-button">
					<Button className="btn-small btn-primary"
									onClick={handlePasswordRequest}>
						сохранить изменение
					</Button>
				</div>
				<div className="form-footer-action">
					<a href="#" onClick={handleOpenSelectAddressForm}>отмена</a>
				</div>
			</div>
		</>
	)
};
