const decorator = str => 'order/' + str;


export const FETCH_ORDER = decorator('FETCH_ORDER');
export const ORDER_LOADED = decorator('ORDER_LOADED');
export const RESET_ORDER = decorator('RESET_ORDER');
export const ORDER_LOADED_WITH_ERROR = decorator('ORDER_LOADED_WITH_ERROR');

export const UPDATE_ORDER = decorator('UPDATE_ORDER');
export const ORDER_UPDATED = decorator('ORDER_UPDATED');
export const ORDER_UPDATED_WITH_ERROR = decorator('ORDER_UPDATED_WITH_ERROR');
