import {OrderedMap} from "immutable";

import {__, toBool} from "../../../utils/utils";
import RollModel from "../../../models/Products/Roll/RollModel";


export const rollsCreator = data => new RollModel({
	announce: __(data.announce),
	code: __(data.code),
	id: __(data.id),
	position: __(data.position),
	publicImages: data.public_images,
	title: __(data.title),

	isOnTrade: toBool(data.is_on_trade),
	isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
	hasMedium: toBool(data.has_medium),

	big: {
		amount: data.big_amount,
		calories: __(data.big_calories),
		carbohydrates: __(data.big_carbohydrates),
		fats: __(data.big_fats),
		price: __(data.big_price) / 10000,
		proteins: __(data.big_proteins),
		priceAvoided: __(data.big_price_avoided) / 10000,
	},

	medium: __(data.has_medium) && {
		amount: data.medium_amount,
		calories: __(data.medium_calories),
		carbohydrates: __(data.medium_carbohydrates),
		fats: __(data.medium_fats),
		price: __(data.medium_price) / 10000,
		proteins: __(data.medium_proteins),
		priceAvoided: __(data.medium_price_avoided) / 10000,
	},
});

export const rollsProcess = (data) => {
	return (new OrderedMap()).withMutations(map => {
		Object.entries(data).forEach(([id, item]) => {
			map.set(id, rollsCreator(item))
		})
	})
};
