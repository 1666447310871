import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {changePopupProps, closePopup} from "../common/popups/_core/popupActions";
import { AddressPopupDeliveryTab } from "./addressPopupTabs/AddressPopupDeliveryTab";
import { AddressPopupMyAdressTab } from "./addressPopupTabs/AddressPopupMyAdressTab";
import { AddressPopupPickupTab } from "./addressPopupTabs/AddressPopupPickupTab";
import { CircleBackground } from "../common/circleBackground/CircleBackground";
import { PopupLayoutTabButton } from "./addressPopupTabs/myAddressTabs/internalComponents/PopupLayoutTabButton";
import { routerHistorySelector } from "../../system/router/routerHistoryReducer";


export const AddressPopup = ({ onClose, tab = 'delivery' }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routerHistory = useSelector(routerHistorySelector);

  const [ activeTab, setActiveTab ] = useState(tab);
  const changeTab = (tab) => () => {
    dispatch(changePopupProps({ error: null, warning: null }));
    setActiveTab(tab)
  };

  const close = () => {
    dispatch(closePopup())
    history.push(routerHistory.previousLocation.pathname || '/');
  }

  return (
    <>
      <div className="popup popup--address popup--show">
        <button className="popup__close" type="button" title="Закрыть" onClick={close}>&times;</button>
        <div className="popup-outer">

          <ul className="popup-nav">
            <PopupLayoutTabButton onClick={changeTab('delivery')} title="доставка" active={activeTab === 'delivery'}/>
            <PopupLayoutTabButton onClick={changeTab('pickup')} title="самовывоз" active={activeTab === 'pickup'}/>
            <PopupLayoutTabButton onClick={changeTab('myadress')} title="мои адреса" active={activeTab === 'myadress'}/>
          </ul>

          {activeTab === 'delivery' && <AddressPopupDeliveryTab close={close} onClose={onClose}/>}
          {activeTab === 'pickup' && <AddressPopupPickupTab close={close} onClose={onClose}/>}
          {activeTab === 'myadress' && <AddressPopupMyAdressTab close={close} onClose={onClose}/>}

        </div>
        <CircleBackground/>
      </div>
    </>
  );
};
