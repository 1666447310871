import React from "react";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";


export const CartStickProductList = ({ stick }) => {
  return (
    <>
      {stick.valueSeq().map(stickItem => (
        <CartListItem key={`stick_${stickItem.get('id')}`}
                      item={stickItem}
					  hideAnnounce
                      type={PRODUCT_TYPES.garnish.id}
                      subtype={PRODUCT_TYPES.garnish.subtypes.stick.id}
					  imageType="alt"
				/>
			))}
		</>
	)
};
