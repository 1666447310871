import React, { Component } from 'react';

import './YandexMapConstructor.css';

export class YandexMapConstructor extends Component {
    constructor(props) {
        super(props);

        this.yandexMap = React.createRef();
    }

    componentDidMount() {
        if(this.props.constructorURL) {
            const script = document.createElement("script");
            const src = this.deconstruct(this.props.constructorURL);

            if(src) {
                script.type = 'text/javascript';
                script.charset = "utf-8";
                script.src = src;
                script.async = true;

                this.yandexMap.current.appendChild(script);
            } else {
                console.error('React-Yandex-Maps-Constructor: Bad script format. Put full script string to the script tag from <script ... to </script>.');
            }
        } else {
            console.error('React-Yandex-Maps-Constructor: script tag not found.');
        }
    }

    componentWillUnmount() {
        while(this.yandexMap.current.firstChild) {
            this.yandexMap.current.removeChild(this.yandexMap.current.firstChild);
        }
    }

    deconstruct(url) {
        if(url.startsWith('https://api-maps.yandex.ru/')) {
            return url;
        }
    }

    render() {
        return <div className="delivery-map-container" ref={this.yandexMap} />;
    }
}
