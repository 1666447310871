import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { fetchOrder } from "../components/order/_core/orderActions";
import { OrderList } from "../components/order/OrderList";
import { OrderFeedbackForm } from "../components/order/feedbackForm/OrderFeedbackForm";
import { loadFeedbackTroubles } from "../components/order/feedbackForm/_core/feedackTroublesActions";


export const OrderPage = () => {
	const {pathname} = useLocation();
	const orderHash = pathname.split('/')[2];
	const dispatch = useDispatch();

	useEffect(() => {
			dispatch(fetchOrder(orderHash));
			dispatch(loadFeedbackTroubles())
		}, [pathname]
	);

	return (
		<div className="content-page">
			<OrderList/>
			<OrderFeedbackForm />
		</div>
	);
};
