import { Record } from "immutable";
import Immutable from "immutable";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";


const RollsetInfoRecord = Record({
  amount: null,
  calories: null,
  carbohydrates: null,
  fats: null,
  price: 0,
  proteins: null,
  priceAvoided: 0,
  freeSticks: 0,
  rollsCount: 0,
  autoAmount: 0,
});

export class RollsetInfo extends RollsetInfoRecord {
  //
}

const RollsetChildrenMapRecord = Immutable.Record({
  [PRODUCT_TYPES.roll.id]: Immutable.Map(),
  [PRODUCT_TYPES.rollset.id]: Immutable.Map(),
  [PRODUCT_TYPES.dessert.id]: Immutable.Map(),
  [PRODUCT_TYPES.poke.id]: Immutable.Map(),
  [PRODUCT_TYPES.bento.id]: Immutable.Map(),
  [PRODUCT_TYPES.soup.id]: Immutable.Map(),
  [PRODUCT_TYPES.drink.id]: Immutable.Map(),

  [PRODUCT_TYPES.dessert.subtypes.cookie.id]: Immutable.Map(),
  [PRODUCT_TYPES.dessert.subtypes.cake.id]: Immutable.Map(),

  [PRODUCT_TYPES.garnish.subtypes.garnish.id]: Immutable.Map(),
  [PRODUCT_TYPES.garnish.subtypes.sauce.id]: Immutable.Map(),
  [PRODUCT_TYPES.garnish.subtypes.stick.id]: Immutable.Map(),

  [PRODUCT_TYPES.warmer.subtypes.coffee.id]: Immutable.Map(),
  [PRODUCT_TYPES.warmer.subtypes.tea.id]: Immutable.Map(),
  [PRODUCT_TYPES.warmer.subtypes.other.id]: Immutable.Map(),
});

export class RollsetChildrenMapModel extends RollsetChildrenMapRecord {
  //
}

const RollsetModelRecord = Record({
  active: null,
  ageLimit: null,
  announce: null,
  isOnTrade: null,
  isSuitableForCurrentCart: null,
  position: null,
  price: 0,
  publicImages: {},
  id: null,
  title: '',
  info: new RollsetInfo(),
  type: PRODUCT_TYPES.rollset.id,
  children: new RollsetChildrenMapModel(),
});

export default class RollsetModel extends RollsetModelRecord {
  //
}