import Immutable from "immutable";

import {
	FETCH_PREORDER_INTERVALS, PREORDER_INTERVALS_LOADED, PREORDER_INTERVALS_LOADED_WITH_ERROR,
	RESET_PREORDER_INTERVALS
} from "./preorderConstants";


export const preorderIntervalsReducer = (state = Immutable.Map({}), {type, payload}) => {
	switch (type) {
		case RESET_PREORDER_INTERVALS:
			return Immutable.Map({});

		case FETCH_PREORDER_INTERVALS:
			return state.set('loading', true).set('error', false);

		case PREORDER_INTERVALS_LOADED:
			return state.set('loading', false).set('error', false).set('data', payload);

		case PREORDER_INTERVALS_LOADED_WITH_ERROR:
			return state.set('loading', false).set('error', true);

		default:
			return state
	}
};

