const decorator = str => 'product/' + str;


export const LOADED_ROLLS = decorator('LOADED_ROLLS');
export const LOADED_ROLLSETS = decorator('LOADED_ROLLSETS')
export const LOADED_POKES = decorator('LOADED_POKES');
export const LOADED_SOUPS = decorator('LOADED_SOUPS');
export const LOADED_BENTOS = decorator('LOADED_BENTOS');
export const LOADED_GARNISHES = decorator('LOADED_GARNISHES');
export const LOADED_DRINKS = decorator('LOADED_DRINKS');
export const LOADED_DESSERTS = decorator('LOADED_DESSERTS');
export const LOADED_WARMERS = decorator('LOADED_WARMERS');

export const LOADED_FILTERS = decorator('LOADED_FILTERS');
export const LOADED_INGREDIENTS = decorator('LOADED_INGREDIENTS');

export const LOADED_SUGGESTED_PRODUCTS = decorator('LOADED_SUGGESTED_PRODUCTS')
