import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
    cartProcessedWithError,
    cartProcessingApproved,
    toggleConfirmPrivacyPolicyFeedbackService,
    toggleConfirmPrivacyPolicyOrderData,
    toggleConfirmPrivacyPolicySmsProcessOrder
} from "../_core/cartActions";
import { cartSelectors } from "../_core/cartSelectors";
import { closePopup } from "../../common/popups/_core/popupActions";
import { SectionTitle } from "../../common/sectionTitle/SectionTitle";

import './PrivacyPolicyRulesPopup.css';
import {customerSelectors} from "../../customer/_core/customerSelectors";
import {ROUTES} from "../../../system/router/routeConstants";
import {Link} from "react-router-dom";


export const PrivacyPolicyRulesPopup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {freeItemsRemaining} = useSelector(cartSelectors.additionalData);
    const cart = useSelector(customerSelectors.cart);
    const approveCartProcessing = () => {
        dispatch(cartProcessingApproved());
        dispatch(closePopup());
    }

    const close = () => {
        dispatch(cartProcessedWithError());
        dispatch(closePopup());
    }

    const closeCartConfirmingPopup = () => {
        history.push('/cart#garnish');
        close();
    }

    const onChangeConfirmPrivacyPolicyOrderData = () => {
        dispatch(toggleConfirmPrivacyPolicyOrderData());
    };

    const onChangeConfirmPrivacyPolicySmsProcessOrder = () => {
        dispatch(toggleConfirmPrivacyPolicySmsProcessOrder());
    };

    const onChangeConfirmPrivacyPolicyFeedbackService = () => {
        dispatch(toggleConfirmPrivacyPolicyFeedbackService());
    };

    return (
        <div className="popup-modal popup--address popup--show modal-privacy-policy">
            <Link className="popup__close" to={{pathname: '/cart'}}>
                &times;
            </Link>
            <div className="cart-confirm-popup-layout">
                <div className="confirm-popup-modal">
                    <div className="cart-confirm-popup__title" >
                        <SectionTitle title="Обработка ваших персональных данных" />
                    </div>
                    <div className="popup-modal__description" >
                        <div >
                            <p><b>Оформляя заказ на сайте</b> вам необходимо ознакомиться с порядком использования всех персональных данных,
                                которые вы нам предоставляете.</p>

                            <p>  <b>Персональные данные</b> – любая информация, относящаяся к идентифицированному физическому лицу или
                                физическому лицу, которое может быть идентифицировано и которую субъект персональных данных предоставляет о
                                себе.</p>

                            <p><b>Обрабатываемые персональные данные и цели обработки.</b></p>

                            <p>Цели обработки ваших персональных данных:</p>
                            <ul>
                                <li>подтверждение данных, предоставленных при оформлении заказа;</li>
                                <li>заключение, изменение, исполнение публичного договора, контроль исполнения договорных обязательств (обработка
                                    оказываемых услуг, платежей);</li>
                                <li>предоставление обратной связи: предоставление ответов на ваши вопросы, рассмотрение сообщений (обращений);</li>
                                <li>информирование о статусах заказа и информационная sms-рассылка о заказе;</li>
                                <li>обратная связь с целью оценки качества оказанной услуги.</li>
                            </ul>

                            <p><b>Обрабатываемые персональные данные:</b></p>
                            <ul>
                                <li>Имя;</li>
                                <li>номер мобильного телефона;</li>
                                <li>адрес доставки заказа;</li>
                                <li>изображение человека (при посещении объектов общественного питания).</li>
                            </ul>

                            <p><b>Срок хранения персональных данных.</b></p>

                            <p> 5 лет с даты последнего взаимодействия.</p>
                            <p>
                                В соответствии с требованиями свободного, однозначного и информированного характера согласия, согласие на
                                обработку персональных данных предоставляется путем:
                            </p>
                            <p>  - проставления соответствующей отметки «V» напротив каждой из представленных целей обработки ваших персональных
                                данных.</p>

                            <p>
                                Обработка ваших персональных данных осуществляется на основании вашего согласия и ваши персональные данные будут
                                обрабатываться пока в установленном порядке вы не отзовете свое согласие или не потребуете прекращения
                                (удаления) обработки ваших персональных данных, но не более заявленного срока обработки персональных данных.
                            </p>
                            <p>
                                Полный текст Политики обработки персональных данных ООО «Лёгкий ужин» размещен на сайте: <Link target={'_blank'} to={{pathname: ROUTES.privatPolicy.path}}>https://godzilla.by/privacy-policy.</Link>
                            </p>
                            <p>
                                У вас есть право:
                            </p>
                            <ul>
                                <li>на отзыв предоставленного нам согласия;</li>
                                <li>требовать внесения изменений в ваши персональные данные в случае, если ваши персональные данные являются
                                    неполными, устаревшими или неточными;</li>
                                <li>получить от нас информацию о предоставлении ваших персональных данных, обрабатываемых нами, третьими лицами.
                                    Такое право может быть реализовано один раз в календарный год, предоставление такой информации осуществляется
                                    бесплатно.</li>
                                <li>
                                    требовать от нас прекращения обработки ваших персональных данных, включая их удаление, при отсутствии
                                    оснований для обработки персональных данных;
                                </li>
                                <li>
                                    обжаловать наши действия или решения, нарушающие ваши права при обработке персональных данных, в суд в
                                    порядке, установленном гражданским процессуальным законодательством.
                                    Вы можете в любое время отозвать согласие (снять соответствующую отметку на сайте (мобильном приложении)
                                    напротив каждой цели обработки персональных данных) на обработку ваших персональных данных, без объяснения
                                    причин, направив нам заявление по адресу og@pzz.by.
                                </li>
                            </ul>

                            <p>       Заявление должно содержать:</p>

                            <ul>
                                <li>фамилию, собственное имя, отчество (если таковое имеется), адрес места жительства (места пребывания);</li>
                                <li>дату рождения;</li>
                                <li>идентификационный номер, при отсутствии такого номера – номер документа, удостоверяющего личность;
                                    -изложение сути требований.</li>
                                <li>изложение сути требований.</li>
                            </ul>
                            <p>
                                Я подтверждаю, что ознакомился (ознакомилась) с порядком обработки моих персональных данных, мне понятны мои
                                права, механизм реализации моих прав, а также последствия дачи согласия или отказа в даче такого согласия, цели
                                обработки персональных данных, и я даю свое согласие ООО «Лёгкий ужин» на обработку следующих персональных
                                данных (проставить отметку):
                            </p>

                            <div className='actions-wrapper'>
                                <label onClick={onChangeConfirmPrivacyPolicyOrderData}>
                                    <input checked={cart.customerInfo.isConfirmPrivacyPolicyOrderData} type="checkbox"/>
                                        <small>подтверждение данных, предоставленных при оформлении заказа</small>
                                </label>
                                <label onClick={onChangeConfirmPrivacyPolicySmsProcessOrder}>
                                    <input checked={cart.customerInfo.isConfirmPrivacyPolicySmsProcessOrder} type="checkbox"/>
                                    <small> информирование о статусах заказа и информационная sms-рассылка о заказе</small>
                                </label>
                                <label onClick={onChangeConfirmPrivacyPolicyFeedbackService}>
                                    <input checked={cart.customerInfo.isConfirmPrivacyPolicyFeedbackService} type="checkbox"/>
                                    <small> обратная связь с целью оценки качества оказанной услуги</small>
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
