import Immutable from 'immutable';
import m from 'moment';

import { __, toBool } from "../../utils/utils";
import { addressCreator } from "../address/creators/addressCreator";
import { CartModel } from "../../models/Cart/CartModel";
import { cartItemMapCreator } from "./cartItemCreators";
import {ManufactureWorkTimeInfoModel} from "../../models/Manufacture/ManufactureWorkTimeInfoModel";
import {ManufactureWorkTimeInfoDataModel} from "../../models/Manufacture/ManufactureWorkTimeInfoDataModel";


export const DISCOUNT_TYPES = {
	permanentPercentAll: 'permanent_percent_all',
	singlePercentAll: 'single_percent_all',
	singlePercentRoll: 'single_percent_roll',
}


const cartMenufactureInfoCreator = data => {
	return ({
		manufactureId: __(data.manufacture_id),
		isPickupSlowed: toBool(data.is_pickup_slowed),
		onStopAt: __(data.on_stop_at),
		workingTimeInfo: cartManufactureWorkTimeInfoModelCreator(data.working_time_info),
	})
}

export const cartManufactureWorkTimeInfoModelCreator = data => {
	return data && new ManufactureWorkTimeInfoModel({
		pickup: cartManufactureWorkTimeInfoDataModelCreator(data.pickup),
		delivery: cartManufactureWorkTimeInfoDataModelCreator(data.delivery),
		isWorkDay: toBool(data.is_work_day),
	})
}

export const cartManufactureWorkTimeInfoDataModelCreator = data => {
	return data && new ManufactureWorkTimeInfoDataModel({
		closeAt: __(data.close_at),
		openAt: __(data.open_at),
		isOpen: toBool(data.is_open),
	})
}

const cartSettingsCreator = data => {
	return ({
		isDisabledNoContactDelivery: toBool(data.is_disabled_no_contact_delivery),
		isDisabledPaymentByCard: toBool(data.is_disabled_payment_by_card),
		isActivePreorderPickupDiscount: toBool(data.is_active_preorder_pickup_discount),
		manufactureInfo: cartMenufactureInfoCreator(data.manufacture_info),
		manufactureType: __(data.manufacture_type),
		runtimeSushiDiscount20: toBool(data.runtime_sushi_discount_percent20),
		selectedSushiDiscount20: toBool(data.selected_sushi_discount_percent20),
	});
}

export const customerInfoCreator = data => {
	return ({
		name: __(data.name),
		phone: __(data.phone),
		clientUidHash: __(data.client_uid_hash),
		isConfirmedPrivacyPolicyRules: toBool(data.is_confirmed_privacy_policy_rules),
		isConfirmPrivacyPolicyRules: toBool(data.is_confirm_privacy_policy_rules),
		isConfirmPrivacyPolicyOrderData: toBool(data.is_confirm_privacy_policy_order_data),
		isConfirmPrivacyPolicySmsProcessOrder: toBool(data.is_confirm_privacy_policy_sms_process_order),
		isConfirmPrivacyPolicyFeedbackService: toBool(data.is_confirm_privacy_policy_feedback_service),
	})
}

export const cartPriceSpecificationCreator = data => {
	return ({
		deliveryCost: __(data.delivery_cost) / 10000,
		discountItemsAmount: __(data.discount_items_amount) / 10000,
		discountState: Immutable.fromJS(data.discount_state),
		discountedItemsPrice: __(data.discounted_items_price) / 10000,
		paymentType: __(data.payment_type),
		prepareChangeFor: __(data.prepare_change_for),
		rawItemsPrice: __(data.raw_items_price) / 10000,
		orderTotal: __(data.order_total) / 10000,
		orderTotalWithoutFree: __(data.order_total_without_free) / 10000
	})
}

const cartTimeDetailsCreator = data => {
	return ({
		timeType: __(data.time_type), //preorder/runtime
		date: __(data.date),
		time: __(data.time),
		delay: __(data.delay),
		isDelayIncreased: toBool(data.is_delay_increased),
		deliveryAt: m(data.delivery_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YY HH:mm'),
	})
}

export const cartShippingCreator = data => {
	return ({
		address: addressCreator(data.address),
		shippingType: __(data.shipping_type), //pickup/delivery
		noContactOnDelivery: toBool(data.no_contact_on_delivery),
		timeDetails: cartTimeDetailsCreator(data.time_details),
	})
}

const cartStatusCreator = data => {
	return ({
		error: __(data.error),
		warning: __(data.warning),
	})
}

const cartDetailsCreator = data => ({
	cartId: __(data.cart_id),
	pinnedAt: __(data.pinned_at), // время фиксации заказа (basket_at)
	trackedAt: __(data.tracked_at), // текущее время сервера на момент снятия блокировки с корзины после запроса
})

const cartAdditionalDataCreator = data => {
	return ({
		ageLimit: __(data.age_limit),
		freeItemsRemaining: Immutable.fromJS(data.free_items_remaining),
		originalComment: __(data.original_comment),
		confirmedByOperator: toBool(data.confirmed_by_operator),
	})
}

// todo Remove temp creator data
export const cartCreator = data => {
	const [items, hasItemToDelete] = cartItemMapCreator(data.items);
	return new CartModel({
		additionalData: cartAdditionalDataCreator(data.additional_data),
		customerInfo: customerInfoCreator(data.customer_info),
		details: cartDetailsCreator(data.details),
		items,
		priceSpecification: cartPriceSpecificationCreator(data.price_specification),
		settings: cartSettingsCreator(data.settings),
		shipping: cartShippingCreator(data.shipping),
		status: cartStatusCreator(data.status),
		hasItemToDelete,
	});
};
