import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { cartSelectors } from "../../_core/cartSelectors";
import {updateCartComment} from "../../_core/cartActions";

export const CommentInput = () => {
  const dispatch = useDispatch();
  const comment = useSelector(cartSelectors.additionalData).originalComment;

  const handleBlur = (e) => {
    dispatch(updateCartComment({
      original_comment: e.target.value.toString()
    }));
  };

  return (
    <div className="form-group">
      <div className="form-label">комментарий к заказу</div>
      <textarea rows="3"
                style={{resize: 'vertical'}}
                className="form-input form-size-full"
                onBlur={handleBlur}
                defaultValue={comment}/>
    </div>
  );
}
