import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {InputRadioButton} from "./InputRadioButton";
import {updateCartNoContact} from "../../_core/cartActions";
import {settingSelectors} from "../../../settings/_core/settingSelectors";
import {SectionTitle} from "../../../common/sectionTitle/SectionTitle";
import {Warning} from "../../../common/notifications/warning/Warning";
import {cartSelectors} from "../../_core/cartSelectors";


export const NoContactDeliveryInput = () => {
	const dispatch = useDispatch();
	const isPickup = useSelector(cartSelectors.isPickup);
	const noContactDelivery = useSelector(cartSelectors.shipping).noContactOnDelivery;
	const settings = useSelector(settingSelectors.settings);

	const setNoContactDelivery = (value) => () => {
		dispatch(updateCartNoContact({
			no_contact: value,
		}))
	};

	if (settings.nonContactDeliveryDisabled) {
		return null
	}

	if (settings.onlineDisabled && settings.halvaDisabled) {
		return (
			<Warning className="fs-m">
				бесконтактная доставка временно недоступна из-за проблем с оплатой онлайн
			</Warning>
		);
	}

	if (isPickup) {
		return null;
	}

	return (
		<>
			<SectionTitle title="доставка"/>
			<div className="content-page">
				<ul className="form-list-radio" style={{marginBottom: '20px'}}>
					<InputRadioButton title="привезти как обычно"
														name="no-contact-delivery"
														onClick={setNoContactDelivery(0)}
														checked={!noContactDelivery}/>
					<div className="non-conteact-delivery" >
						<InputRadioButton title="доставка без контакта с курьером"
														name="no-contact-delivery"
														onClick={setNoContactDelivery(1)}
														checked={!!noContactDelivery}/>
						<div className="non-contact-delivery-description">
							бесконтактная доставка исключает необходимость контактировать с курьером и доступна только при онлайн оплате заказа с доставкой
						</div>
					</div>
				</ul>
			</div>
		</>
	)
};
