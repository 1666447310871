import React from "react";

import { ProductQuantity } from "../products/productQuantity/ProductQuantity";
import { Error } from "../common/notifications/error/Error";


const NotInStockComponent = ({ message }) => (
  <div className="cart-table-quantity">
    <Error description={message}/>
  </div>
);

const CartProductQuantityComponent = ({ cartProduct, handleRemoveItem, handleAddItem, count }) => {

  if (!cartProduct.get('isOnTrade')) {
    return ( <NotInStockComponent message="нет в наличии"/> );
  }

  if (!cartProduct.get('isSuitableForCurrentCart')) {
    return ( <NotInStockComponent message="недоступно для заказа<br/>по данному адресу"/> );
  }

  return (
    <div className="cart-table-quantity">
      <div className="cart-table-quantity__inner">
        <button className="cart-table-quantity__controls"
                data-button-type="minus"
                onClick={handleRemoveItem}
        >–
        </button>
        <strong className="cart-table-quantity__numbers">{count}</strong>
        <button className="cart-table-quantity__controls"
                data-button-type="plus"
                onClick={handleAddItem}
        >+
        </button>
      </div>
    </div>
  )
};

export const CartProductQuantity = (props) => {
  return (
    <ProductQuantity {...props} NotInStockComponent={NotInStockComponent}>
      <CartProductQuantityComponent cartProduct={props.product}/>
    </ProductQuantity>
  )
};

