import { Record } from "immutable";


const AnswerModelRecord = Record({
  active: null,
  description: null,
  id: null,
  position: 0,
  title: null,
  anchor: null
});

export default class AnswerModel extends AnswerModelRecord {
  //
}
