import React, { useState } from "react";
import { useLocation, useHistory } from "react-router";
import { Anchor } from "../common/Anchor";


export const AnswersListItem = ({ answer }) => {
    const hash = useLocation().hash;
    const history = useHistory();

    const [ open, setOpen ] = useState(hash === `#${answer.anchor}`);

    const toggleAnswer = () => {
        setOpen(!open);
        if(answer.anchor) {
            history.replace(`/information#${answer.anchor}`);
        }
    };

    return (
        <div>
            <Anchor id={answer.anchor} top={-100} />
            <h3 onClick={toggleAnswer}
                style={{ fontSize: "32px", cursor: "pointer", color: "#637c6c", margin: '20px 0', }}>
                {answer.title}
            </h3>
            {open &&
                ( <div dangerouslySetInnerHTML={{ __html: answer.description }}/> )
            }
        </div>
    )
};
