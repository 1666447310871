import { REMOVE_ADDRESS_ERROR, SET_ADDRESS_ERROR } from "./addressConstants";


const initialState = '';

export const addressErrorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ADDRESS_ERROR:
      return payload ? payload : initialState;

    case REMOVE_ADDRESS_ERROR:
      return initialState;

    default:
      return state
  }
};
