import React from 'react'
import ReactDOM from 'react-dom'

import "./styles/gdz-base.css";
import "./styles/media-classes.css";
import "./styles/button.css";
import "./styles/common.css";


const RootContainer = process.env.NODE_ENV === 'production'
  ? require('./app/system/root/core/entry/RootEntry.prod.jsx').default
  : require('./app/system/root/core/entry/RootEntry.dev.jsx').default;

const rootNode = document.getElementById('root');

ReactDOM.render(<RootContainer/>, rootNode);
