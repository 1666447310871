import { fetchApiProcess } from "../../utils/http";
import { bepaidOrderCreators } from "./bepaidOrderCreators";


export const bepaidOrderCallers = {
  fetchBepaidFailOrder: ({ token }) => fetchApiProcess([ 'bepaid', 'fail' ], bepaidOrderCreators, {
    version: 'v4.0.0',
    method: 'POST',
    body: {
      token,
    }
  }),

  fetchBepaidSuccessOrder: ({ token }) => fetchApiProcess([ 'bepaid', 'success' ], bepaidOrderCreators, {
    version: 'v4.0.0',
    method: 'POST',
    body: {
      token,
    }
  })
};
