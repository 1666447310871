export function nearestMinutes(interval, someMoment){
	const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
	return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestPastMinutes(interval, someMoment){
	const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
	return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestFutureMinutes(interval, someMoment){
	const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
	return someMoment.clone().minute(roundedMinutes).second(0);
}
