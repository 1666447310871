import { all, call, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";

import { api } from "../../../api/api";
import { orderLoaded, orderLoadedWithError } from "./orderActions";
import { featureSagaCreator } from "../../../utils/featureSagaCreator";
import { FETCH_ORDER, UPDATE_ORDER } from "./orderConstants";


function* loadOrder({payload}) {
    const {result, error} = yield call(api.order.fetchOrder, payload);
    if (error) {
        yield put(orderLoadedWithError(error))
        yield put(push('/'));
    } else {
        yield put(orderLoaded(result))
    }
}

function* updateOrder({payload}) {
    const {result, error} = yield call(api.order.updateOrder, payload);
    if (error) {
        yield put(orderLoadedWithError(error))
    } else {
        yield put(orderLoaded(result))
    }
}

export const orderSaga = featureSagaCreator({
    workers: function* () {
        yield all([
            takeEvery(FETCH_ORDER, loadOrder),
            takeEvery(UPDATE_ORDER, updateOrder),
        ])
    },
});
