import React from "react";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";


export const CartDrinkProductList = ({ drink }) => {
  return (
    <>
      {drink.valueSeq().map(drinkItem => (
        <CartListItem key={`drink_${drinkItem.get('id')}`}
                      item={drinkItem}
                      type={PRODUCT_TYPES.drink.id}
                      imageType="alt"
					/>
				))}
		</>
	)
};
