import { all, call, put, takeEvery } from "redux-saga/effects";

import { api } from "../../../api/api";
import { featureSagaCreator } from "../../../utils/featureSagaCreator";
import {blockProductsLoaded, housesLoaded, manufacturesLoaded, streetsLoaded, vacanciesLoaded} from "./catalogAction";
import { LOAD_HOUSES } from "./catalogConstants";
import { generateCalls } from "../../../utils/utils";
import { feedbackTroublesLoaded } from "../../order/feedbackForm/_core/feedackTroublesActions";
import { LOAD_FEEDBACK_TROUBLES } from "../../order/feedbackForm/_core/feedackTroublesConstants";


function* loadStreets()
{
  const { result } = yield call(api.address.streets);
  yield put(streetsLoaded(result))
}

function* loadVacancies()
{
  const { result } = yield call(api.system.vacancies);
  yield put(vacanciesLoaded(result))
}

function* loadBlockProducts()
{
    const { result } = yield call(api.system.blockProducts);
    yield put(blockProductsLoaded(result))
}

function* loadHouses({payload}) {
	const {result} = yield call(api.address.houses, payload);
	yield put(housesLoaded(result))
}

function* loadManufactures()
{
  const { result } = yield call(api.manufactures.manufactures);
  yield put(manufacturesLoaded(result))
}

function* loadFeedbackTroubles() {
    const { result } = yield call(api.feedbackTroubles.feedbackTroubles);
    yield put(feedbackTroublesLoaded(result))
}

const loaderSettings = {
  streets: true,
  manufactures: true,
  vacancies: true,
  blockProducts: true,
};

function* loader(settings = loaderSettings) {
	settings = {...loaderSettings, ...settings};
	yield all(generateCalls(
    {
      loadManufactures: settings.manufactures,
      loadStreets: settings.streets,
      loadVacancies: settings.vacancies,
      loadBlockProducts: settings.blockProducts,
    },
    {
      loadStreets,
      loadManufactures,
      loadVacancies,
      loadBlockProducts,
    },
	))
}

export const catalogSaga = featureSagaCreator({
	init: loader,
	workers: function* () {
		yield all([
			takeEvery(LOAD_HOUSES, loadHouses),
            takeEvery(LOAD_FEEDBACK_TROUBLES, loadFeedbackTroubles),
		])
	},
});

