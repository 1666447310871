import React from "react";
import { useSelector } from "react-redux";

import { popupSelector } from "../../../../common/popups/_core/popupSelectors";
import { Warning } from "../../../../common/notifications/warning/Warning";


export const PopupNotification = ({ className }) => {
  const notification = useSelector(popupSelector.props).notification;

  return (
    <Warning description={notification} className={`m-centered ${className}`}/>
  )
};
