import { CS } from "../../../utils/modules/reselect";


const shared = state => state.get('shared');

export const sharedSelectors = {
	products: CS(shared, shared => shared.get('products')),
	catalogs: CS(shared, shared => shared.get('catalogs')),
	customer: CS(shared, shared => shared.get('customer')),
	settings: CS(shared, shared => shared.get('settings')),
	preorderIntervals: CS(shared, shared => shared.get('preorderIntervals'))
};
