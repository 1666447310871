import {OrderedMap} from "immutable";

import {__, toBool} from "../../../utils/utils";
import DrinkModel from "../../../models/Products/Drink/DrinkModel";


export const drinkCreator = data => new DrinkModel({
	announce: __(data.announce),
	ageLimit: __(data.age_limit),
	id: __(data.id),
	position: __(data.position),
	price: __(data.price) / 10000,
	publicImages: data.public_images,
	title: __(data.title),

	isOnTrade: toBool(data.is_on_trade),
	isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
	producerCountry: __(data.producer_country),
});

export const drinksProcess = (data) => {
	return (new OrderedMap()).withMutations(map => {
		Object.entries(data).forEach(([id, item]) => {
			map.set(id, drinkCreator(item))
		})
	})
};
