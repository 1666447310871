import React from 'react';
import { useSelector } from "react-redux";
import promoCookieBanner from '../../../../assets/images/ban_promo_ryb.jpg'
import css from "./promoCookieBanner.css";

export const PromoCookieBanner = () => {
    return (
        <div className="promo-cookie-banner-container">
            <img src={promoCookieBanner} alt='promo cookie info' className="promo-cookie-banner-img"/>
        </div>
    )
}
