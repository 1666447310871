import { systemCallers } from "./system/systemCallers"
import { manufactureCallers } from "./manufactures/manufactureCallers"
import { addressCallers } from "./address/addressCallers"
import { productCallers } from "./products/productCallers"
import { cartCallers } from "./cart/cartCallers";
import { customerCallers } from "./customer/customerCallers";
import { orderCallers } from "./order/orderCallers";
import { bypassCallers } from "./bypassOnly/bypassCallers";
import { bepaidOrderCallers } from "./bepaidOrder/bepaidOrderCallers";
import { resolveCallers } from "./resolveIp/resolveCallers";
import { preorderCallers } from "./preorderIntervals/preorderCallers";
import { feedbackTroublesCallers } from "./feedbackTroubles/feedbackTroublesCallers";


export const api = {
  address: addressCallers,
  system: systemCallers,
  products: productCallers,
  manufactures: manufactureCallers,
  cart: cartCallers,
  customer: customerCallers,
  order: orderCallers,
  bypass: bypassCallers,
  bepaid: bepaidOrderCallers,
  resolve: resolveCallers,
  preorder: preorderCallers,
  feedbackTroubles: feedbackTroublesCallers,
};
