import { fromJS } from "immutable";

import { CONTENT_LOADED, PAYMENTS_LOADED, SETTING_LOADED, SLIDES_LOADED } from "./settingConstants";


const initialState = fromJS({});

export const settingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETTING_LOADED:
      return state.set('settings', payload);

    case SLIDES_LOADED:
      return state.set('slides', payload);

    case CONTENT_LOADED:
      return state.set('content', payload);

    case PAYMENTS_LOADED:
      return state.set('payments', payload);

		default:
			return state
	}
};
