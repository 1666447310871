import Immutable from "immutable";

import { CartItemMapModel } from "../Cart/CartItemsMapModel";


const OrderModelRecord = Immutable.Record({
	num: null,
	sync: null,
	hash: null,
	customerInfo: {
		name: null,
		phone: null,
	},
	items: new CartItemMapModel(),

	shipping: {
		noContactOnDelivery: null,
		shippingType: null,
		address: {
			entrance: null,
			flat: null,
			floor: null,
			houseId: null,
			houseTitle: null,
			intercom: null,
			streetId: null,
			streetTitle: null,
			toEntrance: null
		},
		timeDetails: {
			date: null,
			delay: null,
			isDelayIncreased: null,
			time: null,
			timeType: null,
			deliveryAt: null,
		}
	},

	priceSpecification: {
		deliveryCost: null,
		discountAmount: null,
		discountState: null,
		discountedSubtotal: null,
		paymentType: null,
		prepareChangeFor: null,
		rawSubtotal: null,
		total: null
	},

	additionalData: {
		originalComment: null,
		bepaidRedirectUrl: null,
		confirmedByOperator: null,
	},

	feedback: {
		comment: null,
		conclusion: null, //“ok” / “troubles” / null,
		troubles: null, //[“troubleA”, “troubleB”]
	}
});


export class OrderModel extends OrderModelRecord {
	//
}
