import React from "react";
import { useSelector } from "react-redux";

import { productSelectors } from "../_core/productSelectors";
import { Anchor } from "../../common/Anchor";
import { DrinkList } from "./DrinkList";
import { SectionTitle } from "../../common/sectionTitle/SectionTitle";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import {catalogSelectors} from "../../catalogs/_core/catalogSelectors";
import {SectionDescription} from "../../common/sectionDescription/SectionDescription";


export const DrinkSection = () => {
  const drinkList = useSelector(productSelectors[PRODUCT_TYPES.drink.id]);
    let sectionDescription = useSelector(catalogSelectors.drinkSectionDescription)

  return (
    <div className="additional-goods">
      <Anchor id={PRODUCT_TYPES.drink.id} top={-120}/>
      <SectionTitle title="напитки"/>
        {sectionDescription && sectionDescription.description && <SectionDescription description={sectionDescription.description}/>}
      <DrinkList productList={drinkList}/>
    </div>
  )
}
