import { OrderedMap } from "immutable";

import { __, toBool } from "../../../utils/utils";
import { PRODUCT_TYPES } from "../productBaseConstants";
import { WarmerModel } from "../../../models/Products/Warmer/WarmerModel";


export const warmerCreator = data => new WarmerModel({
    announce: __(data.announce),
    id: __(data.id),

    isOnTrade: toBool(data.is_on_trade),
    isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),


    position: __(data.position),
    price: __(data.price) / 10000,
    title: __(data.title),
    subtype: __(data.warmer_type)
});

const createWarmerSubtypeFilter = (data, subtype, creator) => OrderedMap().withMutations(map => {
    Object.entries(data)
        .filter(([id, item]) => item.warmer_type === subtype)
        .forEach(([id, item]) => {
            map.set(id, creator(item))
        })
});

export const warmersProcess = (data) => {
    return {
        [PRODUCT_TYPES.warmer.subtypes.other.id]: createWarmerSubtypeFilter(data, PRODUCT_TYPES.warmer.subtypes.other.id, warmerCreator),
        [PRODUCT_TYPES.warmer.subtypes.tea.id]: createWarmerSubtypeFilter(data, PRODUCT_TYPES.warmer.subtypes.tea.id, warmerCreator),
        [PRODUCT_TYPES.warmer.subtypes.coffee.id]: createWarmerSubtypeFilter(data, PRODUCT_TYPES.warmer.subtypes.coffee.id, warmerCreator),
    }
};
