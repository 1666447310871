import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { cartSelectors } from "../../cart/_core/cartSelectors";
import { PokePriceInfo } from "./PokePriceInfo";
import { PokeProductQuantity } from "./PokeProductQuantity";
import { WidthRatioImageContainer } from "../../common/ratioContainers/WidthRatioImageContainer";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import { titleFormatter } from "../../../utils/titleFormatter";


export const PokeListItem = ({ poke }) => {
  const history = useHistory();
  const open = () => history.push(`/poke-detail/${poke.id}`);

  const cartItem = useSelector(state => cartSelectors.cartItem(state, poke));
  const productInCart = cartItem.count ? 'incart' : 'none';

  return (
    <li className="main-goods__list-item" data-item={productInCart}>
      <div className="main-goods__item">
        <h3 className="main-goods__title">
          <div>
            <span>{titleFormatter(poke.title)}</span>
          </div>
        </h3>

        <ul className="main-goods-types">
          <PokePriceInfo type="big" productType={poke}/>
        </ul>

        <WidthRatioImageContainer
          ratio={1}
          publicImages={poke.publicImages}
          type="main"
          onClick={open}
          className="main-goods__picture"/>

        <PokeProductQuantity product={poke} type={PRODUCT_TYPES.poke.id}/>

        <article className="main-goods__chars">
          <p>{poke.get('announce')}</p>
        </article>
      </div>
    </li>
  );
};
