import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { fromEn, prepareText } from "../../catalogs/_core/util";
import { fetchHouses } from "../../catalogs/_core/catalogAction";
import { catalogSelectors } from "../../catalogs/_core/catalogSelectors";
import { cartSelectors } from "../../cart/_core/cartSelectors";


export const AddressStreetSelectInput = ({
  styles, onMenuOpen, onMenuClose, onChange = () => {
  }
}) => {
  const dispatch = useDispatch();
  const streets = useSelector(catalogSelectors.streets);
  const address = useSelector(cartSelectors.address);

  const [ optionValue, setOptionValue ] = useState({
    value: address.streetId,
    label: address.street,
  });
  const [ state, setState ] = useState({
    options: [],
  });

  useEffect(() => {
    if (address.streetId) {
      dispatch(fetchHouses(address.streetId));
      setOptionValue({
        value: address.streetId,
        label: address.street,
      });
    }
  }, [ address.streetId ]);

  const handleSelectInputChange = (input, { action }) => {
    const localedInput = prepareText(fromEn(input)).toLowerCase();

    if (action === "input-change" && input.length > 1) {
      setState({
        options: streets
          .filter(i => i.label.toLowerCase().includes(localedInput))
          .sortBy(i => i.label)
          .valueSeq()
          .toArray(),
      });

    }
    else {
      setState({
        options: [],
      });
    }
  };

  const handleStreetChange = (newValue, action) => {
    onChange(newValue);
    setOptionValue({
      value: newValue.value,
      label: newValue.label
    });
    dispatch(fetchHouses(newValue.value));
  };

  const noOptionMessage = ({ inputValue }) => {
    if (inputValue.length < 2) {
      return `пожалуйста, введите еще хотя бы ${2 - inputValue.length} символ${inputValue.length ? '' : 'a'}`
    }
    else {
      return `совпадений не найдено`
    }
  };

  return (
    <Select options={state.options}
            escapeClearsValue
            styles={styles}
            value={optionValue}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onChange={handleStreetChange}
            filterOption={() => true}
            onInputChange={handleSelectInputChange}
            placeholder=" "
            noOptionsMessage={noOptionMessage}/>
  )
};
