import React from "react";

import { DessertListItem } from "./DessertListItem";
import { sortByPosition } from "../../../utils/utils";


export const DessertList = ({ productList, freeCookieCount }) => {

  return (
      <ul className="secondary-goods__list">
        {productList.toList().sort(sortByPosition).map(dessert => (
            <DessertListItem dessert={dessert} key={dessert.get('id')} freeCookieCount={freeCookieCount}/>
        ))}
      </ul>
  );
}
