import {Record} from "immutable"

import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";


const RollInfoRecord = Record({
	amount: null,
	calories: null,
	carbohydrates: null,
	fats: null,
	price: 0,
	proteins: null,
	priceAvoided: 0
});

class RollInfo extends RollInfoRecord {
	//
}

const RollModelRecord = Record({
	active: null,
	announce: null,
	code: null,
	id: null,
	position: null,
	publicImages: {},
	title: null,

	isOnTrade: null,
	isSuitableForCurrentCart: null,
	hasMedium: null,

	big: new RollInfo(),
	medium: new RollInfo(),

	type: PRODUCT_TYPES.roll.id,
});

export default class RollModel extends RollModelRecord {
	//
}
