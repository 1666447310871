import { OrderedMap } from "immutable";

import { __ } from "../../utils/utils";
import BlockProductModel from "../../models/BlockProduct/BlockProductModel";


export const blockProductModelCreator = data => new BlockProductModel({
  active: __(data.active),
  description: __(data.description),
  id: __(data.id),
  systemName  : __(data.system_name),
});

export const blockProductMapProcess = data => OrderedMap().withMutations(blockProductMap => {
  Object.values(data).forEach(blockProduct => blockProductMap.set(__(blockProduct.id), blockProductModelCreator(blockProduct)))
});
