import { __, toBool } from "../../utils/utils";
import { CartItemModel } from "../../models/Cart/CartItemModel";
import { CartItemMapModel } from "../../models/Cart/CartItemsMapModel";


export const cartItemCreator = (data) => {
  return new CartItemModel({
    id: __(data.product_id),
    code: __(data.code),

    title: __(data.title),
    type: __(data.product_type),
    subtype: __(data.product_subtype),
    announce: __(data.announce),
    size: __(data.item_size),
    ageLimit: __(data.age_limit),

    isOnTrade: toBool(data.is_on_trade), //это можно купить (т.е. сейчас гипотетически может участвовать в торговых отношениях)
    isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart), //это разрешено купить с указанными параметрами

    count: __(data.item_size) === 'medium' ? 0.5 : 1,
    discountedPrice: __(data.price) / 10000,
    productPrice: __(data.product_price) / 10000,
    priceWithFiasco: __(data.price_with_fiasco) / 10000,
    specialPrice: __(data.special_price) / 10000,
    discountAmount: __(data.discount_amount) / 10000,
    discountPercentSuggested: __(data.discount_percent_suggested) / 10000,
    realizationPrice: __(data.realization_price) / 10000,
    fiascoCandidate: toBool(data.fiasco_candidate),
    moneyAmountSavedIfFree: __(data.money_amount_saved_if_free) / 10000
  });
}

export const cartItemMapCreator = data => {
  const cartItemMap = new CartItemMapModel();
  let cartHasItemToDelete = false;

  const items = cartItemMap.withMutations(map => {
    Object.values(data).forEach(item => {
      if (!item.is_suitable_for_current_cart || !item.is_on_trade) {
        cartHasItemToDelete = true;
      }
      const type = item.product_subtype || item.product_type;

      const itemKey = item.product_id;
      const cartProductTypeMap = map.get(type);
      const cartProduct = cartProductTypeMap.get(itemKey);

      if (cartProduct) {
        map.set(type, cartProductTypeMap.set(itemKey, cartProduct.addItem(cartItemCreator(item))));
      } else {
        map.set(type, cartProductTypeMap.set(itemKey, cartItemCreator(item)));
      }
    })
  });

  return [items, cartHasItemToDelete];
};
