import { Record } from "immutable";
import { ManufactureWorkTimeInfoModel } from "./ManufactureWorkTimeInfoModel";


const ManufactureResolveResult = Record({
  id: null,
	isPickupSlowed: null,
	warning: null,
	error: null,
  workTimeInfo: new ManufactureWorkTimeInfoModel(),
});

export class ManufactureResolveResultModel extends ManufactureResolveResult {
  //
}
