import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cartSelectors } from "../../_core/cartSelectors";
import { addressFormModified } from "../../../../pages/_core/cartPage/cartPageActions";
import { cartPageSelectors } from "../../../../pages/_core/cartPage/cartPageSelectors";
import { updateCartClientPhone } from "../../_core/cartActions";


// todo Add possibilities to save prefix. Problem: length of prefix could be not only 4 (+375), but 2 e.g. (+7)
export const PhoneInput = ({}) => {
  const dispatch = useDispatch();
  const phone = useSelector(cartSelectors.customerInfo).phone;
  const isAddressFormModified = useSelector(cartPageSelectors.addressFormModified);
  const [state, setState] = useState({
    phone: phone.slice(4),
    prefix: phone.slice(0, 4) || "+375",
  });

  useEffect(() => {
    setState({
      phone: phone.slice(4),
      prefix: phone.slice(0, 4) || "+375",
    })
  }, [phone])

  const handlePhoneInputChange = (e) => {
    if (!isAddressFormModified) {
      dispatch(addressFormModified());
    }
    setState({
      phone: e.target.value.match(/\d+/) ? (e.target.value.match(/\d+/)[0]).substring(0, 9) : "",
    })
  };

  // handlePrefixInputChange = (e) => {
  // 	this.props.modifyAddressForm();
  // 	this.setState({
  // 		prefix: e.target.value,
  // 	})
  // }

  const handlePhoneBlur = (e) => {
    dispatch(updateCartClientPhone({
      phone: `+375${e.target.value.trim()}`
    }));
  };

  // handlePrefixBlur = (e) => {
  // 	this.props.cartUpdating({
  // 		phone: `${e.target.value}${this.state.phone}`
  // 	})
  // };

  return (
    <div className="form-group form-required">
      <div className="form-size-normal">
        <div className="form-label form-label--flex">
          <strong className="form-label__value">ваш мобильный телефон</strong>
          <span className="form-label__note">например: +375 296040000</span>
        </div>
        <div className="form-grid form-grid--sps-normal">
          <div className="form-grid__cell form-grid__cell--phone-prefix">
            <input
              disabled
              className="form-input form-size-full form-input-big"
              type="text"
              value="+375"/>
          </div>
          <div className="form-grid__cell form-grid__cell--full">
            <input
              onBlur={handlePhoneBlur}
              onChange={handlePhoneInputChange}
              value={state.phone}
              className="form-input form-size-full form-input-big"
              type="text"/>
          </div>
        </div>
      </div>
    </div>
  );
}

