import { all, call, put } from "redux-saga/effects";

import { generateCalls } from "../../../utils/utils";
import { api } from "../../../api/api";
import { featureSagaCreator } from "../../../utils/featureSagaCreator";
import { loadedAnswers, loadedContent, loadedPayments, loadedSettings, loadedSlides } from "./settingsActions";


function* loadSetting()
{
  const { result } = yield call(api.system.settings);
  // todo use all date from settings request (only delivery45 for now)
  yield put(loadedSettings(result))
}

function* loadSlides()
{
  const { result } = yield call(api.system.slides);
  yield put(loadedSlides(result))
}

function* loadContent()
{
  const { result } = yield call(api.system.content);
  yield put(loadedContent(result))
}

function* loadPayments()
{
  const {result, error} = yield call(api.system.payments);
  if (error) {
    console.error('loadPayments - ', error);
  } else {
    yield put(loadedPayments(result))
  }
}

function* loadAnswers()
{
  const {result, error} = yield call(api.system.answers);
  if (error) {
    console.error('loadAnswers - ', error);
  } else {
    yield put(loadedAnswers(result))
  }
}

const loaderSettings = {
  setting: true,
  slides: true,
  content: true,
  payments: true,
  answers: true,
};

function* loader(settings = loaderSettings)
{
  settings = { ...loaderSettings, ...settings };
  yield all(generateCalls(
    {
      loadSetting: settings.setting,
      loadSlides: settings.slides,
      loadContent: settings.content,
      loadPayments: settings.payments,
      loadAnswers: settings.answers,
    },
    {
      loadSetting,
      loadSlides,
      loadContent,
      loadPayments,
      loadAnswers
    },
  ))
}

export const settingSaga = featureSagaCreator({
  init: loader,
});
