import {CS} from "../../../utils/modules/reselect";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";

import {productSelectors} from "./productSelectors"

export const suggestedSelectors = {
    [PRODUCT_TYPES.suggestedProduct.id]: CS([
        productSelectors.suggestedProduct, productSelectors.roll, productSelectors.rollset, productSelectors.poke,
        productSelectors.soup, productSelectors.drink, productSelectors.dessert, productSelectors.garnish
    ], (links, roll, rollset, poke, soup, drink, dessert, garnish) => {

        return links.map(link => {

            let product = null
            switch (link.productType) {
                case PRODUCT_TYPES.roll.id:
                    product = roll.get(link.productId)
                    break;
                case PRODUCT_TYPES.rollset.id:
                    product = rollset.get(link.productId)
                    break;
                case PRODUCT_TYPES.poke.id:
                    if (poke.light.get(link.productId)) {
                        product = poke.light.get(link.productId)
                    } else  if (poke.sashimi.get(link.productId)) {
                        product = poke.sashimi.get(link.productId)
                    } else  if (poke.standard.get(link.productId)) {
                        product = poke.standard.get(link.productId)
                    }
                    break;
                case PRODUCT_TYPES.soup.id:
                    product = soup.get(link.productId)
                    break;
                case PRODUCT_TYPES.drink.id:
                    product = drink.get(link.productId)
                    break;
                case PRODUCT_TYPES.dessert.id:
                    if (dessert.cake.get(link.productId)) {
                        product = dessert.cake.get(link.productId)
                    } else  if (dessert.cookie.get(link.productId)) {
                        product = dessert.cookie.get(link.productId)
                    }
                    break;
                case PRODUCT_TYPES.garnish.id:
                    product = garnish.get(link.productId)
                    break;

            }
            if (product) {

                return Object.assign(link, {product})
            }

            return null;
        }).filter(item => item)
    }),

};
