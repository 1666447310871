import {fetchApiProcess} from "../../utils/http";
import {cartCreator} from "./cartCreators";
import { orderCreator } from "../order/orderCreators";


const createCartPatchRequest = (urlArray) => (payload) => fetchApiProcess(urlArray, cartCreator, {
	method: 'PATCH',
	version: 'v4.0.0',
	body: payload
})

export const cartCallers = {
	fetchCart: () => fetchApiProcess('cart', cartCreator, {
		version: 'v4.0.0',
	}),

	applyPickup: (id) => fetchApiProcess(['cart', 'address', 'apply-pickup'], cartCreator, {
		method: 'POST',
		version: 'v4.0.0',
		body: {
			manufacture_id: id
		}
	}),

	applyClientAddress: (addressId) => fetchApiProcess(['cart', 'address', 'apply-client'], cartCreator, {
		method: 'POST',
		version: 'v4.0.0',
		body: {
			address_id: addressId
		}
	}),

	setAddress: (payload) => fetchApiProcess(['cart', 'address', 'apply-address'], cartCreator, {
		method: 'POST',
		version: 'v4.0.0',
		body: payload
	}),

	changeAddress: createCartPatchRequest(['cart', 'change-address']),
	changeFlat: createCartPatchRequest(['cart', 'flat']),
	changeFloor: createCartPatchRequest(['cart', 'floor']),
	changeEntrance: createCartPatchRequest(['cart', 'entrance']),
	changeIntercom: createCartPatchRequest(['cart', 'intercom']),
	changeClientName: createCartPatchRequest(['cart', 'client', 'name']),
	changeClientPhone: createCartPatchRequest(['cart', 'client', 'phone']),
	changeNoContact: createCartPatchRequest(['cart', 'no-contact']),
	changePickupDelay: createCartPatchRequest(['cart', 'pickup-delay']),
	changePreorder: createCartPatchRequest(['cart', 'preorder']),
	changePayment: createCartPatchRequest(['cart', 'payment']),
	changeConfirmedPrivacyPolicyRules: createCartPatchRequest(['cart', 'client', 'privacy-policy', 'rules']),
	changeConfirmPrivacyPolicyOrderData: createCartPatchRequest(['cart', 'client', 'privacy-policy', 'order-data']),
	changeConfirmPrivacyPolicySmsProcessOrder: createCartPatchRequest(['cart', 'client', 'privacy-policy', 'sms-process-order']),
	changeConfirmPrivacyPolicyFeedbackService: createCartPatchRequest(['cart', 'client', 'privacy-policy', 'feedback-service']),

	changePrepareCharge: createCartPatchRequest(['cart', 'prepare-change-for']),
	changeComment: createCartPatchRequest(['cart', 'comment']),

	addItemToCart: (payload) => fetchApiProcess(['cart', 'items'], cartCreator, {
		method: 'PUT',
		version: 'v4.0.0',
		body: payload,
	}),

	removeItemFromCart: (payload) => fetchApiProcess(['cart', 'items'], cartCreator, {
		method: 'DELETE',
		version: 'v4.0.0',
		body: payload,
	}),

	removeAllItemFromCart: (payload) => fetchApiProcess(['cart', 'items', 'same'], cartCreator, {
		method: 'DELETE',
		version: 'v4.0.0',
		body: payload,
	}),

	validateCart: () => fetchApiProcess(['cart', 'validate'], cartCreator, {
		version: 'v4.0.0',
	}),

	submitOrder: () => fetchApiProcess(['cart', 'save'], orderCreator, {
		method: 'POST',
		version: 'v4.0.0',
		body: {
			with_result: 1,
		}
	}),
};
