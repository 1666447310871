import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from "react-router";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { rootSelectors } from "../system/root/core/rootSelectors";
import { CartPage } from "./CartPage";
import { DeliveryZonePage } from "./DeliveryZonePage";
import { PickupPage } from "./PickupPage";
import { Footer } from "../components/common/footer/Footer";
import { Header } from "../components/common/header/Header";
import { MainPage } from "./MainPage";
import { OrderPage } from "./OrderPage";
import { PageLoader } from "../components/common/pageLoader/PageLoader";
import { PaymentFailurePage } from "./PaymentFailurePage";
import { PaymentSuccessPage } from "./PaymentSuccessPage";
import { PaymentsPage } from "./PaymentsPage";
import { ServiceRulesPage } from "./ServiceRulesPage";
import { VacanciesPage } from "./VacanciesPage";
import { ROUTES } from "../system/router/routeConstants";
import { StopInformer } from "../components/common/stopInformer/StopInformer";
import { AnswersPage } from "./AnswersPage";
import { scrollToAnchor } from "../utils/scrollToAnchor";
import { closePopup, openPopup } from "../components/common/popups/_core/popupActions";
import { POPUP_COMPONENTS } from "../components/common/popups/popupsConfig";
import { pageSelectors } from "./_core/pagesSelectors";

import "./router.css";
import {PrivatPolicyPage} from "./PrivatPolicyPage";
import { PrivatPolicyPopup } from "../components/common/notifications/privatPolicyPopup/PrivatPolicyPopup";
import {AudioAndVideoPolicyPage} from "./AudioAndVideoPolicyPage";


export function AppRouter() {
  const dispatch = useDispatch();
  const history = useHistory();
  const popup = useSelector(pageSelectors.popup)

  const loading = useSelector(rootSelectors.loading);
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      document.location.reload();
    }, 2*60*60*1000)
  }, [])

  useEffect(() => {
    scrollToAnchor(hash)
  }, [ pathname, hash, key ]);

  useEffect(() => {
    if (history.location.pathname.includes('shipping-settings')) {
      dispatch(openPopup({
        type: POPUP_COMPONENTS.addressPopup.type,
      }))
    } else {
      if (popup.type === POPUP_COMPONENTS.addressPopup.type) {
        dispatch(closePopup())
      }
    }
  }, [history.location.pathname]);

  return (
    <div className="page-container">
      {loading ? <PageLoader/> :
        <>
          <Header/>
          <StopInformer />

          <div className="page-wrapper">
            <section className="body-section">
              <Switch>
                <Route exact path={ROUTES.main.path} component={MainPage}/>
                <Route exact path={ROUTES.popups.rollset} component={MainPage}/>
                <Route exact path={ROUTES.popups.roll} component={MainPage}/>
                <Route exact path={ROUTES.popups.poke} component={MainPage}/>
                <Route exact path={ROUTES.popups.garnish} component={MainPage}/>
                <Route exact path={ROUTES.popups.sauce} component={MainPage}/>
                <Route exact path={ROUTES.popups.soup} component={MainPage}/>
                <Route exact path={ROUTES.popups.dessert} component={MainPage}/>
                <Route exact path={ROUTES.popups.shipping} component={MainPage}/>

                <Route exact path={ROUTES.cart.path} component={CartPage}/>
                <Route exact path={ROUTES.vacancies.path} component={VacanciesPage}/>
                <Route exact path={ROUTES.rules.path} component={ServiceRulesPage}/>
                <Route exact path={ROUTES.information.path} component={AnswersPage}/>
                <Route exact path={ROUTES.deliveryZone.path} component={DeliveryZonePage}/>
                <Route exact path={ROUTES.pickup.path} component={PickupPage}/>
                <Route exact path={ROUTES.privatPolicy.path} component={PrivatPolicyPage}/>
                <Route exact path={ROUTES.audioAndVideoPolicy.path} component={AudioAndVideoPolicyPage}/>
                <Route exact path={ROUTES.payments.path} component={PaymentsPage}/>
                <Route exact path={ROUTES.order.path} component={OrderPage}/>

                <Route exact path={ROUTES.paymentSuccessResult.path} component={PaymentSuccessPage}/>
                <Route exact path={ROUTES.paymentFailureResult.path} component={PaymentFailurePage}/>

                <Route path="*">
                  <Redirect
                    to={{
                      pathname: "/",
                      hash: ""
                    }}
                  />
                </Route>
              </Switch>
            </section>


            <Footer/>
          </div>
          {!localStorage.getItem('privat_policy') && <PrivatPolicyPopup/>}
        </>
      }
    </div>
  )
}
