import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { closePopup } from "../../../common/popups/_core/popupActions";
import { productSelectors } from "../../_core/productSelectors";
import { popupSelector } from "../../../common/popups/_core/popupSelectors";
import { CircleBackground } from "../../../common/circleBackground/CircleBackground";
import { Image } from "../../../common/image/Image";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";
import {SoupPriceInfo} from "../SoupPriceInfo";
import {SoupProductQuantity} from "../SoupProductQuantity";
import { ROUTES } from "../../../../system/router/routeConstants";


export const SoupPopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const soupId = useSelector(popupSelector.props).soupId;
  const soup = useSelector(productSelectors[PRODUCT_TYPES.soup.id]).get(soupId);

  const handleClick = () => {
    history.push(ROUTES.main.path);
    dispatch(closePopup());
  };

  return (
    <div className="popup popup--product popup--show">

      <button
        onClick={handleClick}
        className="popup__close"
        type="button"
        title="закрыть">&times;
      </button>

      <div className="popup-outer">
        <div className="popup-product">
          <h1 className="popup-product__title">{soup.title.toLowerCase()}</h1>

          <ul className="popup-product-types">
            <SoupPriceInfo type="big" noAmount productType={soup}/>
          </ul>

          <Image className="popup-product__picture"
                 publicImages={soup.publicImages}
                 type="popup"
                 height={600} width={600}/>

          <SoupProductQuantity product={soup} type={PRODUCT_TYPES.soup.id}/>

          <article className="popup-product__chars">
            <p>{soup.get('announce')}</p>
          </article>
          <ul className="popup-product-ingredients">
            <li className="popup-product-ingredients__list-item">вес {soup.info.amount}</li>
            <li className="popup-product-ingredients__list-item">калории {soup.info.calories} ккал</li>
            <li className="popup-product-ingredients__list-item">углеводы {soup.info.carbohydrates} г</li>
            <li className="popup-product-ingredients__list-item">белки {soup.info.proteins} г</li>
            <li className="popup-product-ingredients__list-item">жиры {soup.info.fats} г</li>
          </ul>
        </div>
      </div>
      <CircleBackground className="hide-on-mobile"/>
    </div>
  );
};
