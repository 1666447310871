import React from "react";

import { SoupListItem } from "./SoupListItem";
import { sortByPosition } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { productSelectors } from "../_core/productSelectors";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";


export const SoupList = () => {
  const soups = useSelector(productSelectors[PRODUCT_TYPES.soup.id]);

  return (
    <ul className="main-goods__list">
      {soups.toList().sort(sortByPosition).map(product => (
        <SoupListItem soup={product} key={product.get('id')}/>
      ))}
    </ul>
  )
};
