import { Record } from "immutable";


const ManufactureModelRecord = Record({
	active: null,
	houseId: null,
	house: null,
	isPickupSlowed: null,
	id: null,
	onStopAt: null,
	streetId: null,
	street: null,
	type: null,
	withPickup: null,
	workingTimeInfo: null,
	status: {}
});

export class ManufactureModel extends ManufactureModelRecord {
  //
}
