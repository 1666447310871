import Immutable from "immutable";


const StreetModelRecord = Immutable.Record({
  value: undefined,
  label: undefined,
});

export class StreetModel extends StreetModelRecord {
  //
}
