import React from "react";

import {priceBYN} from "../../../utils/formatHelpers";


export const RollsetPriceInfo = ({productType, noAmount}) => {
    const renderPriceAvoided = () => {
        return productType.info.price !== productType.info.priceAvoided && productType.info.price > 0
            ? <div className="main-goods__price-avoided">{priceBYN(productType.info.priceAvoided)}</div>
            : null;
    }
    return (
        <li className="main-goods-types__list-item">
            <div className="main-goods__price-box">
                {renderPriceAvoided()}
                <strong className="main-goods__price">{priceBYN(productType.info.price)}</strong>
                {noAmount ? null : <span className="main-goods__portion">{productType.info.rollsCount * 8} шт</span>}
            </div>
        </li>
    )
};
