import { all, call, fork, put } from "redux-saga/effects";
import * as Sentry from '@sentry/react';

import { rootActions } from "./rootActions";
import { api } from "../../../api/api";
import { isBypassEnabled } from "../../../utils/http";
import { bepaidPageSaga } from "../../../pages/_core/bepaidPage/bepaidPageSaga";
import { cartPageSaga } from "../../../pages/_core/cartPage/cartPageSaga";
import { cartSaga } from "../../../components/cart/_core/cartSagas";
import { catalogSaga } from "../../../components/catalogs/_core/catalogSaga";
import { customerSaga } from "../../../components/customer/_core/customerSaga";
import { manufactureSaga } from "../../../components/currentManufacture/_core/manufactureSaga";
import { orderSaga } from "../../../components/order/_core/orderSaga";
import { productSaga } from "../../../components/products/_core/productSaga";
import { settingSaga } from "../../../components/settings/_core/settingSaga";
import { enabledSentry } from "../../sentry";
import { preorderIntervalSaga } from "../../../components/preorder/_core/preorderSaga";


function* byPassSaga()
{
  yield put(rootActions.bypassParamsRequested());
  const { error } = yield call(api.bypass.byPassRequest);

  if (error) {
    yield put(rootActions.bypassParamsLoadedWithError())
  }
  else {
    yield put(rootActions.bypassParamsLoaded())
  }
}

function* resolveSaga()
{
	if (!enabledSentry) return;

  const {clientIp} = yield call(api.resolve.resolveIp);

  if (!clientIp) {
		Sentry.captureException(new Error("Client IP has failed to resolve"));
	} else {
		Sentry.setUser({
			ip_address: clientIp
		});
	}
}

function* workers()
{
  yield all([
    fork(catalogSaga.workers),
    fork(cartSaga.workers),
    fork(manufactureSaga.workers),
    fork(customerSaga.workers),
    fork(orderSaga.workers),
    fork(bepaidPageSaga.workers),
    fork(cartPageSaga.workers),
    fork(preorderIntervalSaga.workers),
  ])
}

function* init()
{
  yield all([
    call(settingSaga.init),
    call(productSaga.init),
    call(catalogSaga.init),
    call(cartSaga.init),
    call(preorderIntervalSaga.init),
    call(customerSaga.init),
  ]);
}

export function* rootSaga()
{
  yield put(rootActions.loading('загрузка...'));

  if (isBypassEnabled) {
    yield* byPassSaga();
  }
  yield* resolveSaga();

  yield* workers();
  yield* init();

  yield put(rootActions.loading(false));
}
