import { fetchApi } from "../../utils/http";


export const bypassCallers = {
		byPassRequest: () => fetchApi([ 'bypass' ], {
			params: {
				manufacture_type: 'sushi'
			},
			version: 'v4.0.0'
		}),
};
