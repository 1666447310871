import moment from "moment";

export const getAvailablePreorderDates = (preorderIntervals) => {
    return preorderIntervals?.data ?
        Object.keys(preorderIntervals?.data).filter(i => parseInt(i)).map(i => moment(i, "YYYY-MM-DD"))
        : []
}

export const getMinDate = (preorderIntervals) => {
    if (!preorderIntervals) return null;
    const availablePreorderDate = getAvailableDay(preorderIntervals);
    return new Date(moment(availablePreorderDate).format());
}

export const getMaxDate = (preorderIntervals) => {
    if (!preorderIntervals) return null;
    const availablePreorderDates = getAvailablePreorderDates(preorderIntervals);
    return new Date(moment.max(availablePreorderDates).format());
}

const getAvailableDay = (preorderIntervals, dateString) => {
    for (const day in preorderIntervals.data) {
        if (!['disabledDeliveryDays', 'disabledPickupDays', 'protected_at', 'ttl'].includes(day)) {
            for (const hours in preorderIntervals.data[day]) {
                for (const minutes in preorderIntervals.data[day][hours]) {
                    if (preorderIntervals.data[day][hours][minutes].delivery) {
                        return day
                    }
                }
            }
        }
    }

    return dateString
}

export const getFirstAvailableDayTime = (preorderIntervals) => {
    for (const day in preorderIntervals?.data) {
        if (!['disabledDeliveryDays', 'disabledPickupDays', 'protected_at', 'ttl'].includes(day)) {
            for (const hours in preorderIntervals?.data[day]) {
                for (const minutes in preorderIntervals?.data[day][hours]) {
                    if (preorderIntervals?.data?.[day][hours][minutes].delivery) {
                        return new Date((day + ' ' + hours + ':' + minutes))
                    }
                }
            }
        }
    }

    return false
}

const getAvailableHoursForTheDay = (preorderIntervals, dateString) => {
    return Object.keys(preorderIntervals?.data?.[dateString] || {}).filter(a => {
        for (const minutes in preorderIntervals?.data?.[dateString][a]) {
            if (preorderIntervals?.data?.[dateString][a][minutes].delivery) {
                return true
            }
        }

        return false
    }).map(a => +a)
}

const getAvailableMinutesForTheHour = (preorderIntervals, dateString, minHourForADay) => {
    return Object.keys(preorderIntervals.data[dateString]?.[minHourForADay] || {}).map(a => +a)
}

export const getMinTimeOfDay = (dayTimeString, preorderIntervals) => {
    if (!preorderIntervals) return new Date(moment(dayTimeString).set('hour', 0).set('minutes', 0).format());

    const dateString = moment(dayTimeString).format("YYYY-MM-DD")
    const availableHours = getAvailableHoursForTheDay(preorderIntervals, dateString);
    const minHourForADay = Math.min(...availableHours).toString().padStart(2, "0");
    const availableMinutes = getAvailableMinutesForTheHour(preorderIntervals, dateString, minHourForADay);
    const minMinuteOfTheHour = Math.min(...availableMinutes);

    return new Date(moment(dayTimeString)
        .set('hour', +minHourForADay)
        .set('minutes', minMinuteOfTheHour)
        .format()
    );
}

export const getMaxTimeOfDay = (dayTimeString, preorderIntervals) => {
    if (!preorderIntervals) return new Date(moment(dayTimeString).set('hour', 23).set('minutes', 59).format());

    const dateString = moment(dayTimeString).format("YYYY-MM-DD")
    const availableHours = getAvailableHoursForTheDay(preorderIntervals, dateString);
    const maxHourForADay = Math.max(...availableHours).toString().padStart(2, "0");
    const availableMinutes = getAvailableMinutesForTheHour(preorderIntervals, dateString, maxHourForADay);
    const maxMinuteOfTheHour = Math.max(...availableMinutes);

    return new Date(moment(dayTimeString)
        .set('hour', +maxHourForADay)
        .set('minutes', +maxMinuteOfTheHour)
        .format()
    );
}
