import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { cartSelectors } from "../../cart/_core/cartSelectors";
import { RollPriceInfo } from "./RollPriceInfo";
import { RollProductQuantity } from "./RollProductQuantity";
import { WidthRatioImageContainer } from "../../common/ratioContainers/WidthRatioImageContainer";
import { PRODUCT_TYPES, ROLL_SIZES } from "../../../api/products/productBaseConstants";
import { titleFormatter } from "../../../utils/titleFormatter";
import { ProductCode } from "../productCode/ProductCode";


export const RollListItem = ({ roll }) => {
  const history = useHistory();
  const open = () => history.push(`/rolls-detail/${roll.id}`);

  const cartItem = useSelector(state => cartSelectors.cartItem(state, roll));
  const productInCart = cartItem.count ? 'incart' : 'none';
  const availableSize = roll.hasMedium ? ROLL_SIZES.medium.id : ROLL_SIZES.big.id

  return (
    <li className="main-goods__list-item" data-item={productInCart}>
      <div className="main-goods__item">
        <h3 className="main-goods__title">
          <div>
            <ProductCode code={roll.code}/>
            <span>{titleFormatter(roll.title)}</span>
          </div>
        </h3>

        <ul className="main-goods-types">
          <RollPriceInfo type="big" productType={roll.big}/>
          {roll.hasMedium && <RollPriceInfo type="medium" productType={roll.medium}/>}
        </ul>

        <WidthRatioImageContainer
          ratio={1}
          publicImages={roll.publicImages}
          type="main"
          onClick={open}
          className="main-goods__picture"/>

        <RollProductQuantity product={roll} type={PRODUCT_TYPES.roll.id} size={availableSize}/>

        <article className="main-goods__chars">
          <p>{roll.get('announce')}</p>
        </article>
      </div>
    </li>
  );
};
