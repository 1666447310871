import React from "react";

import { ProductMenuListItem } from "./ProductMenuListItem";
import { ReactComponent as DessertIcon } from "../../../../../assets/images/react-svg/dessert.icon.svg";
import { ReactComponent as DrinkIcon } from "../../../../../assets/images/react-svg/drink.icon.svg";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";

// bento: {
//   position: 2,
//   anchor: 'bento',
//   title: 'Бенто'
// },
const MENU_ITEMS = {
  rollset: {
    id: PRODUCT_TYPES.rollset.id,
    position: 1,
    anchor: 'rollset',
    title: 'Сеты',
    isNew: true,
  },
  sushi: {
    id: PRODUCT_TYPES.roll.id,
    position: 2,
    anchor: 'sushi',
    title: 'Суши',
    isNew: true,
  },
  garnish: {
    id: PRODUCT_TYPES.garnish.id,
    position: 3,
    anchor: 'garnish',
    title: 'Гарнир'
  },
  sauce: {
    id: PRODUCT_TYPES.garnish.subtypes.sauce.id,
    position: 4,
    anchor: 'sauce',
    title: 'Соусы'
  },
  poke: {
    id: PRODUCT_TYPES.poke.id,
    position: 5,
    anchor: 'poke',
    title: 'Поке'
  },
  soup: {
    id: PRODUCT_TYPES.soup.id,
    position: 6,
    anchor: 'soup',
    title: 'Супы'
  },
  dessert: {
    id: PRODUCT_TYPES.dessert.id,
    position: 7,
    anchor: PRODUCT_TYPES.dessert.id,
    Icon: DessertIcon,
  },
  drinks: {
    id: PRODUCT_TYPES.drink.id,
    position: 8,
    anchor: PRODUCT_TYPES.drink.id,
    Icon: DrinkIcon,
  },
};

export const ProductsMenu = () => {
  return (
    <div className="mx-auto pc:mx-0 text-t13 tab:text-t20 leading-[13px] tab:leading-[20px] font-bold flex justify-between items-end max-w-[296px] tab:max-w-[442px]">
      {Object.values(MENU_ITEMS)
        .sort((a, b) => +a.position > +b.position ? 1 : -1)
        .map((menuElement) => {
          return (
            <ProductMenuListItem
              id={menuElement.id}
              isNew={menuElement.isNew}
              Icon={menuElement.Icon}
              hash={menuElement.anchor}
              title={menuElement.title}
              key={`menuitem_${menuElement.anchor}`}/>
          )
        })}
    </div>
  )

};
