import Immutable from "immutable";

import CartAddressModel from "./CartAddressModel";


const CartRecord = Immutable.Record({
	additionalData: {
		ageLimit: null,
		originalComment: null,
		freeItemsRemaining: {
			garnish: 0,
			sauce: 0,
			stick: 0,
			warmer: 0,
			cookie: 0,
		}
	},
	customerInfo: {
		name: null,
		phone: null,
		isConfirmedPrivacyPolicyRules: false,
		isConfirmPrivacyPolicyRules: false,
		isConfirmPrivacyPolicyOrderData: true,
		isConfirmPrivacyPolicySmsProcessOrder: false,
		isConfirmPrivacyPolicyFeedbackService: false,
	},
	details: {
		cartId: null,
		pinnedAt: null,
		trackedAt: null
	},
	items: [],
	priceSpecification: {
		rawSubtotal: null,
		discountState: null,
		discountAmount: null,
		discountedSubtotal: null,
		deliveryCost: null,
	},
	settings: {
		manufactureType: "sushi",
		isDisabledNoContactDelivery: null,
		isDisabledPaymentByCard: null,
		manufactureInfo: {
			onStopAt: null,
			isPickupSlowed: null,
			manufactureId: null,
			workingTimeInfo: null,
			runtimeSushiDiscount20: null,
			selectedSushiDiscount20: null,
		}},
	shipping: {
		shippingType: "delivery",
		address: new CartAddressModel(),
	},
	status: {
		error: null,
		warning: null
	},
	updating: null,
	hasItemToDelete: null,
});

export class CartModel extends CartRecord {
  //
}
