import { CS } from "../../../utils/modules/reselect";
import { sharedSelectors } from "../../shared/core/sharedSelectors";


export const customerSelectors = {
  cart: CS(sharedSelectors.customer, customer => customer.get('cart')),
  addressError: CS(sharedSelectors.customer, customer => customer.get('addressError')),
  customerInfo: CS(sharedSelectors.customer, customer => customer.get('customerInfo')),
  order: CS(sharedSelectors.customer, customer => customer.get('order')),
};
