import React from "react";

import { priceBYN } from "../../../utils/formatHelpers";


export const PokePriceInfo = ({productType, noAmount}) => {
    return (
        <li className="main-goods-types__list-item">
            <div className="main-goods__price-box">
                <strong className="main-goods__price">{priceBYN(productType.info.price)}</strong>
                {noAmount ? null : <span className="main-goods__portion">{productType.info.amount}</span>}
            </div>
        </li>
    )
};
