import React from 'react';
import {PRICE_PENNY, priceBYN} from "../../../utils/formatHelpers";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";

export const CookieProductPrice = ({desert, freeCookieCount}) => {
	return (
		<>
			{freeCookieCount && desert.subtype === PRODUCT_TYPES.dessert.subtypes.cookie.id ? (
					<div className="secondary-goods__price">
						<small className="secondary-goods__discount">{priceBYN(desert.price)}</small>
						<div className="secondary-goods__title-box">
							<strong>{PRICE_PENNY}</strong>
							<small className="secondary-goods__amount">{desert.amount}</small>
						</div>
					</div>
				)
				: (
					<div className="secondary-goods__price">
						<div className="secondary-goods__title-box">
							<strong>{priceBYN(desert.price)}</strong>
							<small className="secondary-goods__amount">{desert.amount}</small>
						</div>
					</div>
				)
			}
		</>)
}
