import Immutable from "immutable";


const CartItemRecord = Immutable.Record({
  id: null,
  code: null,
  title: null,
  type: null,
  subtype: null,
  announce: null,
  size: null,
  ageLimit: null,

  isOnTrade: null, //это можно купить (т.е. сейчас гипотетически может участвовать в торговых отношениях)
  isSuitableForCurrentCart: null, //это разрешено купить с указанными параметрами

  count: 0,
  discountedPrice: null,
  productPrice: null,
  discountAmount: null,
  discountPercentSuggested: null,
  realizationPrice: 0,
  specialPrice: 100,
  fiascoCandidate: false,
  moneyAmountSavedIfFree: 0,
  priceWithFiasco: 100,
});

export class CartItemModel extends CartItemRecord {
  addItem(cartItemModel) {
    return this
        .set('count', this.count + cartItemModel.count)
        .set('discountedPrice', this.discountedPrice + cartItemModel.discountedPrice)
        .set('productPrice', this.productPrice + cartItemModel.productPrice)
        .set('realizationPrice', this.realizationPrice + cartItemModel.realizationPrice)
        .set('fiascoCandidate', this.fiascoCandidate || cartItemModel.fiascoCandidate)
        .set('moneyAmountSavedIfFree', this.moneyAmountSavedIfFree > cartItemModel.moneyAmountSavedIfFree ? this.moneyAmountSavedIfFree : cartItemModel.moneyAmountSavedIfFree)
  }
}
