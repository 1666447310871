import {all, call, put, takeEvery} from 'redux-saga/effects'

import {api} from "../../../api/api";
import {featureSagaCreator} from "../../../utils/featureSagaCreator";
import {FAIL_ORDER_REQUESTING, SUCCESS_ORDER_REQUESTING} from "./bepaidPageConstants";
import {
	failOrderLoaded,
	failOrderLoadedWithError,
	successOrderLoaded,
	successOrderLoadedWithError
} from "./bepaidPageActions";


function* loadBepaidSuccessOrder({payload}) {
	const {result, error} = yield call(api.bepaid.fetchBepaidSuccessOrder, payload);

	if (error) {
		yield put(successOrderLoadedWithError(error));
	} else {
		yield put(successOrderLoaded(result));
	}
}

function* loadBepaidFailOrder(token) {
	const {result, error} = yield call(api.bepaid.fetchBepaidFailOrder, token);

	if (error) {
		yield put(failOrderLoadedWithError(error))
	} else {
		yield put(failOrderLoaded(result))
	}
}

export const bepaidPageSaga = featureSagaCreator({
	workers: function* () {
		yield all([
			takeEvery(SUCCESS_ORDER_REQUESTING, loadBepaidSuccessOrder),
			takeEvery(FAIL_ORDER_REQUESTING, loadBepaidFailOrder),
		])
	},
});

