import {
	SUCCESS_ORDER_REQUESTING,
	SUCCESS_ORDER_LOADED,
	SUCCESS_ORDER_LOADED_WITH_ERROR,
	FAIL_ORDER_REQUESTING,
	FAIL_ORDER_LOADED_WITH_ERROR,
	FAIL_ORDER_LOADED,
} from "./bepaidPageConstants";
import {BepaidOrderModel} from "../../../models/BepaidOrder/BepaidOrderModel";


const initialState = new BepaidOrderModel();

export const bepaidPageReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case SUCCESS_ORDER_REQUESTING:
			return state.set('orderLoaded', false);

		case SUCCESS_ORDER_LOADED:
			return payload.set('orderLoaded', true);

		case SUCCESS_ORDER_LOADED_WITH_ERROR:
			return initialState.set('orderLoaded', true);

		case FAIL_ORDER_REQUESTING:
			return state.set('orderLoaded', false);

		case FAIL_ORDER_LOADED:
			return payload.set('orderLoaded', true);

		case FAIL_ORDER_LOADED_WITH_ERROR:
			return initialState.set('orderLoaded', true);

		default:
			return state
	}
};
