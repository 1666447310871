import Immutable from 'immutable';

import { __ } from "../../utils/utils";
import CustomerAddressModel from "../../models/Customer/CustomerAddressModel";
import {CustomerModel} from "../../models/Customer/CustomerModel";


export const customerCreator = data => {
	return new CustomerModel({
		addresses: customerAddressMapCreator(data.addresses),
		name: __(data.name),
		phone: __(data.phone),
	})
};

export const customerAddressCreator = data => {
	return new CustomerAddressModel({
		warning: __(data.warning),
		error: __(data.error),
		fullAddress: __(data.full_address),
		id: __(data.id),
	})
};

export const customerAddressMapCreator = data => {
	const customerAddressMap = Immutable.Map();
	return customerAddressMap.withMutations(map => {
		Object.entries(data).forEach(([id, item]) => {
			map.set(item.id, customerAddressCreator(item))
		})
	});
};

