import * as Sentry from '@sentry/react';
import {cartSelectors} from "../components/cart/_core/cartSelectors";
import moment from "moment";

export const enabledSentry = process.env.SENTRY_ENABLED === '1'

export function initSentry() {
  if (enabledSentry) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      release: process.env.REACT_APP_VERSION,
      environment: process.env.NODE_ENV,
      maxBreadcrumbs: 10
    });
  }
}

// https://docs.sentry.io/platforms/javascript/react/
export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	actionTransformer: action => {
		return {
			...action,
			payload: {}
		};
	},
	stateTransformer: state => {
		return {
			customerInfo: cartSelectors.customerInfo(state),
			details: cartSelectors.details(state),
			userTime: moment().format("YYYY-MM-DD HH:mm:ss Z"),
			buildInfo: {
				rootMode: process.env.NODE_ENV === 'production' ? 'production' : 'development',
				accessMode: process.env.API_BYPASS_ENABLED === '1' ? 'bypass' : 'proxy'
			}
		};
	}
});
