import Immutable from "immutable";


const CustomerAddressModelRecord = Immutable.Record({
	error: null,
  warning: null,
  fullAddress: null,
  id: null,
});


export default class CustomerAddressModel extends CustomerAddressModelRecord {
  //
}
