export const featureSagaCreator = ({ init, workers, loader } = {}) => {
  return {
    init: init ? init : function* () {
    },
    workers: workers ? workers : function* () {
    },
    loader: loader ? loader : function* () {
    },
  }
};
