import React from "react";
import { ConnectedRouter } from "connected-react-router/immutable";

import { history } from "../../../router/routerHistory";
import { AppRouter } from "../../../../pages/AppRouter";
import { RootPopup } from "../../../../components/common/popups/RootPopup";

export const AppEntry = () => {
  return (
    <ConnectedRouter history={history}>
      <RootPopup/>
      <AppRouter key="router"/>
    </ConnectedRouter>
  )
};
