import React from "react";
import { useDispatch } from "react-redux";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";
import { openPopup } from "../../../common/popups/_core/popupActions";
import { POPUP_COMPONENTS } from "../../../common/popups/popupsConfig";


export const CartRollsetProductList = ({ rollset }) => {
  const dispatch = useDispatch();

  const open = (rollsetId) => () => {
    dispatch(openPopup({
      type: POPUP_COMPONENTS.rollsetPopup.type,
      props: { rollsetId }
    }));
  };


    return (
		<>
            {rollset.valueSeq().map(rollsetItem => (
                <CartListItem key={rollsetItem.id + '_rollItem'}
                              hideAnnounce
                              onClick={open(rollsetItem.id)}
                              item={rollsetItem}
                              type={PRODUCT_TYPES.rollset.id}
                />
            ))}
		</>
	)
};
