import { fetchApi, fetchApiProcess } from "../../utils/http";
import { customerCreator } from "./customerCreators";


export const customerCallers = {
  login: (payload) => fetchApiProcess([ 'clients', 'login' ], customerCreator, {
    method: 'POST',
    version: 'v4.0.0',
    body: payload
  }),

  logout: () => fetchApi([ 'clients', 'logout' ], {
		method: 'GET',
		version: 'v4.0.0',
	}),

	requestPassword: (payload) => fetchApi(['clients', 'password'], {
		method: 'POST',
		version: 'v4.0.0',
		body: payload
	}),

	deleteAddress: ({address_id}) => fetchApi(['clients', 'forget-address'], {
		method: 'POST',
		version: 'v4.0.0',
		body: {address_id},
	}),

	changePassword: (password) => fetchApi(['clients', 'new-password'], {
		method: 'POST',
		version: 'v4.0.0',
		body: {password},
	}),

	fetchMe: () => fetchApiProcess(['clients', 'me'], customerCreator, {
		method: 'GET',
		version: 'v4.0.0',
	})
};
