import {OrderedMap} from "immutable";

import {__} from "../../../utils/utils";
import SuggestedProductModel from "../../../models/Products/SuggestedProduct/SuggestedProductModel";

export const suggestedProductCreator = data => new SuggestedProductModel({
	id: __(data.id),
	productId: __(data.product_id),
	productType: data.product_type,
	position: __(data.position),
});

export const  suggestedProductProcess = (data) => {
	return (new OrderedMap()).withMutations(map => {
		Object.entries(data).forEach(([id, item]) => {
			map.set(id, suggestedProductCreator(item))
		})
	})
};
