import React from "react";

export const InputRadioButton = ({title, disabled, onClick, checked, name}) => {
	return (
		<>
			{!disabled && (
				<li className="form-list-radio__list-item">
					<label className="form-list-radio__item">
						<input className="form-list-radio__radio"
                   type="radio"
                   name={name}
                   onChange={onClick}
                   checked={checked}/>
            <span className="form-list-radio__value">{title}</span>
          </label>
        </li>
      )}
    </>
  );
};
