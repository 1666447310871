import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

import { catalogSelectors } from "../../catalogs/_core/catalogSelectors";
import { cartSelectors } from "../../cart/_core/cartSelectors";


export const AddressHouseSelectInput = ({
  value, style, disabled, onChange = () => {
  }
}) => {
  const houses = useSelector(catalogSelectors.houses).valueSeq().toArray();
  const address = useSelector(cartSelectors.address);
  const [ selectedHouse, setSelectedHouse ] = useState({
    value: value ? value.value : address?.houseId,
    label: value ? value.label : address?.house,
  });

  useEffect(() => {
    setSelectedHouse({
      value: value ? value.value : address?.houseId,
      label: value ? value.label : address?.house,
    });
  }, [ address.street, address.house, address.houseId, value, value?.value, value?.label ]);

  const handleHouseChange = (newValue) => {
    onChange(newValue);
    setSelectedHouse({ value: newValue.value, label: newValue.label });
  };


  return (
    <Select options={houses}
            styles={style}
            value={selectedHouse}
            isDisabled={disabled}
            onChange={handleHouseChange}
            placeholder=" "
						filterOption={({label}, input) => {
							if (!input) return true;
							return label.includes(input);
						}}
            noOptionsMessage={() => ``}/>
  )
};
