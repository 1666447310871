import { Record } from "immutable";

const RollsetItemModelRecord = Record({
  productType: '',
  id: null,
  position: 0,
  title: "",
  code: "",
  announce: "",
  is_on_trade: false,
  is_suitable_for_current_cart: false,
  product_size: '',
  amount: '',
  calories: 0,
  proteins: 0,
  fats: 0,
  carbohydrates: 0,
  price: 0,
  specialPrice: 0,
  priceAvoided: 0,
  count: 0,
  publicImages: null,
});

export default class RollsetItemModel extends RollsetItemModelRecord {
  //
}