import { Record } from "immutable";


const VacancyModelRecord = Record({
  active: 0,
  description: '',
  id: 0,
  position: 0,
  title: '',
});

export default class VacancyModel extends VacancyModelRecord {
  //
}
