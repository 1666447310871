import React from 'react';
import {PRICE_PENNY, priceBYN} from "../../../utils/formatHelpers";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";

export const DessertProductPrice = ({dessert, freeCookieCount, noAmount}) => {
	return (
		<>
			{freeCookieCount && dessert.subtype === PRODUCT_TYPES.dessert.subtypes.cookie.id ? (
					<div className="secondary-goods__price">
						<small className="secondary-goods__discount">{priceBYN(dessert.price)}</small>
						<div className="secondary-goods__title-box">
							<strong>{PRICE_PENNY}</strong>
							{noAmount ? null : <small className="secondary-goods__amount">{dessert.info.amount}</small>}
						</div>
					</div>
				)
				: (
					<div className="secondary-goods__price">
						<div className="secondary-goods__title-box">
							<strong>{priceBYN(dessert.price)}</strong>
							{noAmount ? null : <small className="secondary-goods__amount">{dessert.info.amount}</small>}
						</div>
					</div>
				)
			}
		</>)
}
