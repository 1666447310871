import { combineReducers } from "redux-immutable";

import { productReducer } from "../../products/_core/productReducer";
import { catalogsReducer } from "../../catalogs/_core/catalogReducer";
import { customerReducer } from "../../customer/_core/customerReducer";
import { settingReducer } from "../../settings/_core/settingReducer";
import { preorderIntervalsReducer } from "../../preorder/_core/preorderReducer";


export const sharedReducer = combineReducers({
	products: productReducer,
	catalogs: catalogsReducer,
	customer: customerReducer,
	settings: settingReducer,
	preorderIntervals: preorderIntervalsReducer,
});
