import React from "react";
import { useSelector } from "react-redux";

import { catalogSelectors } from "../components/catalogs/_core/catalogSelectors";
import { SectionTitle } from "../components/common/sectionTitle/SectionTitle";
import { VacanciesList } from "../components/vacancies/VacanciesList";


export const VacanciesPage = () => {
  const vacancies = useSelector(catalogSelectors.vacancies);
  return (
    <div className="content-page">
      <SectionTitle title="вакансии" count={vacancies.size}/>
      <VacanciesList/>
    </div>
  );
};


