import React from 'react';
import moment from "moment";

export class CustomPreorderInput extends React.Component {
    render() {
        const val = this.props.isDelivery
            ? this.props.value + '–' + moment().hours(this.props.value.slice(11,13)).minutes(this.props.value.slice(14,16)).add(30, 'minutes').format('HH:mm')
            : this.props.value;

        return (
            <input className="form-required form-input form-preorder-input"
                   value={val} onClick={this.props.onClick} readOnly />
        )
    }
}
