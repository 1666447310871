import React from "react";
import { Link } from "react-router-dom";
import plural from "plural-ru";

import { ROUTES } from "../../../system/router/routeConstants";
import { useSelector } from "react-redux";
import { settingSelectors } from "../../settings/_core/settingSelectors";
import { Image } from "../image/Image";

import "./footer.css";
import { cartSelectors } from "../../cart/_core/cartSelectors";
import { nbsp } from "../../../utils/titleFormatter";

const stringifyBYN = (value) => {
  return `${value}${nbsp}${plural(Number.parseInt(value), 'рубль', 'рубля', 'рублей')}`;
};

export const Footer = () => {
	const payments = useSelector(settingSelectors.payments);
	const content = useSelector(settingSelectors.content);
	const cartDetails = useSelector(cartSelectors.details);
	const settings = useSelector(settingSelectors.settings);

  const hardReload = () => {
    document.cookie = "GDZSESSID=0; _ym_uid=0; _ym_d=0; _ym_isad=0; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.location.reload(true);
    localStorage.setItem('cartId', '');
  };

	return (
		<div className="footer">
			<div className="footer__inner">
				<div className="footer__rules">
					{settings.minimalPrice === settings.minimalPriceAction ? (
						<span>минимальная сумма заказа для доставки — {stringifyBYN(settings.minimalPrice)}</span>
					) : (
						<span>Минимальная сумма заказа для доставки с&nbsp;понедельника по&nbsp;четверг
								с&nbsp;{settings.minimalPriceActionTimeFrom}&nbsp;до&nbsp;{settings.minimalPriceActionTimeTo} составляет&nbsp;{stringifyBYN(settings.minimalPriceAction)}.<br/>
								В остальное время, предпраздничные и праздничные
								дни&nbsp;—&nbsp;{stringifyBYN(settings.minimalPrice)}
							</span>
					)}
				</div>

				<nav className="footer-nav">
					<ul className="footer-nav__list">
						<li className="footer-nav__list-item">
							<Link to={{pathname: ROUTES.rules.path}}
										className="footer-nav__item">
								правила оказания услуг
							</Link>
						</li>
						<li className="footer-nav__list-item">
							<Link to={{pathname: ROUTES.payments.path}}
										className="footer-nav__item" href="">
								способы оплаты
							</Link>
						</li>
						<li className="footer-nav__list-item">
							<Link to={{pathname: ROUTES.deliveryZone.path}}
										className="footer-nav__item">
								зона доставки
							</Link>
						</li>
					</ul>
					<ul className="footer-nav__list privat-policy">
						<li className="footer-nav__list-item">
							<Link to={{pathname: ROUTES.audioAndVideoPolicy.path}}
								  className="footer-nav__item">
								политика аудио и видеонаблюдения
							</Link>
						</li>
					</ul>
					<ul className="footer-nav__list privat-policy">
						<li className="footer-nav__list-item">
							<Link to={{pathname: ROUTES.privatPolicy.path}}
										className="footer-nav__item">
								политика обработки персональных данных
							</Link>
						</li>
					</ul>
				</nav>

				<div className="footer__payments">
					{payments.get('payments')
						.valueSeq()
						.sort((a, b) => a.get('position') > b.get('position') ? 1 : -1)
						.map(payment => (
							<div key={payment.id}>
								{payment.link
									? (
										<a href={payment.link} target="_blank">
											<Image type="main" publicImages={payment.publicImages} />
										</a>
									)
									: (<Image type="main" publicImages={payment.publicImages}/>)
								}
							</div>
						))
					}
				</div>

				<div className="footer__instalments">
					{!!payments.get('installments').size && (
						<>
							<h3>рассрочка действует только при оплате онлайн</h3>
							<div className="footer__instalments-list">
								{payments.get('installments')
									.valueSeq()
									.sort((a, b) => a.get('position') > b.get('position') ? 1 : -1)
									.map(payment => (
										<div key={payment.id}>
											{payment.link
												? (
													<a href={payment.link} target="_blank">
														<Image type="main" publicImages={payment.publicImages} />
													</a>
												)
												: (<Image type="main" publicImages={payment.publicImages} />)
											}
										</div>
									))
								}
							</div>
						</>
					)}
				</div>

				<div className="footer__contacts">
					<div dangerouslySetInnerHTML={{__html: content.footer_copyright}}/>
				</div>

				{!!settings.showTestControls && (
					<div className="footer-test-section" >
						<div style={{paddingTop: '30px'}}>
							<span>cartId: {cartDetails.cartId}</span>
						</div>
						<div className="m-centered delete-button" onClick={hardReload}>
							<span>&#x1F5D1; удалить корзину</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
