import React from "react";
import {useSelector} from "react-redux";

import {SectionTitle} from "../components/common/sectionTitle/SectionTitle";
import {settingSelectors} from "../components/settings/_core/settingSelectors";
import {scrollToTop} from "../utils/scrollToAnchor";

export const PrivatPolicyPage = () => {
	const content = useSelector(settingSelectors.content);
	scrollToTop()

	return (
		<div className="content-page">
			<SectionTitle title="Политика обработки персональных данных"/>
			<div dangerouslySetInnerHTML={{__html: content.privat_policy}}/>
			<br/>
		</div>
	)
};
