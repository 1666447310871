import { Record } from "immutable";

import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import {DessertInfo} from "./DessertInfo";

const CookieModelRecord = Record({
	active: null,
	announce: null,
	id: null,
	position: null,
	price: null,
	publicImages: [],
	title: '',

	subtype: PRODUCT_TYPES.dessert.subtypes.cookie.id,
	type: PRODUCT_TYPES.dessert.id,
  info: new DessertInfo(),

	isOnTrade: null,
	isSuitableForCurrentCart: null,

});

export default class CookieModel extends CookieModelRecord {
  //
}
