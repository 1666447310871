import React from "react";

import {titleFormatter} from "../../../utils/titleFormatter";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";
import {SuggestedProductQuantity} from "./SuggestedProductQuantity";
import {Image} from "../../common/image/Image";


export const SuggestedProductItem = ({product, cartItem, updateCart}) => {
    const renderAmount = (product) => {
        let amount = null
        if (product.info && product.info.amount) {
            amount = product.info.amount
        } else if (product.amount) {
            amount = product.amount
        }

        return amount ? <small className="secondary-goods__amount">{amount}</small> : null
    }

    const renderPriceInfo = (product) => {
        let price = null
        if (product.info && product.info.price) {
            price = product.info.price
        } else if (product.price) {
            price = product.price
        }

        return price ? <strong>{price}</strong> : null
    }

    const productInCart = cartItem.count ? 'incart' : 'none';

    return (
        <div className='suggested-product-list__item' data-item={productInCart}>
            <Image
                alwaysMax={true}
                publicImages={product.publicImages}
                type={'main'}
                ratio={1}
                className={'suggested-product_img ' + (product.type === PRODUCT_TYPES.drink.id ? 'suggested-product_img-drink' : '')}
            />
            <h3 className="secondary-goods__title">
                <span>{titleFormatter(product.title)}</span>
            </h3>
            <div className="secondary-goods__price">
                <div className="secondary-goods__title-box">
                    {renderPriceInfo(product)}
                    {renderAmount(product)}
                </div>
            </div>
            <SuggestedProductQuantity product={product} updateCart={updateCart} isSuggested={true}/>
        </div>

    );
};
