import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../common/button/Button";
import { settingSelectors } from "../../settings/_core/settingSelectors";
import { catalogSelectors } from "../../catalogs/_core/catalogSelectors";
import { orderSelectors } from "../_core/orderSelectors";
import { FeedbackList } from "./FeedbackList";
import { FeedbackSection } from "./FeedbackSection";
import { updateOrder } from "../_core/orderActions";

import './OrderFeedbackForm.css';

export const OrderFeedbackForm = () => {
    const dispatch = useDispatch();
    const settings = useSelector(settingSelectors.settings);
    const orderFeedback = useSelector(orderSelectors.feedback);
    const feedbackTroubles = useSelector(catalogSelectors.feedbackTroubles);
    const [cartTroubles, setCartTroubles] = useState(orderFeedback?.troubles || []);
    const [feedbackComment, setFeedbackComment] = useState(orderFeedback?.comment || '');
    const [conclusion, setConclusion] = useState(orderFeedback?.conclusion || null);

    const sendFeedback = () => {
        dispatch(updateOrder({
            feedback: {
                comment: 'asdasdasd',
                conclusion: 'troubles',
                troubles: [cartTroubles]
            }
        }))
    }

    const toggleTrouble = (trouble) => () => {
        if (cartTroubles.includes(trouble)) {
            setCartTroubles(cartTroubles.filter(i => i !== trouble))
        } else {
            setCartTroubles([...cartTroubles, trouble])
        }
    }

    if (!settings.isFeedbackEnabled) return null;

    return (
        <div className="order-feedback-form">

            <div className="feedback-conclusion-checker">
                <div className={`feedback-element ${conclusion === 'ok' ? 'active' : ''}`}
                     onClick={() => setConclusion('ok')}>
                    с заказом все хорошо
                </div>
                <div className={`feedback-element ${conclusion === 'troubles' ? 'active' : ''}`}
                     onClick={() => setConclusion('troubles')}>
                    что-то пошло не так
                </div>
            </div>

            {conclusion === 'troubles' && (
                <div>
                    {feedbackTroubles.sections && feedbackTroubles.sections.map((i, hash) => (
                        <FeedbackSection section={i} key={hash} cartTroubles={cartTroubles}
                                         toggleTrouble={toggleTrouble}/>
                    ))}

                    {feedbackTroubles.list && (
                        <FeedbackList list={feedbackTroubles.list} cartTroubles={cartTroubles}
                                      toggleTrouble={toggleTrouble}/>
                    )}

                    <div className="feedback-comment">
                        <textarea value={feedbackComment} onChange={(e) => setFeedbackComment(e.target.value)}/>
                    </div>
                </div>

            )}

            <div className="feedback-submit-button">
                <Button
                    onClick={sendFeedback}
                    className="btn-primary submit-order-button">отправить
                </Button>
            </div>
        </div>
    )
}
