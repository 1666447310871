import { OrderedMap } from "immutable";

import { __ } from "../../utils/utils";
import { SlideModel } from "../../models/Settings/SlideModel";


export const slideCreator = data => new SlideModel({
  active: __(data.active),
  id: __(data.id),
  link: __(data.link),
  photo: __(data.photo),
  position: __(data.position),
  title: __(data.title),
  publicImages: data.public_images,
});

export const slideProcess = (data) => {
  return ( new OrderedMap() ).withMutations(map => {
    if (data) {
      Object.entries(data).forEach(([id, item]) => map.set(__(item.id), slideCreator(item)))
    }
  })
};
