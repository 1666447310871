import React from 'react';


export const FeedbackList = ({list, cartTroubles, toggleTrouble}) => {
    return (
        <div className="feedback-list" >
            {Object.keys(list).sort((a, b) => +a.position > +b.position ? 1 : -1).map(key => (
                <div
                    className={`feedback-element inline ${cartTroubles.includes(key) ? 'active' : ''}`}
                    onClick={toggleTrouble(key)}
                    key={key}>
                    {list[key].title}
                </div>)
            )}
        </div>
    )
}
