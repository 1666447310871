import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { cartSelectors } from "../../cart/_core/cartSelectors";
import { SoupPriceInfo } from "./SoupPriceInfo";
import { SoupProductQuantity } from "./SoupProductQuantity";
import { WidthRatioImageContainer } from "../../common/ratioContainers/WidthRatioImageContainer";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import { titleFormatter } from "../../../utils/titleFormatter";


export const SoupListItem = ({ soup }) => {
  const history = useHistory();
  const open = () => history.push(`/soup-detail/${soup.id}`);

  const cartItem = useSelector(state => cartSelectors.cartItem(state, soup));
  const productInCart = cartItem.count ? 'incart' : 'none';

  return (
    <li className="main-goods__list-item" data-item={productInCart}>
      <div className="main-goods__item">
        <h3 className="main-goods__title">
          <div>
            <span>{titleFormatter(soup.title)}</span>
          </div>
        </h3>

        <ul className="main-goods-types">
          <SoupPriceInfo type="big" productType={soup}/>
        </ul>

        <WidthRatioImageContainer
          ratio={1}
          publicImages={soup.publicImages}
          type="main"
          onClick={open}
          className="main-goods__picture"/>

        <SoupProductQuantity product={soup} type={PRODUCT_TYPES.soup.id}/>

        <article className="main-goods__chars">
          <p>{soup.get('announce')}</p>
        </article>
      </div>
    </li>
  );
};
