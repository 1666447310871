import React, { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { closePopup } from "./_core/popupActions";
import { pageSelectors } from "../../../pages/_core/pagesSelectors";
import { POPUP_COMPONENTS } from "./popupsConfig";

import "./styles/popup.css";
import "./styles/popup-form.css";
import "./styles/product-popup.css";


const defaultPopupStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 51,
	},
	content: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		border: 'none',
		background: 'unset',
		overflow: 'auto',
		WebkitOverflowScrolling: 'touch',
		borderRadius: 0,
		outline: 'none',
		padding: 0
	}
};

Modal.setAppElement('#root');
export const RootPopup = () => {
	const {type, props, inFocus} = useSelector(pageSelectors.popup);
	const dispatch = useDispatch();

  useEffect(() => {
    if (type) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  }, [ type ]);

	if (!type) {
		return null;
	}
	let styles = {...defaultPopupStyles}
	if (type === POPUP_COMPONENTS.privacyPolicyRulesPopup.type) {
		styles.overlay = {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 51,
			width: '90%',
			margin: '20px auto',
			background: 'white',
			borderRadius: '10px',
		}
	}

	const SpecificModal = POPUP_COMPONENTS[type].component;
	return (
		<Modal
      isOpen={true}
      style={styles}
      onRequestClose={() => {
        if (inFocus) {
          dispatch(closePopup())
        }
      }}>

      <SpecificModal {...props} />
    </Modal>
  )
};
