import React, {useState} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { cartSelectors } from "../../cart/_core/cartSelectors";
import { RollsetPriceInfo } from "./RollsetPriceInfo";
import { RollsetProductQuantity } from "./RollsetProductQuantity";
import { WidthRatioImageContainer } from "../../common/ratioContainers/WidthRatioImageContainer";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import { titleFormatter } from "../../../utils/titleFormatter";
import {RollsetProductList} from "./RollsetProductList";


export const RollsetListItem = ({ rollset }) => {
  const history = useHistory();
  const [state, setState] = useState({
    showComposition: false,
  });

  const handleChangeShowComposition = () => {
    setState({
      showComposition: !state.showComposition,
    })
  };
  const open = () => history.push(`/rollset-detail/${rollset.id}`);

  const cartItem = useSelector(state => cartSelectors.cartItem(state, rollset));
  const productInCart = cartItem.count ? 'incart' : 'none';
  const renderCollapseDetailStatus = () => {
    return state.showComposition ? <span className='triangle-open'></span> : <span className='triangle-close'></span>
  }

  const renderRollsetChildren = () => {
    if (state.showComposition) {
      return (
          <article className="main-goods__chars">
            <p>{rollset.get('announce')}</p>
            <RollsetProductList products={rollset.children}/>
            <p className="cursor-pointer btn-title btn-more-detailed-product" onClick={open}>подробнее</p>
          </article>
      )
    }

    return null
  }

  return (
    <li className="main-goods__list-item" data-item={productInCart}>
      <div className="main-goods__item">
        <h3 className="main-goods__title-mini">
          <div>
            <span>{titleFormatter(rollset.title)}</span>
          </div>
        </h3>

        <ul className="main-goods-types">
          <RollsetPriceInfo type="big" productType={rollset}/>
        </ul>

        <WidthRatioImageContainer
          ratio={1}
          publicImages={rollset.publicImages}
          type="main"
          onClick={open}
          className="main-goods__picture"/>

        <RollsetProductQuantity product={rollset} type={PRODUCT_TYPES.rollset.id}/>
        <p className="cursor-pointer collapse-description-wrapper" onClick={handleChangeShowComposition}>
          <span className='btn-title'>состав </span> {renderCollapseDetailStatus()}
        </p>
        {state.showComposition && renderRollsetChildren()}
      </div>
    </li>
  );
};
