import { CS } from "../../../utils/modules/reselect";
import { customerSelectors } from "../../customer/_core/customerSelectors";

const shipping = CS(customerSelectors.order, order => order?.shipping || {});
const isPreorder = CS(shipping, s => s.timeDetails?.timeType === 'preorder');

export const orderSelectors = {
    feedback: CS(customerSelectors.order, order => order.get('feedback')),
    isPickup: CS(shipping, ({shippingType}) => shippingType === 'pickup'),
    address: CS(shipping, (shipping) => shipping.address),
    isPreorder: isPreorder,
    rawPreorderAt: CS([shipping, isPreorder], (s, ip) => ip ? (s.timeDetails.date + ' ' + s.timeDetails.time) : null),
    delay: CS(shipping, s => s.timeDetails.delay),
};
