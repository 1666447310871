import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";
import {take} from "@redux-saga/core/effects";

import {generateCalls} from "../../../utils/utils";
import {featureSagaCreator} from "../../../utils/featureSagaCreator";
import {api} from "../../../api/api";
import {
	cartProcessed,
	cartProcessedWithError,
	cartUpdated,
	cartUpdateRequested,
	cartUpdateWithError,
} from "./cartActions";
import {openPopup} from "../../common/popups/_core/popupActions";
import {orderLoaded} from "../../order/_core/orderActions";
import {resetCartPage} from "../../../pages/_core/cartPage/cartPageActions";
import {closePopupSafe, showPopupError} from "../../common/popups/_core/popupSaga";
import {fetchMe} from "../../customer/_core/customerSaga";
import {cartPageSelectors} from "../../../pages/_core/cartPage/cartPageSelectors";
import {cartSelectors} from "./cartSelectors";
import {customerSelectors} from "../../customer/_core/customerSelectors";
import {applyOrderValidationRules, orderValidation as OV} from "../cartForm/validationComponents/paymentFormValidation";
import {
	ADD_ITEM_TO_CART,
	APPLY_ADDRESS,
	APPLY_PICKUP,
	CART_PROCESSING,
	CART_PROCESSING_APPROVED,
	CART_UPDATED,
	REMOVE_ITEM_FROM_CART,
	REMOVE_ITEM_GROUP_FROM_CART,
	SET_CART_ADDRESS,
	STREET_CHANGED,
	TOGGLE_CART_CONFIRM_PRIVACY_POLICY_FEEDBACK_SERVICE,
	TOGGLE_CART_CONFIRM_PRIVACY_POLICY_ORDER_DATA,
	TOGGLE_CART_CONFIRM_PRIVACY_POLICY_RULES,
	TOGGLE_CART_CONFIRM_PRIVACY_POLICY_SMS_PROCESS_ORDER,
	UPDATE_CART_ADDRESS,
	UPDATE_CART_CLIENT_NAME,
	UPDATE_CART_CLIENT_PHONE,
	UPDATE_CART_COMMENT,
	UPDATE_CART_ENTRANCE,
	UPDATE_CART_FLAT,
	UPDATE_CART_FLOOR,
	UPDATE_CART_INTERCOM,
	UPDATE_CART_NO_CONTACT,
	UPDATE_CART_PAYMENT,
	UPDATE_CART_PICKUP_DELAY,
	UPDATE_CART_PREORDER,
	UPDATE_CART_PREPARE_CHARGE
} from "./cartConstants";
import {ADDRESS_FORM_MODIFICATION_HANDLED} from "../../../pages/_core/cartPage/cartPageConstants";
import {CLOSE_POPUP} from "../../common/popups/_core/popupConstants";
import {POPUP_COMPONENTS} from "../../common/popups/popupsConfig";
import {removeAddressError} from "../../address/_core/addressActions";
import {productLoader} from "../../products/_core/productSaga";
import {sendOrderDoneGoogleTagAnalyticReport} from "../../../utils/googleTagHelpers";
import * as preorderActions from "../../preorder/_core/preorderActions";
import {preorderSelectors} from "../../preorder/_core/preorderSelectors";


function* streetChanged({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeAddress, {
		street: payload.street,
		street_id: payload.streetId,

		house: '',
		house_id: '',

		flat: '',
		entrance: '',
		floor: '',
		intercom: '',
	});
	yield* updateCartWithResult(responce);
}

function* preUpdateCheck() {
	const isCartRequested = yield select(cartSelectors.updating);
	if (isCartRequested) {
		yield take(CART_UPDATED);
	}
	yield put(cartUpdateRequested());
}

function*  updateCartWithResult({result, error}, closePopup) {
	if (error) {
		yield put(cartUpdateWithError(error.message))
	}
	else {
		yield put(cartUpdated(result));
		if (closePopup) {
			yield call(closePopupSafe);
		}
	}
}

function* checkCurrentManufacture({result}) {
	if (!result) {
		return null;
	}

	const {manufactureInfo} = yield select(cartSelectors.settings);
	if (manufactureInfo?.manufactureId !== result.settings?.manufactureInfo?.manufactureId) {
		yield* productLoader();
	}
}

function* updateCartAddressSaga({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeAddress, payload);
	yield* checkCurrentManufacture(responce);
	yield* updateCartWithResult(responce, true);
	const isPreorder = yield select(cartSelectors.isPreorder)
	if (isPreorder) {
		yield put(preorderActions.fetchPreorderIntervals());
	}
}

function* setCartAddressSaga({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.setAddress, payload);
	yield* checkCurrentManufacture(responce);
	yield* updateCartWithResult(responce, true);
}

function* updateCartFlat({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeFlat, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartFloor({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeFloor, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartEntrance({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeEntrance, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartIntercom({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeIntercom, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartClientName({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeClientName, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartClientPhone({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeClientPhone, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartNoContact({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeNoContact, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartPickupDelay({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changePickupDelay, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartPreorder({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changePreorder, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartComment({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changeComment, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartPayment({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changePayment, payload);
	yield* updateCartWithResult(responce);
}

function* updateConfirmedPrivacyPolicyRules({payload})
{
	const responce = yield call(api.cart.changeConfirmedPrivacyPolicyRules, payload);
	yield* updateCartWithResult(responce);
}

function* updateConfirmPrivacyPolicyOrderData({payload})
{
	const responce = yield call(api.cart.changeConfirmPrivacyPolicyOrderData, payload);
	yield* updateCartWithResult(responce);
}

function* updateConfirmPrivacyPolicySmsProcessOrder({payload})
{
	const responce = yield call(api.cart.changeConfirmPrivacyPolicySmsProcessOrder, payload);
	yield* updateCartWithResult(responce);
}

function* updateConfirmPrivacyPolicyFeedbackService({payload})
{
	const responce = yield call(api.cart.changeConfirmPrivacyPolicyFeedbackService, payload);
	yield* updateCartWithResult(responce);
}

function* updateCartPrepareCharge({payload})
{
	yield call(preUpdateCheck);
	const responce = yield call(api.cart.changePrepareCharge, payload);
	yield* updateCartWithResult(responce);
}

function* applyPickup({payload})
{
	const {result, error} = yield call(api.cart.applyPickup, payload);
	if (error) {
		yield fork(showPopupError, error.message);
	}
	else {
		yield* checkCurrentManufacture({result});
		yield put(cartUpdated(result));
		yield put(removeAddressError());
    yield call(closePopupSafe);
	}
}

function* applyAddress({payload})
{
	const {result, error} = yield call(api.cart.applyClientAddress, payload);
	if (error) {
		yield fork(showPopupError, error.message);
	}
	else {
		yield* checkCurrentManufacture({result});
		yield put(cartUpdated(result));
		yield put(removeAddressError());
    yield call(closePopupSafe);
	}
}

function* addItemToCartRequest(payload)
{
	const responce = yield call(api.cart.addItemToCart, payload);
	yield* updateCartWithResult(responce);
}

function* addItemToCart({payload})
{
	const isOrderSubmitted = yield select(cartPageSelectors.isOrderSubmitted);
	if (isOrderSubmitted) {
		yield put(resetCartPage());
	}

	const cartAddress = yield select(cartSelectors.address);
	if (cartAddress.get('house')) {
		yield call(addItemToCartRequest, payload);
	}
	else {
		yield put(openPopup({type: POPUP_COMPONENTS.addressPopup.type}));
		yield take(CLOSE_POPUP);

		const cartAddress = yield select(cartSelectors.address);
		if (cartAddress.get('house')) {
			yield call(addItemToCartRequest, payload);
		}
	}
}

function* removeItemFromCart({payload})
{
	const responce = yield call(api.cart.removeItemFromCart, payload);
	yield* updateCartWithResult(responce);
}

function* removeAllItemFromCart({payload})
{
	const responce = yield call(api.cart.removeAllItemFromCart, payload);
	yield* updateCartWithResult(responce);
}

function* loadCart()
{
	const {result, error} = yield call(api.cart.fetchCart);
	if (error) {
		throw new Error(error);
	}
	else {
		localStorage.setItem('cartId', result.details.cartId);
		yield put(cartUpdated(result));
		if (result.shipping.timeDetails.timeType === 'preorder') {
			yield put(preorderActions.fetchPreorderIntervals());
		}
	}
}

const loaderSettings = {
	cart: true,
};

function* loader(settings = loaderSettings)
{
	settings = {...loaderSettings, ...settings};
	yield all(generateCalls(
		{
			loadCart: settings.cart,
		},
		{
			loadCart,
		},
	))
}

function* submitOrderRequestSaga({deliveryTimeSelected}) {
	const cart = yield select(customerSelectors.cart);
	const preorderIntervals = yield select(preorderSelectors.intervals);

	const {validationPassed, errorMessage} = applyOrderValidationRules([
		[OV.invalidPhone, 'введите корректный номер телефона'],
		[OV.isOurPhone, 'на указанный номер нельзя совершить заказ'],
		[OV.emptyName, 'введите ваше имя'],
		[OV.emptyAddress, 'улица или номер дома не выбраны'],
		[OV.emptyPayment, 'способ оплаты не выбран'],
		[OV.noTimeForPickup, 'выберите время получения заказа'],
		[OV.noValidPaymentMethod, 'способ оплаты не выбран'],
		[OV.confirmPrivacyPolicy, 'требуется согласие на обработку персональных данных'],
		[OV.notAvailablePreorderTime, 'недоступное время предзаказа'],
	], {cart, preorderIntervals});

	if (validationPassed && (deliveryTimeSelected || (cart.shipping.timeDetails.timeType === 'preorder'))) {
		const validation = yield call(api.cart.validateCart);
		if (validation.error ) {
			yield put(cartUpdateWithError(validation.error.message));
			yield put(cartProcessedWithError());
		}
		else if (validation.result.status.error) {
			yield put(cartUpdateWithError(validation.result));
			yield put(cartProcessedWithError());
		} else {
			if (cart.additionalData.freeItemsRemaining.get('garnish') && !cart.items.garnish.size) {
				yield put(openPopup({type: POPUP_COMPONENTS.cartConfirmingPopup.type}));
			} else {
				yield finalizeOrder();
			}
		}
	}
	else {
		yield put(cartProcessedWithError(errorMessage || 'выберите время доставки'));
	}
}

function* finalizeOrder() {
	const {result, error} = yield call(api.cart.submitOrder);

	if (error) {
		yield put(cartProcessedWithError(error.message));
	}
	else {
		sendOrderDoneGoogleTagAnalyticReport(result);

		if (result.additionalData.bepaidRedirectUrl) {
			window.location.href = result.additionalData.bepaidRedirectUrl;
		}
		else {
			yield put(orderLoaded(result));
			yield put(cartProcessed(result));
			yield call(fetchMe);
			window.scrollTo({top: 0, behavior: "smooth"});
		}
		yield* loadCart();
	}
}

function* submitOrderSaga({payload: {deliveryTimeSelected}})
{
	const addressFormModified = yield select(cartPageSelectors.addressFormModified);

	if (addressFormModified) {
		yield take(ADDRESS_FORM_MODIFICATION_HANDLED);
		yield call(submitOrderRequestSaga, {deliveryTimeSelected});
	}
	else {
		yield call(submitOrderRequestSaga, {deliveryTimeSelected});
	}
}


export const cartSaga = featureSagaCreator({
	init: loader,
	workers: function* () {
		yield all([
			takeEvery(APPLY_PICKUP, applyPickup),
			takeEvery(APPLY_ADDRESS, applyAddress),
			takeEvery(STREET_CHANGED, streetChanged),

			takeEvery(ADD_ITEM_TO_CART, addItemToCart),
			takeEvery(REMOVE_ITEM_FROM_CART, removeItemFromCart),
			takeEvery(REMOVE_ITEM_GROUP_FROM_CART, removeAllItemFromCart),

			takeEvery(UPDATE_CART_ADDRESS, updateCartAddressSaga),
			takeEvery(SET_CART_ADDRESS, setCartAddressSaga),
			takeEvery(UPDATE_CART_FLAT, updateCartFlat),
			takeEvery(UPDATE_CART_FLOOR, updateCartFloor),
			takeEvery(UPDATE_CART_ENTRANCE, updateCartEntrance),
			takeEvery(UPDATE_CART_INTERCOM, updateCartIntercom),

			takeEvery(UPDATE_CART_CLIENT_NAME, updateCartClientName),
			takeEvery(UPDATE_CART_CLIENT_PHONE, updateCartClientPhone),

			takeEvery(UPDATE_CART_NO_CONTACT, updateCartNoContact),
			takeEvery(UPDATE_CART_PICKUP_DELAY, updateCartPickupDelay),
			takeEvery(UPDATE_CART_PREORDER, updateCartPreorder),
			takeEvery(UPDATE_CART_COMMENT, updateCartComment),


			takeEvery(UPDATE_CART_PAYMENT, updateCartPayment),
			takeEvery(UPDATE_CART_PREPARE_CHARGE, updateCartPrepareCharge),

			takeEvery(CART_PROCESSING, submitOrderSaga),
			takeEvery(CART_PROCESSING_APPROVED, finalizeOrder),
			takeEvery(TOGGLE_CART_CONFIRM_PRIVACY_POLICY_RULES, updateConfirmedPrivacyPolicyRules),
			takeEvery(TOGGLE_CART_CONFIRM_PRIVACY_POLICY_ORDER_DATA, updateConfirmPrivacyPolicyOrderData),
			takeEvery(TOGGLE_CART_CONFIRM_PRIVACY_POLICY_SMS_PROCESS_ORDER, updateConfirmPrivacyPolicySmsProcessOrder),
			takeEvery(TOGGLE_CART_CONFIRM_PRIVACY_POLICY_FEEDBACK_SERVICE, updateConfirmPrivacyPolicyFeedbackService),
		])
	},
});
