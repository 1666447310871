import React from "react";
import { useSelector } from "react-redux";

import { priceBYN } from "../../../utils/formatHelpers";
import { customerSelectors } from "../../customer/_core/customerSelectors";
import { CartDessertProductList } from "./cartProductLists/CartDessertProductList";
import { CartDrinkProductList } from "./cartProductLists/CartDrinkProductList";
import { CartGarnishProductList } from "./cartProductLists/CartGarnishProductList";
import { CartRollProductList } from "./cartProductLists/CartRollProductList";
import { CartSauceProductList } from "./cartProductLists/CartSauceProductList";
import { CartStickProductList } from "./cartProductLists/CartStickProductList";
import { Warning } from "../../common/notifications/warning/Warning";
import { DISCOUNT_TYPES } from "../../../api/cart/cartCreators";
import { CartCoffeeProductList } from "./cartProductLists/CartCoffeeProductList";
import { CartTeaProductList } from "./cartProductLists/CartTeaProductList";
import { CartOtherProductList } from "./cartProductLists/CartOtherProductList";
import { CartPokeProductList } from "./cartProductLists/CartPokeProductList";
import { CartSoupProductList } from "./cartProductLists/CartSoupProductList";
import "./cart-table.css";
import {CartRollsetProductList} from "./cartProductLists/CartRollsetProductList";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";


export const CartList = () => {
    const cart = useSelector(customerSelectors.cart);
    const {priceSpecification} = cart;
    const {soup, roll, rollset, garnish, sauce, stick, drink, coffee, tea, other, cookie, cake, pie, standard, light, sashimi
    } = cart.items;
    return (
        <div className="cart-table">
            <ul className="cart-table__list">
                {rollset && <CartRollsetProductList rollset={rollset}/>}
                {roll && <CartRollProductList roll={roll}/>}
                {garnish && <CartGarnishProductList garnish={garnish}/>}
                {sauce && <CartSauceProductList sauce={sauce}/>}
                {standard && <CartPokeProductList poke={standard} subtype={PRODUCT_TYPES.poke.subtypes.standard.id}/>}
                {light && <CartPokeProductList poke={light} subtype={PRODUCT_TYPES.poke.subtypes.light.id}/>}
                {sashimi && <CartPokeProductList poke={sashimi} subtype={PRODUCT_TYPES.poke.subtypes.sashimi.id}/>}
                {/*{(cookie || cake) && <CartDessertProductList dessert={cookie.merge(cake)}/>}*/}
                {soup && <CartSoupProductList soup={soup}/>}
                {stick && <CartStickProductList stick={stick}/>}
                {cookie && <CartDessertProductList dessert={cookie} subtype={PRODUCT_TYPES.dessert.subtypes.cookie.id}/>}
                {cake && <CartDessertProductList dessert={cake} subtype={PRODUCT_TYPES.dessert.subtypes.cake.id}/>}
                {pie && <CartDessertProductList dessert={pie} subtype={PRODUCT_TYPES.dessert.subtypes.pie.id}/>}
                {drink && <CartDrinkProductList drink={drink}/>}
                {coffee && <CartCoffeeProductList coffee={coffee}/>}
                {tea && <CartTeaProductList tea={tea}/>}
                {other && <CartOtherProductList other={other}/>}

            </ul>
            <div className="form-payments-and-total-general">
                <div className="form-payments-and-total__price">

                    {
                        (priceSpecification.discountState?.get('type') === DISCOUNT_TYPES.permanentPercentAll
                            || priceSpecification.discountState?.get('type') === DISCOUNT_TYPES.singlePercentAll)
                            ? (<Warning
                                style={{textAlign: 'left'}}
                                description={`ваша скидка распространяется на все товары в заказе, кроме тех, которые уже попадают под действие правила	"блюдо за 1 копейку"`}
                            />)
                            : (<div/>)
                    }

                    {
                        (priceSpecification.discountState?.get('type') === DISCOUNT_TYPES.singlePercentRoll)
                            ? (<Warning style={{textAlign: 'left'}}
                                        description={`ваша скидка распространяется на самый дорогой ролл в заказе`}/>)
                            : (<div/>)
                    }

                    <div style={{marginLeft: '20px'}}>
                        {!!priceSpecification.discountItemsAmount && (
                            <>
                                <div style={{whiteSpace: 'nowrap'}}>
                                    <span className="form-payments-and-total-general__label">стоимость:</span>
                                    <strong
                                        className="form-payments-and-total-general__total">{priceBYN(priceSpecification.rawItemsPrice)}
                                    </strong>
                                </div>

                                {
                                    !!priceSpecification.discountState.get('percent') && (
                                        <div>
                                            <span className="form-payments-and-total-general__label">скидка:</span>
                                            <strong
                                                className="form-payments-and-total-general__total">{priceSpecification.discountState.get('percent')}%
                                            </strong>
                                        </div>
                                    )
                                }

                                {
                                    !priceSpecification.discountState.get('percent') && (
                                        <div>
                                            <span className="form-payments-and-total-general__label">скидка:</span>
                                            <strong
                                                className="form-payments-and-total-general__total">{priceSpecification.discountItemsAmount}
                                            </strong>
                                        </div>
                                    )
                                }
                            </>
                        )}
                        <div>
                            <span className="form-payments-and-total-general__label">итого:</span>
                            <strong
                                className="form-payments-and-total-general__total">{priceBYN(priceSpecification.orderTotal)}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
