import React, {useState} from "react";
import { useSelector } from "react-redux";
import { productSelectors } from "../_core/productSelectors";
import plural from "plural-ru";
import { CookieList } from "./CookieList";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import {cartSelectors} from "../../cart/_core/cartSelectors";

export const CookieCartSection = () => {
    const cookie = useSelector(productSelectors[PRODUCT_TYPES.dessert.subtypes.cookie.id]);
    const freeItemsRemaining = useSelector(cartSelectors.additionalData).freeItemsRemaining;
    const freeCookieCount = freeItemsRemaining.get('cookie')
    const nbsp = '\xa0';
    const displayHint = !!freeCookieCount

    const [state, setState] = useState({
        isShow: true,
    });

    const close = () => {
        setState({
            isShow: false,
        })
    };

    return displayHint && state.isShow ? (
        <>
            <div className="secondary-goods cart-cookies-section">
              <div className='btn-close-wrapper'>
                  <button onClick={close}>
                      <div className='close'></div>
                  </button>
              </div>
                <p className="cart-cookies-section-info">{`добавить в корзину ${freeItemsRemaining.get('cookie')}${nbsp}${plural(freeItemsRemaining.get('cookie'), 'порцию', 'порции', 'порций')} по цене одна копейка`}</p>
                <CookieList productList={cookie} freeCookieCount={freeCookieCount}/>
            </div>
        </>
    ) : null
}
