import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { closePopup } from "../../../common/popups/_core/popupActions";
import { productSelectors } from "../../_core/productSelectors";
import { popupSelector } from "../../../common/popups/_core/popupSelectors";
import { CircleBackground } from "../../../common/circleBackground/CircleBackground";
import { Image } from "../../../common/image/Image";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";
import {PokePriceInfo} from "../PokePriceInfo";
import {PokeProductQuantity} from "../PokeProductQuantity";
import { ROUTES } from "../../../../system/router/routeConstants";


export const PokePopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pokeId = useSelector(popupSelector.props).pokeId;
  const pokesStandard = useSelector(productSelectors[PRODUCT_TYPES.poke.subtypes.standard.id]);
  const pokesLight = useSelector(productSelectors[PRODUCT_TYPES.poke.subtypes.light.id]);
  const pokesSashimi = useSelector(productSelectors[PRODUCT_TYPES.poke.subtypes.sashimi.id]);
  const pokes = pokesStandard.merge(pokesLight).merge(pokesSashimi)
  const poke = pokes.get(pokeId);

  const handleClick = () => {
    history.push(ROUTES.main.path);
    dispatch(closePopup());
  };

  return (
    <div className="popup popup--product popup--show">

      <button
        onClick={handleClick}
        className="popup__close"
        type="button"
        title="закрыть">&times;
      </button>

      <div className="popup-outer">
        <div className="popup-product">
          <h1 className="popup-product__title">{poke.title.toLowerCase()}</h1>

          <ul className="popup-product-types">
            <PokePriceInfo type="big" noAmount productType={poke}/>
          </ul>

          <Image className="popup-product__picture"
                 publicImages={poke.publicImages}
                 type="popup"
                 height={600} width={600}/>

          <PokeProductQuantity product={poke} type={PRODUCT_TYPES.poke.id}/>

          <article className="popup-product__chars">
            <p>{poke.get('announce')}</p>
          </article>
          <ul className="popup-product-ingredients">
            <li className="popup-product-ingredients__list-item">вес {poke.info.amount}</li>
            <li className="popup-product-ingredients__list-item">калории {poke.info.calories} ккал</li>
            <li className="popup-product-ingredients__list-item">углеводы {poke.info.carbohydrates} г</li>
            <li className="popup-product-ingredients__list-item">белки {poke.info.proteins} г</li>
            <li className="popup-product-ingredients__list-item">жиры {poke.info.fats} г</li>
          </ul>
        </div>
      </div>
      <CircleBackground className="hide-on-mobile"/>
    </div>
  );
};
