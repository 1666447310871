import { createAction } from "@reduxjs/toolkit";

import {
  ADD_FOCUS_TO_POPUP,
  CHANGE_POPUP_PROPS,
  CLOSE_POPUP,
  OPEN_POPUP,
  REMOVE_FOCUS_FROM_POPUP
} from "./popupConstants";


export const closePopup = createAction(CLOSE_POPUP);
export const openPopup = createAction(OPEN_POPUP);
export const changePopupProps = createAction(CHANGE_POPUP_PROPS);

export const removeFocusFromPopup = createAction(REMOVE_FOCUS_FROM_POPUP);
export const addFocusToPopup = createAction(ADD_FOCUS_TO_POPUP);
