import React from "react";
import { useSelector } from "react-redux";
import m from "moment";

import { customerSelectors } from "../customer/_core/customerSelectors";
import { PRODUCT_TYPES, ROLL_SIZES } from "../../api/products/productBaseConstants";
import { PAYMENT_METHOD } from "../cart/cartForm/formComponents/PaymentInput";
import { priceBYN } from "../../utils/formatHelpers";
import { orderSelectors } from "./_core/orderSelectors";


export const OrderList = () => {
	const order = useSelector(customerSelectors.order);
	const address = useSelector(orderSelectors.address);
	const isPickup = useSelector(orderSelectors.isPickup);
	const delay = useSelector(orderSelectors.delay);
	const isPreorder = useSelector(orderSelectors.isPreorder);
	const rawPreorderAt = useSelector(orderSelectors.rawPreorderAt);
	const preorderAt = isPreorder ? m(rawPreorderAt) : null;

	if (!order.num) {
		return null;
	}

	let title = null
	if (isPreorder && isPickup) {
		title = `Ждем Вас по адресу: ${address.street}, ${address.house} ${preorderAt.format('DD.MM.YY')} к ${preorderAt.format('H:mm')}.`;
	}

	if (isPreorder && !isPickup) {
		title = `Ваш заказ будет доставлен ${preorderAt.format('DD.MM.YY')} c ${preorderAt.format('H:mm')} до ${preorderAt.add(30, 'minutes').format('H:mm')}.`;
	}

	if (!isPreorder && isPickup) {
		if (order.additionalData.confirmedByOperator) {
			title = `Ждем Вас по адресу: ${address.street}, ${address.house} к ${order.shipping.timeDetails.deliveryAt}`;
		}
		else {
			title = `Ждем Вас по адресу: ${address.street}, ${address.house} через ${delay} минут`;
		}
	}

	if (!isPreorder && !isPickup) {
		if (order.additionalData.confirmedByOperator) {
			title = `Ваш заказ будет доставлен до ${order.shipping.timeDetails.deliveryAt}`;
		}
		else {
			title = `Ваш заказ будет доставлен в течение ${delay} минут`;
		}
	}

	return (
		<>
			<h1>Заказ {order.sync}.{order.num}</h1>
			<h2 style={{marginTop: '12px',}}>{order.customerInfo.name}, {order.customerInfo.phone}</h2>
			<h2 style={{color: '#637c6c', marginTop: '12px',}}>
				{title}
			</h2>

			{order.comment && (
				<div>
					<span>{order.additionalData.originalComment}</span>
				</div>
			)}

			<ul style={{borderCollapse: 'collapse'}}>
				{Object.values(order.items.toJSON()).map(i => i.toArray()).flat().map(([itemKey, item]) => {
					return (
						<li key={itemKey} className="cart-table__list-item">
							<div className="cart-table__content">
								<h3 className="cart-table__title">
									<a>{item.get('title')}</a>
								</h3>
								{item.get('type') === PRODUCT_TYPES.roll.id
									? (<span>{item.get('size') === ROLL_SIZES.medium.id ? 'половина' : 'целый'}</span>)
									: (<span>{item.get('announce')}</span>)}
							</div>

							<div className="cart-table-price">
								<div className="cart-table-quantity__numbers">
									<div className="cart-table-price__maincost">x{item.count}</div>
								</div>
							</div>

							<div className="cart-table-price">
								<div className="cart-table-price__inner">
									<div className="cart-table-price__maincost">{priceBYN(item.realizationPrice)}</div>
								</div>
							</div>
						</li>
					)
				})}
				{!!Number.parseInt(order.priceSpecification.deliveryCost) && (
					<li key="shipping" className="cart-table__list-item">
						<div className="cart-table__content">
							<h3 className="cart-table__title">
								<a>доставка</a>
							</h3>
						</div>
						&nbsp;
						<div/>

						<div className="cart-table-price">
							<div className="cart-table-price__inner">
								<div className="cart-table-price__maincost">{priceBYN(order.priceSpecification.deliveryCost)}</div>
							</div>
						</div>
					</li>
				)}
			</ul>

			<div className="cart-table-total">
				<div className="cart-table-total__content">
					<div className="cart-table-total__content-box">
						<span className="cart-table-total__label">итого:</span>
						<strong className="cart-table-total__price">{priceBYN(order.priceSpecification.orderTotal)}</strong>
					</div>
					<div className="cart-table-total__content-box">
						<span className="cart-table-total__label">оплата: </span>
						<span className="cart-table-total__label">{PAYMENT_METHOD[order.priceSpecification.paymentType]?.title}</span>
					</div>
					{order.payment === PAYMENT_METHOD.cash.id && order.prepareChangeFor && (
						<div className="cart-table-total__content-box">
							<span className="cart-table-total__label">сдача с:</span>
							<span className="cart-table-total__label">{order.priceSpecification.prepareChangeFor}</span>
						</div>
					)}
				</div>
			</div>
		</>
	)
};
