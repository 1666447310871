import React from "react";

import {DrinkListItem} from "./DrinkListItem";
import {sortByPosition} from "../../../utils/utils";


export const DrinkList = ({productList}) => (
	<ul className="additional-goods__list">
		{productList.toList().sort(sortByPosition).map(product => (
			<DrinkListItem drink={product} key={product.get('id')}/>
		))}
	</ul>
);
