import Cookies from "js-cookie";
import { call } from "redux-saga/effects";


export const delay = (ms = 1000) => new Promise(resolve => setTimeout(() => resolve(), ms));

export const toBool = data => data === 1 || data === '1' || data === true || data === 'true';

export const __ = (val, params = {}) => {
  if (val || params.hasOwnProperty('func')) {
    return params.hasOwnProperty('func') ? params.func(val) : `${val}`
  }
  else if (!params.hasOwnProperty('forceUndefined')) {
    return params.hasOwnProperty('defaultValue') ? params.defaultValue : ''
  }
  return undefined
};


export const p2c = (prop, ifTrue, ifFalse = '') => prop ? ifTrue ? ifTrue : prop : ifFalse;

export const dbgMode = process.env.DEBUG === '1' || toBool(Cookies.get('dbg'));

export const generateCalls = (config, functions, def = call) => {
  let calls = [];
	Object.entries(config).map(([key, value ]) => {
    if (value) calls.push(functions[ key ])
  });
  return calls.map(f => def(f));
};

export const prefix = `${Date.now()}${Math.random()}`;

console.dbg = function(...attr) {
  if (dbgMode) {
    console.log(...attr)
  }
};

console.dbgTrace = function(...attr) {
  if (dbgMode) {
    console.trace(...attr)
  }
};

export const trimLeadingUrlSlash = (url) => {
  var res = url;
  while(res.charAt(0) === '/')
  {
    res = res.substring(1);
  }

  return res;
};

export const sortByPosition = (a, b) => +a.get('position') > +b.get('position') ? 1 : -1;
