import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { closePopup } from "../../../common/popups/_core/popupActions";
import { productSelectors } from "../../_core/productSelectors";
import { popupSelector } from "../../../common/popups/_core/popupSelectors";
import { CircleBackground } from "../../../common/circleBackground/CircleBackground";
import { Image } from "../../../common/image/Image";
import { PRODUCT_TYPES, ROLL_SIZES } from "../../../../api/products/productBaseConstants";
import {RollPriceInfo} from "../RollPriceInfo";
import {RollProductQuantity} from "../RollProductQuantity";
import { ROUTES } from "../../../../system/router/routeConstants";
import {ProductCode} from "../../productCode/ProductCode";


export const RollPopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rollId = useSelector(popupSelector.props).rollId;
  const roll = useSelector(productSelectors[PRODUCT_TYPES.roll.id]).get(rollId);
  const availableSize = roll.hasMedium ? ROLL_SIZES.medium.id : ROLL_SIZES.big.id

  const handleClick = () => {
    history.push(ROUTES.main.path);
    dispatch(closePopup());
  };

  return (
    <div className="popup popup--product popup--show">

      <button
        onClick={handleClick}
        className="popup__close"
        type="button"
        title="закрыть">&times;
      </button>

      <div className="popup-outer">
        <div className="popup-product">
          <h1 className="popup-product__title">{<ProductCode code={roll.code}/>}{roll.title.toLowerCase()}</h1>

          <ul className="popup-product-types">
            <RollPriceInfo type="big" productType={roll.big}/>
            {roll.hasMedium && <RollPriceInfo type="medium" productType={roll.medium}/>}
          </ul>

          <Image className="popup-product__picture"
                 publicImages={roll.publicImages}
                 type="popup"
                 height={600} width={600}/>

          <RollProductQuantity product={roll} type={PRODUCT_TYPES.roll.id} size={availableSize}/>

          <article className="popup-product__chars">
            <p>{roll.get('announce')}</p>
          </article>
          <ul className="popup-product-ingredients">
            <li className="popup-product-ingredients__list-item">вес {roll.big.amount}</li>
            <li className="popup-product-ingredients__list-item">калории {roll.big.calories} ккал</li>
            <li className="popup-product-ingredients__list-item">углеводы {roll.big.carbohydrates} г</li>
            <li className="popup-product-ingredients__list-item">белки {roll.big.proteins} г</li>
            <li className="popup-product-ingredients__list-item">жиры {roll.big.fats} г</li>
          </ul>
        </div>
      </div>
      <CircleBackground className="hide-on-mobile"/>
    </div>
  );
};
