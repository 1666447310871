import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { applyDelivery } from "../../cart/_core/cartActions";
import { catalogSelectors } from "../../catalogs/_core/catalogSelectors";
import { Error } from "../../common/notifications/error/Error";
import { Warning } from "../../common/notifications/warning/Warning";

import "./styles/pickup-tab.css";


export const AddressPopupPickupTab = () => {
    const dispatch = useDispatch();
    const manufactures = useSelector(catalogSelectors.pickupManufactures);
    const handleManufactureClick = (manufactureId) => () => {
        dispatch(applyDelivery(manufactureId));
    };

    if (!manufactures.size) {
        return (
            <Error className="m-centered"
                   description="к сожалению, сейчас нет объектов доставки суши Годзилла, которые предоставляют самовывоз"/>
        )
    }

    return (
        <>
            <div className="popup-note">вы сможете забрать заказ по адресу:</div>
            <ul className="pickup-nav">
                {manufactures.valueSeq().toArray().map(manufacture =>
                    manufacture.status.error ? (
                        <li key={manufacture.get('id')}
                            className="pickup-nav__list-item">
                            <Error
                                className="m-centered"
                                style={{marginBottom: 0, padding: '5px 15px', borderRadius: '6px', maxWidth: '960px'}}
                                title={`${manufacture.get('street')}, ${manufacture.get('house')}`}
                                description={manufacture.status.error}
                            />
                        </li>
                    ) : (
                        <li key={manufacture.get('id')}
                            className="pickup-nav__list-item"
                            onClick={handleManufactureClick(manufacture.get('id'))}>

                            <div className="m-centered" style={{maxWidth: '960px'}}>
                                {manufacture.status.warning
                                    ? (
                                        <Warning style={{
                                            margin: '0 auto',
                                            borderRadius: '6px',
                                            lineHeight: '24px',
                                            padding: '10px 20px'
                                        }}>
                                            <div className="pickup-nav__item"
                                                 style={{fontSize: '25px', marginBottom: '10px', padding: '10px 20px'}}>
                                                {manufacture.get('street')}, {manufacture.get('house')}
                                            </div>
                                            <div>
                                                {manufacture.status.warning}
                                            </div>
                                        </Warning>
                                    )
                                    : (
                                        <>
                                            <div className="pickup-nav__item"
                                                 style={{fontSize: '25px', marginBottom: '10px', padding: '10px 20px'}}>
                                                {manufacture.get('street')}, {manufacture.get('house')}
                                            </div>
                                            <div>
                                                {manufacture.status.warning}
                                            </div>
                                        </>
                                    )
                                }

                            </div>
                        </li>
                    )
                )}
            </ul>
        </>
    )
};
