import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { productSelectors } from "../_core/productSelectors";
import { Anchor } from "../../common/Anchor";
import { RollList } from "./RollList";
import { SectionTitle } from "../../common/sectionTitle/SectionTitle";
import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";
import { closePopup, openPopup } from "../../common/popups/_core/popupActions";
import { POPUP_COMPONENTS } from "../../common/popups/popupsConfig";
import { pageSelectors } from "../../../pages/_core/pagesSelectors";
import { catalogSelectors } from "../../catalogs/_core/catalogSelectors";
import { SectionDescription } from "../../common/sectionDescription/SectionDescription";


export const RollSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const rollList = useSelector(productSelectors[PRODUCT_TYPES.roll.id]);
    const popup  = useSelector(pageSelectors.popup);

    useEffect(() => {
        if (history.location.pathname.includes('rolls-detail')) {
            const rollId = history.location.pathname.split('/')[2];
            dispatch(openPopup({
                type: POPUP_COMPONENTS.rollPopup.type,
                props: { rollId }
            }))
        } else {
            if (popup.type === POPUP_COMPONENTS.rollPopup.type) {
                dispatch(closePopup())
            }
        }
    }, [history.location.pathname])
    let sectionDescription = useSelector(catalogSelectors.rollSectionDescription)

    return (
        <div className="main-goods">
            <Anchor id="sushi" top={-25}/>

            <SectionTitle title="Суши"/>
            {sectionDescription && sectionDescription.description
                && <SectionDescription description={sectionDescription.description}/>}
            <RollList products={rollList}/>
        </div>
    );
}
