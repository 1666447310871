import { combineReducers } from "redux-immutable";

import { bepaidPageReducer } from "./bepaidPage/bepaidPageReducer";
import { cartPageReducer } from "./cartPage/cartPageReducer";
import { popupReducer } from "../../components/common/popups/_core/popupsReducer";


export const pagesReducer = combineReducers({
  bePaidPage: bepaidPageReducer,
  cartPage: cartPageReducer,
  popup: popupReducer,
});
