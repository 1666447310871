import { all, call, put } from "redux-saga/effects";

import { api } from "../../../api/api";
import { generateCalls } from "../../../utils/utils";
import { featureSagaCreator } from "../../../utils/featureSagaCreator";
import {
	loadedBentos,
	loadedDesserts,
	loadedDrinks,
	loadedFilters,
	loadedGarnishes,
	loadedIngredients,
	loadedPokes,
	loadedSoups,
	loadedRolls,
	loadedRollsets,
	loadedWarmers,
	loadedSuggestedProducts,
} from "./productAction";


function* loadRolls()
{
	const {result, error} = yield call(api.products.rolls);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedRolls(result))
	}
}

function* loadRollsets()
{
	const {result, error} = yield call(api.products.rollsets);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedRollsets(result))
	}
}

function* loadPokes()
{
  const {result, error} = yield call(api.products.pokes);
  if (error) {
    throw new Error(error);
  } else {
    yield put(loadedPokes(result))
  }
}

function* loadSoups()
{
	const {result, error} = yield call(api.products.soups);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedSoups(result))
	}
}

function* loadBentos()
{
  const {result, error} = yield call(api.products.bentos);
  if (error) {
    throw new Error(error);
  } else {
    yield put(loadedBentos(result))
  }
}

function* loadGarnishes()
{
	const {result, error} = yield call(api.products.garnishes);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedGarnishes(result))
	}
}

function* loadDrinks()
{
	const {error, result} = yield call(api.products.drinks);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedDrinks(result))
	}
}

function* loadDesserts()
{
	const {error, result} = yield call(api.products.desserts);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedDesserts(result))
	}
}

function* loadFilters()
{
	const {result, error} = yield call(api.products.filters);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedFilters(result))
	}
}

function* loadIngredients()
{
	const {result, error} = yield call(api.products.ingredients);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedIngredients(result))
	}
}

function* loadWarmers() {
	const {result, error} = yield call(api.products.warmers);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedWarmers(result))
	}
}

function* loadSuggestedProducts()
{
	const {result, error} = yield call(api.products.suggestedProducts);
	if (error) {
		throw new Error(error);
	} else {
		yield put(loadedSuggestedProducts(result))
	}
}

const loaderSettings = {
	rolls: true,
	rollsets: true,
	poke: true,
	soup: true,
	bento: false,
	garnishes: true,
	drinks: true,
	desserts: true,
	warmers: true,
	filters: false,
	ingredients: false,
	suggestedProducts: true,
};

export function* productLoader(settings = loaderSettings)
{
	settings = {...loaderSettings, ...settings};
	yield all(generateCalls(
		{
			loadRolls: settings.rolls,
			loadRollsets: settings.rollsets,
			loadPokes: settings.poke,
			loadSoups: settings.soup,
			loadBento: settings.bento,
			loadGarnishes: settings.garnishes,
			loadDrinks: settings.drinks,
			loadDesserts: settings.desserts,
			loadFilters: settings.filters,
			loadIngredients: settings.ingredients,
			loadWarmers: settings.warmers,
			loadSuggestedProducts: settings.suggestedProducts,
		},
		{
			loadRolls,
			loadRollsets,
            loadPokes,
			loadSoups,
            loadBentos,
			loadGarnishes,
			loadDrinks,
			loadDesserts,
			loadFilters,
			loadIngredients,
			loadWarmers,
			loadSuggestedProducts,
		},
	))
}

export const productSaga = featureSagaCreator({
	init: productLoader,
});
