import React from "react";
import { useSelector } from "react-redux";

import { priceBYN } from "../../../utils/formatHelpers";
import { cartSelectors } from "../../cart/_core/cartSelectors";
import { DrinkProductQuantity } from "./DrinkProductQuantity";
import { Image } from "../../common/image/Image";
import { titleFormatter } from "../../../utils/titleFormatter";


export const DrinkListItem = ({drink}) => {
    const cartItem = useSelector(state => cartSelectors.cartItem(state, drink));
    const productInCart = cartItem.count ? 'incart' : 'none';

    return (
        <li className="additional-goods__list-item" data-item={productInCart}>
            <div className="additional-goods__item">

                <Image publicImages={drink.publicImages} type="main" width={100} height={100}
                       className="additional-goods__picture"/>

                <h3 className="additional-goods__title">
          <span>
              {drink.ageLimit
                ? `${drink.ageLimit}+ `
                : ''}
              {titleFormatter(drink.title)}
          </span>
                    {drink.producerCountry ? <p>{drink.producerCountry}</p> : ''}
                </h3>
                <p className="additional-goods__size">{drink.announce}</p>
                <p className="additional-goods__price">{priceBYN(drink.price)}</p>

                <DrinkProductQuantity product={drink}/>
            </div>
        </li>
    );
};
