import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { changePopupProps } from "../../../../common/popups/_core/popupActions";
import { customerSelectors } from "../../../../customer/_core/customerSelectors";
import { ADDRESS_POPUP_FORMS } from "../../addressPopupConstants";


export const AddressPopupHeader = () => {
  const dispatch = useDispatch();
  const customerInfo = useSelector(customerSelectors.customerInfo);

  const handleChangePassword = (e) => {
    e.preventDefault();
    dispatch(changePopupProps({
      myAddressForm: ADDRESS_POPUP_FORMS.changePassword
      }
    ));
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(changePopupProps({
        myAddressForm: ADDRESS_POPUP_FORMS.logout
      }
    ));
  };

  return (
    <ul className="user-toolbar">
      <li className="user-toolbar__list-item" data-type="login-info">{customerInfo.get('phone')}</li>
      <li className="user-toolbar__list-item">
        <a href="" onClick={handleChangePassword}>сменить пароль</a>
      </li>
      <li className="user-toolbar__list-item" data-type="exit">
        <a href="" onClick={handleLogout}>выход</a>
      </li>
    </ul>
  )
};
