import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import plural from "plural-ru";

import { cartProcessedWithError, cartProcessingApproved } from "../_core/cartActions";
import { cartSelectors } from "../_core/cartSelectors";
import { closePopup } from "../../common/popups/_core/popupActions";
import { CircleBackground } from "../../common/circleBackground/CircleBackground";
import { SectionTitle } from "../../common/sectionTitle/SectionTitle";

import './CartConfirmPopup.css';


export const CartConfirmPopup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {freeItemsRemaining} = useSelector(cartSelectors.additionalData);

    const approveCartProcessing = () => {
        dispatch(cartProcessingApproved());
        dispatch(closePopup());
    }

    const close = () => {
        dispatch(cartProcessedWithError());
        dispatch(closePopup());
    }

    const closeCartConfirmingPopup = () => {
        history.push('/cart#garnish');
        close();
    }

    return (
        <div className="popup popup--address popup--show">
            <button className="popup__close" type="button" title="Закрыть" onClick={close}>&times;</button>
            <div className="cart-confirm-popup-layout">

                <div className="cart-confirm-popup">
                    <div className="cart-confirm-popup__title" >
                        <SectionTitle title="оформление заказа" />
                    </div>
                    <div className="cart-confirm-popup__description" >
                        <span>
                            Вы можете добавить к заказу еще {freeItemsRemaining.get('garnish')}&nbsp;
                            {plural(freeItemsRemaining.get('garnish'), 'гарнир', 'гарнира', 'гарниров')} за 1&nbsp;копейку.
                            Сейчас в Вашем заказе нет ни одного гарнира. Завершить оформление заказа?
                        </span>
                    </div>

                    <div className="cart-confirm-popup__buttons-section">
                        <div className="cart-confirm-popup__decline-button"
                                onClick={closeCartConfirmingPopup}>вернуться к гарнирам
                        </div>

                        <button className="cart-confirm-popup__confirm-buttom"
                                onClick={approveCartProcessing}>завершить оформление
                        </button>
                    </div>
                </div>

            </div>
            <CircleBackground/>
        </div>
    )

}
