export const scrollToAnchor = (hash) => {
    if (!hash) return;
    setTimeout(
        () => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        },
        300
    );
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
}
