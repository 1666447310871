import { Record } from "immutable";


const PaymentModelRecord = Record({
  id: null,
  link: null,
  position: 0,
  publicImages: [],
});

export class PaymentModel extends PaymentModelRecord {
  //
}
