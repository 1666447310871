import React from "react";

export const isManufactureOnStop = (currentManufacture) => {
  return currentManufacture.manufactureId && currentManufacture.error;
};

export const ManufactureOnStopError = ({ style, currentManufacture: currentManufacture }) => {
  if (!currentManufacture) {
    return null;
  }
  return (
    <>
      {isManufactureOnStop(currentManufacture) && (
        <p className="form-note" style={style}>Доставка по данному адресу временно не осуществляется в связи с
          максимальной загрузкой доставки суши Годзилла. Приносим свои извинения. Попробуйте совершить заказ через 15
          минут.
          Предварительный заказ можно осуществить через оператора колл-центра по телефону 604-00-00 (
          <span><a href="tel:+375296040000">А1</a>, </span>
          <span><a href="tel:+375256040000">мтс</a>, </span>
          <span><a href="tel:+375336040000">лайф</a></span>
          ).
        </p>
      )}
    </> )
};
