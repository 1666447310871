import React from "react";
import {useSelector} from "react-redux";

import {SectionTitle} from "../components/common/sectionTitle/SectionTitle";
import {settingSelectors} from "../components/settings/_core/settingSelectors";
import {scrollToTop} from "../utils/scrollToAnchor";

export const AudioAndVideoPolicyPage = () => {
	const content = useSelector(settingSelectors.content);
	scrollToTop()

	return (
		<div className="content-page">
			<SectionTitle title="Политика аудио и видеонаблюдения"/>
			<div dangerouslySetInnerHTML={{__html: content.audio_and_video_policy}}/>
			<br/>
		</div>
	)
};
