import React from "react";

export const Button = ({ onClick, disabled, className, children, type = 'submit' }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`btn ${className}`}
      type={type}>{children}
    </button>
  )
};
