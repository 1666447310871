import React from "react";
import { useSelector } from "react-redux";

import {cartSelectors} from "../../_core/cartSelectors";


export const IsPaymentDeliveryWarning = () => {
  const deliveryPrice = useSelector(cartSelectors.priceSpecification).deliveryPrice;

  return (
    <>
      {deliveryPrice && (
        <p className="form-note">
          время доставки по этому адресу увеличено на 30 минут в связи с тем, что адрес не
          относится к зоне бесплатной доставки
        </p>
      )}
    </>
  )
};
