import {
  ADD_FOCUS_TO_POPUP,
  CHANGE_POPUP_PROPS,
  CLOSE_POPUP,
  OPEN_POPUP,
  REMOVE_FOCUS_FROM_POPUP
} from "./popupConstants";


const initialState = {
  type: null,
  inFocus: false,
  props: {}
};

export const popupReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_POPUP:
      return {
        type: action.payload.type,
        inFocus: true,
        props: action.payload.props || {}
      };

    case CLOSE_POPUP:
      return initialState;

		case CHANGE_POPUP_PROPS:
      return {
        ...state,
        props: {
          ...state.props,
          ...action.payload
        }
      };

		case REMOVE_FOCUS_FROM_POPUP:
      return {
        ...state,
        inFocus: false,
      };

    case ADD_FOCUS_TO_POPUP:
      return {
        ...state,
        inFocus: true,
      };

    default:
      return state
  }
};
