import React from 'react';

import { sortByPosition } from "../../../utils/utils";
import { WarmerListItem } from "./WarmerListItem";


export const WarmerList = ({products}) => {
    return (
        <ul className="additional-goods__list">
            {products.toList().sort(sortByPosition).map(product => (
                <WarmerListItem warmer={product} key={product.id}/>
            ))}
        </ul>
    )
}
