import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addressFormModified } from "../../../../pages/_core/cartPage/cartPageActions";
import { cartPageSelectors } from "../../../../pages/_core/cartPage/cartPageSelectors";
import { cartSelectors } from "../../_core/cartSelectors";


export const AddressAdditionalInput = ({ title, value, propName, dispatchMethod }) => {
  const [ val, setVal ] = useState(value);
  const isAddressFormModified = useSelector(cartPageSelectors.addressFormModified);
  const dispatch = useDispatch();
  const isPickup = useSelector(cartSelectors.isPickup);

  useEffect(() => {
    setVal(value);
  }, [ value ]);

  const handleBlur = (e) => {
    dispatch(dispatchMethod({
      [propName]: e.target.value
    }))
	};

  const handleChange = (e) => {
    if (!isAddressFormModified) {
      dispatch(addressFormModified());
    }
    setVal(e.target.value)
  };

	return (
		<div className="form-grid__cell form-grid__cell--equal">
			<div className="form-label">{title}</div>
      <input
        className="form-input form-size-full"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        value={val}
        disabled={isPickup}
      />
    </div>
  )
};
