import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';

import { updateCartPreorder } from "../../../_core/cartActions";
import { cartSelectors } from "../../../_core/cartSelectors";
import { PickupInput } from "../PickupInput";
import { nearestMinutes } from "../../../../../utils/momentUtils";
import { CART_SHIPPING_TYPES } from "../../../_core/cartConstants";
import { preorderSelectors } from "../../../../preorder/_core/preorderSelectors";
import { fetchPreorderIntervals } from "../../../../preorder/_core/preorderActions";
import { settingSelectors } from "../../../../settings/_core/settingSelectors";
import { Error } from "../../../../common/notifications/error/Error";
import {
	getMaxDate,
	getMaxTimeOfDay,
	getMinDate,
	getMinTimeOfDay,
	getFirstAvailableDayTime
} from "./components/preorderHelperFunctions";
import { CustomPreorderInput } from "./components/CustomPreorderInput";
import "react-datepicker/dist/react-datepicker.css";
import "./preorderInput.css";


registerLocale('ru', ru);


const BACKEND_PREORDER_DATETIME_FORMAT = 'YYYY-MM-DD H:mm:ss';


export const PreorderInput = ({deliveryTimeSelected, setDeliveryTime}) => {
	const dispatch = useDispatch();

	const priceSpecification = useSelector(cartSelectors.priceSpecification) || {};
	const shipping = useSelector(cartSelectors.shipping);
	const isPreorder = useSelector(cartSelectors.isPreorder);
	const cartSettings = useSelector(cartSelectors.settings);
	const preorderIntervals = useSelector(preorderSelectors.intervals);
	const isDelivery = shipping.shippingType === CART_SHIPPING_TYPES.delivery;
	const isPickup = shipping.shippingType === CART_SHIPPING_TYPES.pickup;
	const settings = useSelector(settingSelectors.settings);
	const discountMisdirection =
		(cartSettings.runtimeSushiDiscount20 && 1) + (cartSettings.selectedSushiDiscount20 && -1);
	const [shouldDisableNearestTimeOrder, setShouldDisableNearestTimeOrder] = useState(false);


	const availableFirstDateTime = preorderIntervals ?
		getFirstAvailableDayTime(preorderIntervals)
		: new Date(nearestMinutes(15, moment().add(1, 'hours')).format())

	const dayTimeString = shipping.timeDetails.date
		? `${shipping.timeDetails.date}T${shipping.timeDetails.time}:00`
		: availableFirstDateTime.toString();

	const disabledDates = isDelivery
			? preorderIntervals?.data?.disabledDeliveryDays || []
			: preorderIntervals?.data?.disabledPickupDays || [];

	const disabledTimes = isDelivery
		? preorderIntervals?.data[shipping.timeDetails.date]?.disabledDeliveryHours || []
		: preorderIntervals?.data[shipping.timeDetails.date]?.disabledPickupHours || []

	const [state, setState] = useState({

		preorderTabIsOpen: isPreorder,
		date: new Date(dayTimeString),
		minDate: getMinDate(preorderIntervals),
		maxDate: getMaxDate(preorderIntervals),
		minTime: getMinTimeOfDay(dayTimeString, preorderIntervals),
		maxTime: getMaxTimeOfDay(dayTimeString, preorderIntervals),
	});

	useEffect(() => {
		dispatch(fetchPreorderIntervals());
	}, [])

	useEffect(() => {
		setState({
			...state,
			defaultDateTimeString: getMinTimeOfDay(dayTimeString, preorderIntervals),
			minDate: getMinDate(preorderIntervals),
			maxDate: getMaxDate(preorderIntervals),
			minTime: getMinTimeOfDay(dayTimeString, preorderIntervals),
			maxTime: getMaxTimeOfDay(dayTimeString, preorderIntervals),
		})
	}, [preorderIntervals])

	useEffect(() => {
		shouldDisableNearestTimeOrder && setDeliveryTime(false);
		setShouldDisableNearestTimeOrder(false);
		setState({
			...state,
			date: new Date(dayTimeString),
			preorderTabIsOpen: isPreorder
		});

	}, [isPreorder, shipping])

	const updateIntervalsTitleWithEndTime = () => {
		if (!isDelivery) return;

		setTimeout(() => {
			const options = document.getElementsByClassName('react-datepicker__time-list-item');
			if (options[0]?.innerHTML?.length && options[0]?.innerHTML?.length < 6) {
				Array.prototype.map.call(options,
					(item => {
						item.innerText = item.innerText + '–' + moment()
							.hours(+item.innerText.split(':')[0])
							.minute(+item.innerText.split(':')[1])
							.add(30, 'minutes')
							.format('HH:mm');
					})
				);
			}
		}, 100)
	}
	const updateCartPreorderData = (time) => {
		const [date, hours, minutes] = moment(time).format('YYYY-MM-DD HH mm').split(' ');
		dispatch(updateCartPreorder({
			preorder_datetime: time ? moment(time).format(BACKEND_PREORDER_DATETIME_FORMAT) : '',
			protection: preorderIntervals?.data?.[date]?.[hours]?.[minutes]?.[isDelivery ? 'delivery' : 'pickup'],
			protected_at: preorderIntervals?.protectedAt,
		}));
	}

	const openTab = (e) => {
		e.preventDefault();
		setShouldDisableNearestTimeOrder(true);
		updateCartPreorderData(state.defaultDateTimeString)
	};

	const closeTab = (e) => {
		e?.preventDefault();
		updateCartPreorderData('');
	};

	const handlePreorderTimeChange = (time) => {
		setState({
			...state,
			date: time,
		});
		updateCartPreorderData(time);
	};

	const handleDaySelect = (dateTime) => {
		setState({
			...state,
			minTime: getMinTimeOfDay(dateTime, preorderIntervals),
			maxTime: getMaxTimeOfDay(dateTime, preorderIntervals),
		})
	};

	const handleOnNearestTimeOrderClick = () => {
		closeTab();
		setDeliveryTime(true);
		updateCartPreorderData('');
	}
	let btnPreorderTitle = 'предварительный заказ'
	let preorderDescription = 'оформление заказа на конкретное время.'
	if (isPickup && cartSettings.isActivePreorderPickupDiscount) {
		btnPreorderTitle = 'предварительный заказ –10%'
		preorderDescription = 'оформление заказа на конкретное время. При предварительном заказе на самовывоз действует скидка 10% на заказ'
	}

	return (
		<div className="form-group">
			<PickupInput setDeliveryTime={setDeliveryTime} />

			{ (!deliveryTimeSelected && !isPreorder && isDelivery) ?
				(<Error descriptionClassName="fs-m"
						description="выберите время доставки"
						className="p-l mb-s"/>)	: null
			}

			<div style={{display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap'}} >
				{ isDelivery && (
					<div className={`cart_form-preorder-button current ${deliveryTimeSelected && "active"}`} onClick={handleOnNearestTimeOrderClick}>
						в течение {settings.delivery45 ? '45' : '60'}&nbsp;минут
					</div>
				)}
				<div className="form-settime-action" onClick={openTab}>
					<div className={`cart_form-preorder-button ${isPreorder && "active"}`}>
						{btnPreorderTitle}
					</div>
					<div className="preorder-description" >
						{preorderDescription}
					</div>
				</div>
			</div>
			{state.preorderTabIsOpen && (
				<div className="form-preorder form-preorder--active" style={{marginTop: '15px'}}>
					<button className="form-preorder__cancel" type="button" onClick={closeTab}>
						отменить
					</button>

					{/*{isDelivery*/}
					{/*	? (*/}
					{/*		<p>*/}
					{/*			Предварительный заказ будет доставлен в выбранный Вами*/}
					{/*			получасовой интервал. Если мы не доставим заказ вовремя,*/}
					{/*			то Вы получите самое дорогое блюдо из заказа за одну копейку.*/}
					{/*		</p>*/}
					{/*	)*/}
					{/*	: (*/}
					{/*		<p>*/}
					{/*			Если заказ на самовывоз не будет готов к выбранному времени,<br/>*/}
					{/*			то Вы получите самое дорогое блюдо из заказа за одну копейку.*/}
					{/*		</p>*/}
					{/*	)*/}
					{/*}*/}


					<div className="form-group form-required">
						<p>предзаказ на:</p>
						<DatePicker
							onCalendarOpen={updateIntervalsTitleWithEndTime}
							className="form-input form-preorder-input"
							selected={state.date}
							onSelect={handleDaySelect}
							onChange={handlePreorderTimeChange}
							showTimeSelect
							locale="ru"
							timeIntervals={15}
							minDate={state.minDate}
							maxDate={new Date(moment().add(5, 'days').format())}
							minTime={state.minTime}
							maxTime={state.maxTime}
							dateFormat="dd.MM.yyyy HH:mm"
							timeCaption="время"
							showDisabledMonthNavigation
							customInput={<CustomPreorderInput isDelivery={isDelivery}/>}
							excludeDates={disabledDates}
							excludeTimes={disabledTimes}
						/>
					</div>
					<p className="c-brand">
						Предварительный заказ можно оформить на удобное для Вас время.<br/>
						Предварительный заказ должен быть оформлен минимум за 1 час до времени получения.
					</p>

					{!!discountMisdirection && (
						discountMisdirection > 0
							? (
								<div className="error-notification m-centered form-group">
									<p>
										В выбранное Вами время предварительного заказа скидка <strong>не действует</strong>.
									</p>
									<p className="error-description">
										Скидка 20% на все суши предоставляется с 00:00 воскресенья по 02:00 пятницы (кроме праздничных и предпраздничных дней).
									</p>
								</div>)
							: (
								<div className="error-notification m-centered form-group">
									<p>
										В выбранное Вами время предварительного заказа скидка <strong>действует и учтена</strong>.
									</p>
									<p className="error-description">
										Скидка 20% на все суши предоставляется с 00:00 воскресенья по 02:00 пятницы (кроме праздничных и предпраздничных дней).
									</p>
								</div>
								)
						)
					}
				</div>
			)}
		</div>
	);
}
