import React from "react";
import {
  fqApiBypassHost,
  fqApiProxyHost,
  fqStaticDataUrl,
  isBypassEnabled,
  staticDataHostEnabled
} from "../../../utils/http";

import "./image.css";


export const Image = ({ publicImages, type = 'main', width, height, onClick, style, imageType, className = 'image-default', alwaysMax = false }) => {
  const imageUrlByApi = isBypassEnabled ? `${fqApiBypassHost}` : `${fqApiProxyHost}/public`;
  const imageUrl = staticDataHostEnabled ? `${fqStaticDataUrl}` : `${imageUrlByApi}`;
  const pixelRatio = devicePixelRatio || 1;
  const [ src1x, src2x ] = [ `${type}_1x`, `${type}_2x` ];

  let src = '';
  if (alwaysMax) {
    src = publicImages?.[src2x].mocked ?
        `${imageUrl}${publicImages?.[src1x].src}` :
        `${imageUrl}${publicImages?.[src2x].src}`;
  } else if (type === 'popup') {
    src = publicImages?.[src2x].mocked ?
      `${imageUrl}${publicImages?.[src1x].src}` :
      `${imageUrl}${publicImages?.[src2x].src}`;
  }
  else {
		if (pixelRatio > 1 && !publicImages?.[src2x]?.mocked) {
			src = `${imageUrl}${publicImages?.[src2x]?.src}`;
		}
    else {
      src = `${imageUrl}${publicImages?.[src1x].src}`;
    }
  }

  return (
    <div className={className} onClick={onClick} style={style} image-type={imageType}>
      <img src={src} alt="" width={width} height={height}/>
    </div>
  )
};
