import { createAction } from "@reduxjs/toolkit";

import {
  LOADED_BENTOS,
  LOADED_DESSERTS,
  LOADED_DRINKS,
  LOADED_FILTERS,
  LOADED_GARNISHES,
  LOADED_INGREDIENTS,
  LOADED_POKES,
  LOADED_SOUPS,
  LOADED_ROLLS,
  LOADED_ROLLSETS,
  LOADED_WARMERS,
  LOADED_SUGGESTED_PRODUCTS,
} from "./productConstants";


export const loadedRolls = createAction(LOADED_ROLLS);
export const loadedRollsets = createAction(LOADED_ROLLSETS);
export const loadedPokes = createAction(LOADED_POKES);
export const loadedSoups = createAction(LOADED_SOUPS);
export const loadedBentos = createAction(LOADED_BENTOS);
export const loadedGarnishes = createAction(LOADED_GARNISHES);
export const loadedDrinks = createAction(LOADED_DRINKS);
export const loadedDesserts = createAction(LOADED_DESSERTS);
export const loadedWarmers = createAction(LOADED_WARMERS);

export const loadedFilters = createAction(LOADED_FILTERS);
export const loadedIngredients = createAction(LOADED_INGREDIENTS);
export const loadedSuggestedProducts = createAction(LOADED_SUGGESTED_PRODUCTS);
