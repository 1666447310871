import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { settingSelectors } from "../../../settings/_core/settingSelectors";
import {
	DefaultNotAvailableForAddressComponent,
	DefaultNotInStockComponent,
	ProductQuantity
} from "../../productQuantity/ProductQuantity";


const SousProductComponent = ({ product, handleRemoveItem, handleAddItem, count }) => {
  const settings = useSelector(settingSelectors.settings);
  const location = useLocation().pathname;

  if (location !== '/cart' && settings.baskedDisabled) {
    return null;
  }

	if (!product.isOnTrade) {
		return (<DefaultNotInStockComponent />);
	}

	if (!product.isSuitableForCurrentCart) {
		return (<DefaultNotAvailableForAddressComponent />);
	}

	return (
		<div className="secondary-goods-quantity">
			<button className="secondary-goods-quantity__controls"
							data-button-type="minus"
							onClick={handleRemoveItem}>
				–</button>
			<strong className="secondary-goods-quantity__numbers">{count}</strong>
			<button className="secondary-goods-quantity__controls"
							data-button-type="plus"
							onClick={handleAddItem}
			>+</button>
		</div>
	)
};

export const SauceProductQuantity = (props) => {
	return (
		<ProductQuantity {...props}>
			<SousProductComponent product={props.product}/>
		</ProductQuantity>
	)
};
