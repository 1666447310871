import React from "react";

import "./circle.css"

export class CircleBackground extends React.Component {

  render()
  {
    return (
      <div className={`circle ${this.props.className}`}>
        <div className="circle__inner">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.79 204.79" width="0" height="0">
            <g>
              <g>
                                <path
                                    d="M102.39 204.79a102.4 102.4 0 11102.4-102.4 102.51 102.51 0 01-102.4 102.4zm0-202.79a100.4 100.4 0 10100.4 100.39A100.51 100.51 0 00102.39 2z"/>
                                <path
                                    d="M102.41 195.23a92.84 92.84 0 1192.83-92.84 92.95 92.95 0 01-92.83 92.84zm0-183.34a90.5 90.5 0 1090.49 90.5 90.6 90.6 0 00-90.49-90.5z"/>
                                <path
                                    d="M102.42 185.67a83.28 83.28 0 1183.27-83.28 83.37 83.37 0 01-83.27 83.28zm0-163.88a80.61 80.61 0 1080.6 80.6 80.7 80.7 0 00-80.6-80.6z"/>
                                <path
                                    d="M102.43 176.1a73.71 73.71 0 1173.71-73.71 73.79 73.79 0 01-73.71 73.71zm0-144.42a70.71 70.71 0 1070.71 70.71 70.79 70.79 0 00-70.71-70.71z"/>
                                <path
                                    d="M102.44 166.54a64.15 64.15 0 1164.15-64.15 64.22 64.22 0 01-64.15 64.15zm0-125a60.82 60.82 0 1060.82 60.82 60.9 60.9 0 00-60.82-60.79z"/>
                                <path
                                    d="M102.45 157a54.59 54.59 0 1154.6-54.59 54.65 54.65 0 01-54.6 54.59zm0-105.51a50.93 50.93 0 1050.93 50.92 51 51 0 00-50.93-50.94z"/>
                                <path
                                    d="M102.47 147.42a45 45 0 1145-45 45.08 45.08 0 01-45 45zm0-86.06a41 41 0 1041 41 41.08 41.08 0 00-41-41z"/>
                                <path
                                    d="M102.48 137.86A35.47 35.47 0 11138 102.39a35.51 35.51 0 01-35.52 35.47zm0-66.6a31.14 31.14 0 1031.14 31.13 31.17 31.17 0 00-31.14-31.13z"/>
                                <path
                                    d="M102.49 128.3a25.91 25.91 0 1125.91-25.91 25.94 25.94 0 01-25.91 25.91zm0-47.15a21.25 21.25 0 1021.24 21.24 21.27 21.27 0 00-21.24-21.24z"/>
                                <path
                                    d="M102.5 118.74a16.35 16.35 0 1116.35-16.35 16.37 16.37 0 01-16.35 16.35zm0-27.7a11.35 11.35 0 1011.35 11.35A11.37 11.37 0 00102.5 91z"/>
                            </g>
                        </g>
                    </svg>

                </div>
            </div>
        )
    }
}
