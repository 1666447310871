import React, { useState } from "react";

import { FeedbackList } from "./FeedbackList";


export const FeedbackSection = ({section, hash, cartTroubles, toggleTrouble, internal}) => {
    const [isSectionOpen, setIsSectionOpen] = useState(false);

    const toggleSection = () => setIsSectionOpen(!isSectionOpen);

    return (
        <div className="feedback-section" >
            <div onClick={toggleSection} className={`feedback-section-title ${internal ? 'internal' : ''}`}>
                {section.title}
            </div>

            {isSectionOpen && (
                <>
                    {section.list && (
                        <FeedbackList list={section.list} cartTroubles={cartTroubles} toggleTrouble={toggleTrouble} />
                    )}
                    {section.sections && section.sections.map((j, jhash) => (
                        <FeedbackSection section={j} key={jhash} cartTroubles={cartTroubles} toggleTrouble={toggleTrouble} internal/>
                    ))}
                </>
            )}
        </div>
    )
}

