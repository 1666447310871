import React from "react";

import { CookieListItem } from "./CookieListItem";
import { sortByPosition } from "../../../utils/utils";


export const CookieList = ({ productList, freeCookieCount }) => {

  return (
      <ul className="secondary-goods__list cart-cookies-section-list">
        {productList.toList().sort(sortByPosition).map(dessert => (
            <CookieListItem desert={dessert} key={dessert.id} freeCookieCount={freeCookieCount}/>
        ))}
      </ul>
  );
}
