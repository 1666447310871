import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {addFocusToPopup, removeFocusFromPopup} from "../../common/popups/_core/popupActions";
import {customerSelectors} from "../../customer/_core/customerSelectors";
import {popupSelector} from "../../common/popups/_core/popupSelectors";
import {setCartAddress} from "../../cart/_core/cartActions";
import {AddressHouseSelectInput} from "../addressFormComponents/AddressHouseSelectInput";
import {AddressStreetSelectInput} from "../addressFormComponents/AddressStreetSelectInput";
import {Error} from "../../common/notifications/error/Error";
import {Warning} from "../../common/notifications/warning/Warning";
import {Button} from "../../common/button/Button";
import {cartSelectors} from "../../cart/_core/cartSelectors";
import {resolveManufacture} from "../../catalogs/_core/catalogAction";


export const AddressPopupDeliveryTab = () => {
	const dispatch = useDispatch();

	const address = useSelector(cartSelectors.address);
	const popupProps = useSelector(popupSelector.props);
	const addressError = useSelector(customerSelectors.addressError);

	const [street, setStreet] = useState({
		value: address?.streetId,
		label: address?.street,
	});

	const [house, setHouse] = useState({
		value: address?.houseId,
		label: address?.house,
	});

	const handleSubmit = () => {
		if (!popupProps.currentManufacture?.error) {
			dispatch(setCartAddress({
				street: street.label,
				street_id: street.value,
				house: house.label,
				house_id: house.value,
			}));
		}
	};

	const onSelectOpen = () => {
		dispatch(removeFocusFromPopup());
	};

	const onSelectClose = () => {
		dispatch(addFocusToPopup());
	};

	const handleStreetChange = (newValue) => {
		setStreet(newValue);
		setHouse({
			value: null,
			label: null,
		})
	}

	const handleHouseChange = (newValue) => {
		setHouse(newValue);
		dispatch(resolveManufacture(newValue.value));
	}

	return (
		<>
			<div className="popup-note">пожалуйста, укажите адрес, куда доставить суши:</div>
			<div className="popup-form">

				<div className="form-group">
					<div className="form-label">улица</div>
					<div className="form-select">
						<AddressStreetSelectInput
							onMenuOpen={onSelectOpen}
							onMenuClose={onSelectClose}
							address={address}
							onChange={handleStreetChange}/>
					</div>
				</div>

				<div className="form-group">
					<div className="form-label">дом</div>
					<div className="form-select form-size-house">
						<AddressHouseSelectInput
							value={house}
							onChange={handleHouseChange}
							disabled={!street?.value}
						/>
					</div>
				</div>

				{(popupProps.currentManufacture?.error || addressError) && (
					<Error description={popupProps.currentManufacture?.error || addressError} className='m-centered'/>
				)}

				{popupProps.currentManufacture?.warning && (
					<Warning description={popupProps.currentManufacture?.warning} className="m-centered"/>
				)}

				{house.publicComment && (
					<Warning description={house.publicComment} className="m-centered"/>
				)}

				{house.toEntrance && (
					<Warning description="по данному адресу доставка осуществляется только до центрального входа" className="m-centered"/>
				)}

				{house.isDelayIncreased && (
					<Warning description="isDelayIncreased" className="m-centered"/>
				)}

				{house && (
					<div className="popup-form-button">
						<Button className="btn-primary"
										onClick={handleSubmit}
										disabled={
											!popupProps.currentManufacture?.id ||
											!house.value ||
											popupProps.currentManufacture?.error ||
											addressError}>
							подтвердить
						</Button>
					</div>
				)}
			</div>

			<div className="popup-footer-note">если вашего адреса нет в списке, но он относится к зоне бесплатной
				доставки,
				сообщите об этом оператору и совершите заказ по телефону<br/>
				<p>
					<a href="tel:+375256040000" data-type="tel">+375 29 604-00-00</a>
					<span> </span>
					<a href="tel:+375296040000" data-type="tel">+375 25 604-00-00</a>
					<span> </span>
					<a href="tel:+375336040000" data-type="tel">+375 33 604-00-00</a>
				</p>
			</div>
		</>
	)
};
