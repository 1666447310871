import React from "react";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";


export const CartPokeProductList = ({ poke, subtype }) => {
  return (
    <>
      {poke.valueSeq().map(pokeItem => {
        return (
          <CartListItem key={`poke_${pokeItem.get('id')}`}
                        item={pokeItem}
						            hideAnnounce
                        amount={poke.amount}
                        type={PRODUCT_TYPES.poke.id}
						subtype={subtype}
					/>
				)
			})}
		</>
	)
};
