import { Record } from "immutable";


const BlockProductModelRecord = Record({
  id: 0,
  active: 0,
  description: '',
  systemName: '',
});

export default class BlockProductModel extends BlockProductModelRecord {
  //
}
