const decorator = str => 'catalogs/' + str;

export const MANUFACTURES_LOADED = decorator('MANUFACTURES_LOADED');
export const RESOLVE_MANUFACTURE = decorator('RESOLVE_MANUFACTURE');

export const STREETS_LOADED = decorator('STREETS_LOADED');
export const VACANCIES_LOADED = decorator('VACANCIES_LOADED');
export const BLOCK_PRODUCTS_LOADED = decorator('BLOCK_PRODUCTS_LOADED');

export const LOAD_HOUSES = decorator('LOAD_HOUSES');
export const HOUSES_LOADED = decorator('HOUSES_LOADED');
