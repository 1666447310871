import React from "react";
import { useDispatch } from "react-redux";

import { logout } from "../../../customer/_core/customerActions";
import { changePopupProps } from "../../../common/popups/_core/popupActions";
import { ADDRESS_POPUP_FORMS } from "../addressPopupConstants";


export const AddressPopupLogoutForm = () => {
	const dispatch = useDispatch();

	const handleLogout = () => {
    dispatch(logout());
  };

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(changePopupProps({
      myAddressForm: ADDRESS_POPUP_FORMS.selectAddress
    }))
  };

	return (
		<div className="popup-form">
			<div className="user-toolbar">
				вы действительно хотите выйти?
			</div>

			<div>
				<div className="popup-form-button">
					<button className="btn btn-small btn-primary"
							onClick={handleLogout}
							type="submit">
            выйти
          </button>
        </div>
        <div className="form-footer-action">
          <a href="#" onClick={handleCancel}>отмена</a>
        </div>
      </div>

    </div>
  )
};
