import { CS } from "../../../utils/modules/reselect";
import { pageSelectors } from "../pagesSelectors";


export const cartPageSelectors = {
  addressFormModified: CS(pageSelectors.cartPage, shared => shared.get('addressFormModified')),
  isOrderSubmitted: CS(pageSelectors.cartPage, shared => shared.get('isOrderSubmitted')),
  isOrderProcessing: CS(pageSelectors.cartPage, shared => shared.get('isOrderProcessing')),
  orderError: CS(pageSelectors.cartPage, shared => shared.get('orderError')),
};
