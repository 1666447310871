import { Record } from "immutable";

import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";


const SticksModelRecord = Record({
    amount: '',
    id: '',
    position: '',
    title: '',
    announce: '',
    price: 0,
    subtype: PRODUCT_TYPES.garnish.subtypes.stick.id,
    type: PRODUCT_TYPES.garnish.id,
    publicImages: [],

    isOnTrade: null,
    isSuitableForCurrentCart: null,
});

export class SticksModel extends SticksModelRecord {
    //
}
