import React from "react";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";


export const CartDessertProductList = ({ dessert, subtype }) => {
  return (
    <>
      {dessert.valueSeq().map(dessertItem => {
        return (
          <CartListItem key={`dessert_${dessertItem.get('id')}`}
                        item={dessertItem}
						            hideAnnounce
                        type={PRODUCT_TYPES.dessert.id}
						subtype={subtype}
					/>
				)
			})}
		</>
	)
};
