import React from "react";

import {
  ProductQuantity
} from "../productQuantity/ProductQuantity";
import { RollProductComponent } from "../rolls/RollProductQuantity";


export const SoupProductQuantity = (props) => {
	return (
		<ProductQuantity {...props}>
			<RollProductComponent product={props.product}/>
		</ProductQuantity>
	)
};
