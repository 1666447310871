import { createAction } from "@reduxjs/toolkit";

import { ANSWERS_LOADED, CONTENT_LOADED, PAYMENTS_LOADED, SETTING_LOADED, SLIDES_LOADED } from "./settingConstants";


export const loadedSettings = createAction(SETTING_LOADED);
export const loadedSlides = createAction(SLIDES_LOADED);
export const loadedContent = createAction(CONTENT_LOADED);
export const loadedPayments = createAction(PAYMENTS_LOADED);
export const loadedAnswers = createAction(ANSWERS_LOADED);
