import { Record } from "immutable";


const SlideModelRecord = Record({
  active: false,
  id: null,
  link: '',
  photo: '',
  position: 0,
  title: '',
  publicImages: [],
});

export class SlideModel extends SlideModelRecord {
  //
}
