import { createAction } from "@reduxjs/toolkit";

import {
    FETCH_ORDER,
    ORDER_LOADED,
    ORDER_LOADED_WITH_ERROR,
    ORDER_UPDATED, ORDER_UPDATED_WITH_ERROR,
    RESET_ORDER,
    UPDATE_ORDER
} from "./orderConstants";


export const fetchOrder = createAction(FETCH_ORDER);
export const orderLoaded = createAction(ORDER_LOADED);
export const orderLoadedWithError = createAction(ORDER_LOADED_WITH_ERROR);
export const resetOrder = createAction(RESET_ORDER);

export const updateOrder = createAction(UPDATE_ORDER);
export const orderUpdated = createAction(ORDER_UPDATED);
export const orderUpdatedWithError = createAction(ORDER_UPDATED_WITH_ERROR);
