import Immutable from "immutable";

import { __ } from "../../utils/utils";
import { PaymentModel } from "../../models/Payments/PaymentModel";


export const paymentCreator = data => new PaymentModel({
  id: __(data.id),
  position: __(data.position),
  publicImages: data.public_images,
  link: __(data.link),
});

export const paymentProcess = (data) => {
    return (
        Immutable.Map({
            installments:
                Object.values(data)
                    .filter((item) => item.is_installment === 1)
                    .reduce((acc, item) => acc.set(item.id, paymentCreator(item)), Immutable.Map()),

            payments:
                Object.values(data)
                    .filter((item) => item.is_installment !== 1)
                    .reduce((acc, item) => acc.set(item.id, paymentCreator(item)), Immutable.Map()),
        })
    );
}
