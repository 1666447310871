import React from "react";
import {useSelector} from "react-redux";

import {CookieProductQuantity} from "./CookieProductQuantity";
import {cartSelectors} from "../../cart/_core/cartSelectors";
import {WidthRatioImageContainer} from "../../common/ratioContainers/WidthRatioImageContainer";
import {titleFormatter} from "../../../utils/titleFormatter";
import {CookieProductPrice} from "./CookieProductPrice";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";


export const CookieListItem = ({desert, freeCookieCount}) => {
    const cartItem = useSelector(state => cartSelectors.cartItem(state, desert));
    const productInCart = cartItem.count ? 'incart' : 'none';

    return (
        <li className="secondary-goods__list-item" data-item={productInCart}>
            <div className="secondary-goods__item">
                <h3 className="secondary-goods__title">
                    <div>
                        <span>{titleFormatter(desert.title)}</span>
                        {
                            !!freeCookieCount && desert.subtype === PRODUCT_TYPES.dessert.subtypes.cookie.id
                            && <span className="secondary-goods__free">{freeCookieCount}</span>
                        }
                    </div>
                </h3>
                <CookieProductPrice desert={desert} freeCookieCount={freeCookieCount}/>
                <WidthRatioImageContainer
                    ratio={217 / 136}
                    publicImages={desert.publicImages}
                    type="main"
                    className="secondary-goods__picture"/>

                <CookieProductQuantity product={desert}/>
            </div>
        </li>
    );
};
