import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { getUrlParams } from "../utils/http";
import { successOrderRequesting } from "./_core/bepaidPage/bepaidPageActions";
import { pageSelectors } from "./_core/pagesSelectors";
import { settingSelectors } from "../components/settings/_core/settingSelectors";
import { PageLoader } from "../components/common/pageLoader/PageLoader";
import { SectionTitle } from "../components/common/sectionTitle/SectionTitle";


export const PaymentSuccessPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bepaidOrder = useSelector(pageSelectors.bePaidPage);
  const content = useSelector(settingSelectors.content);

  if (history.location.search) {
    const searchParams = getUrlParams(history.location.search);
    dispatch(successOrderRequesting({ token: searchParams.token }));
    history.replace('/online/success');
  }

	if (!bepaidOrder.get('orderLoaded')) {
		return (
			<div style={{position: 'relative', height: '350px'}}>
				<SectionTitle title="спасибо, ваш заказ принят" />
				<PageLoader />
			</div>)
	}

	let title, description;

	if (bepaidOrder.isPreorder && bepaidOrder.isDelivery) {
		title = `Спасибо, ваш заказ принят. Ждем вас по адресу: ${bepaidOrder.fullAddress}, ${bepaidOrder.deliveryDate} с ${bepaidOrder.deliveryTimeFrom} по ${bepaidOrder.deliveryTimeTo}`;
		description = content.order_message_online_self_delivery_preorder;
	}

	if (bepaidOrder.isPreorder && !bepaidOrder.isDelivery) {
    title = `Спасибо, ваш заказ принят и будет доставлен курьером ${bepaidOrder.deliveryDate} с ${bepaidOrder.deliveryTimeFrom} по ${bepaidOrder.deliveryTimeTo}`;
    description = content.order_message_online_preorder;
  }

	if (!bepaidOrder.isPreorder && bepaidOrder.isDelivery) {
		title = `Спасибо, ваш заказ принят. Ждем вас по адресу: ${bepaidOrder.fullAddress} через ${bepaidOrder.selfDeliveryTime} минут`;
		description = content.order_message_online_self_delivery
	}

	if (!bepaidOrder.isPreorder && !bepaidOrder.isDelivery) {
		title = `Спасибо, ваш заказ принят и будет доставлен курьером в течение ${bepaidOrder.delivery45 ? '45' : '60'} минут.`;
    description = content.order_message_online;
  }

  return (
    <div className="content-page">
      <SectionTitle title={title}/>
      <div dangerouslySetInnerHTML={{ __html: description }}/>
      <br/>
    </div>
  );
};
