import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import m from "moment";

import { orderSelectors } from "../order/_core/orderSelectors";
import { settingSelectors } from "../settings/_core/settingSelectors";
import { resetOrder } from "../order/_core/orderActions";
import { OrderFeedbackForm } from "../order/feedbackForm/OrderFeedbackForm";
import { OrderList } from "../order/OrderList";

import "../../../styles/content-page.css";


export const OrderDone = () => {
    const dispatch = useDispatch();
    const address = useSelector(orderSelectors.address);
    const isPickup = useSelector(orderSelectors.isPickup);
    const isPreorder = useSelector(orderSelectors.isPreorder);
    const delay = useSelector(orderSelectors.delay);
    const rawPreorderAt = useSelector(orderSelectors.rawPreorderAt);
    const preorderAt = isPreorder ? m(rawPreorderAt) : null;
    const content = useSelector(settingSelectors.content);
    const settings = useSelector(settingSelectors.settings);
    let title, description;

    useEffect(() => {
        return () => {
            dispatch(resetOrder());
        }
    })

    if (isPreorder && isPickup) {
        title = `Спасибо! Ваш заказ принят. Ждем Вас по адресу: ${address.street}, ${address.house} ${preorderAt.format('DD.MM.YY')} к ${preorderAt.format('H:mm')}.`;
        description = content.order_message_self_delivery_preorder;
    }

    if (isPreorder && !isPickup) {
        title = `Спасибо! Ваш заказ принят и будет доставлен ${preorderAt.format('DD.MM.YY')} c ${preorderAt.format('H:mm')} до ${preorderAt.add(30, 'minutes').format('H:mm')}.`;
        description = content.order_message_preorder;
    }

	if (!isPreorder && isPickup) {
    title = `Спасибо! Ваш заказ принят. Ждем Вас по адресу: ${address.street}, ${address.house} через ${delay} минут.`;
    description = content.order_message_self_delivery;
  }

	if (!isPreorder && !isPickup) {
    title = `Спасибо! Ваш заказ принят и будет доставлен в течение ${settings.delivery45 ? '45' : '60'} минут.`;
    description = content.order_message;
  }

  return (
      <div className="content-page">
          <h2 style={{color: '#637c6c'}}>
              {title}
          </h2>
          <br/>
          <div dangerouslySetInnerHTML={{__html: description}}/>
          <br/>

          <OrderList/>
          <OrderFeedbackForm />
      </div>
  );
};
