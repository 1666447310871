import moment from "moment";
import { OrderedMap } from "immutable";

import { __, toBool } from "../../utils/utils";
import { ManufactureModel } from "../../models/Manufacture/ManufactureModel";
import { ManufactureResolveResultModel, } from "../../models/Manufacture/SimplifiedManufactureModel";
import { ManufactureWorkTimeInfoModel } from "../../models/Manufacture/ManufactureWorkTimeInfoModel";
import {ManufactureWorkTimeInfoDataModel} from "../../models/Manufacture/ManufactureWorkTimeInfoDataModel";


export const manufactureWorkTimeInfoModelCreator = data => {
    return data && new ManufactureWorkTimeInfoModel({
        pickup: manufactureWorkTimeInfoDataModelCreator(data.pickup),
        delivery:  manufactureWorkTimeInfoDataModelCreator(data.delivery),
        isWorkDay: toBool(data.is_work_day),
    })
}

export const manufactureWorkTimeInfoDataModelCreator = data => {
    return data && new ManufactureWorkTimeInfoDataModel({
        closeAt: __(data.closed_at),
        openAt: __(data.opened_at),
        isOpen: toBool(data.is_working_now),
    })
}

export const manufactureModelCreator = item => {
    return new ManufactureModel({
        houseId: __(item.house.id),
        house: __(item.house.title),
        isPickupSlowed: toBool(item.is_pickup_slowed),
        id: __(item.manufacture_id),
        onStopAt: item.on_stop_at ? moment(item.on_stop_at) : null,
        streetId: __(item.street.id),
        street: __(item.street.title),
        type: __(item.type),
        withPickup: toBool(item.with_pickup),
        status: {
            warning: __(item.status?.warning),
            error: __(item.status?.error),
        },
        workingTimeInfo: manufactureWorkTimeInfoModelCreator(item.working_time_info),
    })
};

export const simplifiedManufactureModelCreator = data => {
    return new ManufactureResolveResultModel({
        id: __(data.manufacture_id),
        isPickupSlowed: __(data.is_pickup_slowed),

        warning: __(data.warning),
        error: __(data.error),
    })
}

export const manufactureMapProcess = data => OrderedMap().withMutations(manufacturesMap => {
    data.forEach(manufacture => {
        return manufacturesMap.set(manufacture.manufacture_id, manufactureModelCreator(manufacture))
    })
});

