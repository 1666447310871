import React from "react";
import { Link } from "react-router-dom";

import { scrollToTop } from "../../../../utils/scrollToAnchor";

export const BrandHuge = () => {
  return (
    <div className="mx-auto mt-4 tab:mt-[25px] pc:my-2 w-[288px] tab:w-[512px] pc:w-[686px] px-1">
      <Link to={`/`} onClick={scrollToTop}>
        <div className="aspect-[429/86] text-transparent bg-fullLogo bg-no-repeat bg-cover"/>
      </Link>
    </div>
  )
}
