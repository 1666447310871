export const getHouseComparator = function(str) {
  const parts = str.split('/');
  const main = parts[0],
    slave = parts[1];

  let m1 = 0,
    m2 = 0,
    s1 = 0,
    s2 = 0;

  if (main) {
    let m1_ = main.replace(/[^\d]/g, ""),
      m2_ = main.replace(/[\d]/g, "");

    m1 = parseInt(m1_, 10) * 100000000000;
    m1 = isNaN(m1) ? 0 : m1;

    if (m2_ !== '') {
      for (let i = 0; i < m2_.length; i++) {
        m2 += m2_.charAt(i) ? m2_.charCodeAt(i) : 0;
      }
      m2 = m2 * 100000;
    }
  }

  if (slave) {
    let s1_ = slave.replace(/[^\d]/g, ""),
      s2_ = slave.replace(/[\d]/g, "");

    s1 = parseInt(s1_, 10) * 100000;
    s1 = isNaN(s1) ? 0 : s1;

    if (s2_ !== '') {
      for (let i = 0; i < s2_.length; i++) {
        s2 += s2_.charAt(i) ? s2_.charCodeAt(i) : 0;
      }

    }
  }

  return m1 + m2 + s1 + s2;
};

// work with array of House JS Object
export const houseComparator = ([aId, a], [bId, b]) => {
  let aC = getHouseComparator(a.title),
    bC = getHouseComparator(b.title);

  if (aC < bC) {
    return -1;
  }
  if (aC > bC) {
    return 1;
  }
  return 0;
};

