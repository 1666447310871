import { fromJS } from "immutable";

import { CURRENT_MANUFACTURE_UPDATED } from "./manufactureConstants";


const initialState = fromJS({});

export const currentManufactureReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CURRENT_MANUFACTURE_UPDATED:
      return payload;

    default:
      return state
  }
};
