import Immutable from "immutable";


const CustomerModelRecord = Immutable.Record({
  addresses: [],
  name: '',
  phone: '',
});


export class CustomerModel extends CustomerModelRecord {}
