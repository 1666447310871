import { fromJS, OrderedMap } from "immutable";

import {
  BLOCK_PRODUCTS_LOADED,
  HOUSES_LOADED,
  MANUFACTURES_LOADED,
  STREETS_LOADED,
  VACANCIES_LOADED
} from "./catalogConstants";
import { ANSWERS_LOADED } from "../../settings/_core/settingConstants";
import { FEEDBACK_TROUBLES_LOADED } from "../../order/feedbackForm/_core/feedackTroublesConstants";


const initialState = fromJS({
  streets: OrderedMap(),
  houses: OrderedMap(),
  manufactures: OrderedMap(),
  vacancies: OrderedMap(),
  answers: OrderedMap(),
  feedbackTroubles: OrderedMap(),
  blockProducts: OrderedMap(),
});

export const catalogsReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case STREETS_LOADED:
      return state.set('streets', payload);

    case MANUFACTURES_LOADED:
      return state.set('manufactures', payload);

    case HOUSES_LOADED:
      return state.set('houses', payload);

    case VACANCIES_LOADED:
      return state.set('vacancies', payload);

    case BLOCK_PRODUCTS_LOADED:
      return state.set('blockProducts', payload);

    case ANSWERS_LOADED:
      return state.set('answers', payload);

    case FEEDBACK_TROUBLES_LOADED:
      return state.set('feedbackTroubles', payload)

    default:
      return state
  }
};
