import  React from "react";

import { AddressPopup } from "../../address/AddressPopup";
import { RollPopup } from "../../products/rolls/rollPopup/RollPopup";
import { RollsetPopup } from "../../products/rollsets/rollsetPopup/RollsetPopup";
import { CartConfirmPopup } from "../../cart/cartConfirmingPopup/CartConfirmPopup";
import { PokePopup } from "../../products/poke/pokePopup/PokePopup";
import { SoupPopup } from "../../products/soups/soupPopup/SoupPopup";
import {DessertPopup} from "../../products/desserts/dessertPopup/DessertPopup";
import {PrivacyPolicyRulesPopup} from "../../cart/PrivacyPolicyRulesPopup/PrivacyPolicyRulesPopup";
import {GarnishPopup} from "../../products/garnishes/garnish/garnishPopup/GarnishPopup";
import {SaucePopup} from "../../products/garnishes/sauce/saucePopup/SaucePopup";


export const POPUP_COMPONENTS = {
  rollsetPopup: {
    type: 'rollsetPopup',
    component: RollsetPopup
  },
  rollPopup: {
    type: 'rollPopup',
    component: RollPopup
  },
  pokePopup: {
    type: 'pokePopup',
    component: PokePopup
  },
  garnishPopup: {
    type: 'garnishPopup',
    component: GarnishPopup
  },
  saucePopup: {
    type: 'saucePopup',
    component: SaucePopup
  },
  soupPopup: {
    type: 'soupPopup',
    component: SoupPopup
  },
  dessertPopup: {
    type: 'dessertPopup',
    component: DessertPopup
  },
  addressPopup: {
    type: 'addressPopup',
    component: AddressPopup,
  },
  cartConfirmingPopup: {
    type: 'cartConfirmingPopup',
    component: CartConfirmPopup,
  },
  privacyPolicyRulesPopup: {
    type: 'privacyPolicyRulesPopup',
    component: PrivacyPolicyRulesPopup
  },
};


