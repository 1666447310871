import { orderCreator } from "./orderCreators";
import { fetchApiProcess } from "../../utils/http";


export const orderCallers = {
  fetchOrder: (orderHash) => fetchApiProcess([ 'orders', orderHash ], orderCreator, {
    version: 'v4.0.0',
  }),

  // updateOrder: (order) => fetchApiProcess(['order', order.id], orderCreator, {
  //   method: 'POST',
  //   version: 'v4.0.0',
  //   body: {
  //     order
  //   }
  // })

  updateOrder: (order) => {
    console.log('UPDATE ORDER', order);
    return {
      result: orderCreator()
    }
  }
};
