import React from "react";
import { Link } from "react-router-dom";

import { useHeaderScrollHandler } from "./core/useHeaderScrollHandler";
import { BrandHuge } from "./components/BrandHuge";
import { InformationLinks } from "./components/InformationLinks";
import { ContactBar } from "./components/ContactBar";
import { HeaderBar } from "./components/HeaderBar";
import { ProductsMenu } from "./components/ProductsMenu";
import { scrollToTop } from "../../../utils/scrollToAnchor";

import "./header.css";
import "./mobile-header.css";
import { ReactComponent as DostavkaIcon } from "../../../../assets/images/react-svg/DostAvkaSushi.svg";


export const Header = () => {
  useHeaderScrollHandler();

  return (
    <header className="font-Circe bg-header fixed top-0 right-0 left-0 z-50">
      <div className="m-auto px-3 pt-3 tab:py-[10px] max-w-[980px] relative h-[372px] overflow-hidden header__outer">
        <div className="relative z-40 m-auto h-[432px] header__inner">

          <div className="m-auto pc:hidden tab:max-w-[448px] tab:max-width-[400px]">
            <HeaderBar/>
          </div>

          <div className="w-full hidden pc:flex justify-between items-center">
            <div className="flex items-center">
              <div className="h-[80px] w-[110px] mr-6">
                <Link to={`/`} onClick={scrollToTop}>
                  <DostavkaIcon height="80px" width="110px" className="drop-shadow-2xl cursor-pointer"/>
                </Link>
              </div>
              <InformationLinks/>
            </div>
            <div className="min-w-[370px] pl-2">
              <HeaderBar/>
            </div>
          </div>


          <BrandHuge/>
          <div className="pc:hidden">
            <InformationLinks/>
          </div>

          <ContactBar/>

          <div className="tab:mt-4 mb-4 tab:mb-4 pc:mb-2 pc:w-full">
            <div className="tab:mx-auto tab:max-w-[442px]">
              <ProductsMenu/>
            </div>
          </div>

          {/* sticky part of the header */}
          <div className="mt-3 tab:mt-6 pc:hidden">
            <div className="mx-auto tab:max-w-[448px]">
              <HeaderBar/>
            </div>
            <div className="mt-2 tab:mt-6 tab:mb-1">
              <ProductsMenu/>
            </div>
          </div>

          <div className="hidden pc:flex justify-between items-center h-[93px]">
            <div className="pl-4 flex-grow">
              <ProductsMenu/>
            </div>
            <div className="w-[380px]">
              <HeaderBar/>
            </div>
          </div>

        </div>
      </div>
    </header>
  )
}
