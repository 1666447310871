import { LOCATION_CHANGE } from "connected-react-router";


const initialState = {
    currentLocation: {
        hash: '',
        pathname: '',
    },
    previousLocation: {
        hash: null,
        pathname: null,
    }
};

export const routerHistorySelector = state => state.get('routerHistory');

export const routerHistoryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LOCATION_CHANGE:
            return {
                currentLocation: {
                    hash: payload.location.hash,
                    pathname: payload.location.pathname
                },
                previousLocation: state.currentLocation,
            };

        default:
            return state
    }
};
