import { Record } from "immutable";

import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";


const PokeInfoRecord = Record({
  amount: null,
  calories: null,
  carbohydrates: null,
  fats: null,
  price: 0,
  proteins: null,
  priceAvoided: 0
});

export class PokeInfo extends PokeInfoRecord {
  //
}

const PokeModelRecord = Record({
	active: null,
	ageLimit: null,
  announce: null,
  isOnTrade: null,
  isSuitableForCurrentCart: null,
  position: null,
  price: 0,
  publicImages: {},
  id: null,
  title: '',

  info: new PokeInfo(),
  type: PRODUCT_TYPES.poke.id,
  subtype: null,
});

export default class PokeModel extends PokeModelRecord {
  //
}
