import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { api } from "../../../api/api";
import { featureSagaCreator } from "../../../utils/featureSagaCreator";
import {
	changePasswordFailed,
	changePasswordSuccess,
	deleteAddressFailed,
	deleteAddressSuccess,
	loginFailed,
	loginSuccess,
	logoutFailed,
	logoutSuccess
} from "./customerActions";
import { CHANGE_PASSWORD, DELETE_ADDRESS, LOGIN, LOGOUT, REQUEST_PASSWORD } from "./customerConstants";
import { generateCalls } from "../../../utils/utils";
import { changePopupProps } from "../../common/popups/_core/popupActions";
import {
	showPopupError, showPopupNotification,
	showPopupWarning
} from "../../common/popups/_core/popupSaga";
import { ADDRESS_POPUP_FORMS } from "../../address/addressPopupTabs/addressPopupConstants";


export function* fetchMe() {
	const {result} = yield call(api.customer.fetchMe);
	yield put(loginSuccess(result));
}

function* loginSaga({payload}) {
	const {result, error} = yield call(api.customer.login, payload);
	if (error) {
		yield put(loginFailed(error));
		yield fork(showPopupError, error.message);

	} else {
		yield put(loginSuccess(result));
		yield put(changePopupProps({
			myAddressForm: ADDRESS_POPUP_FORMS.selectAddress,
		}))
	}
}

function* logoutSaga({payload}) {
	const {result, error} = yield call(api.customer.logout, payload);
	if (error) {
		yield put(logoutFailed(error));
		yield fork(showPopupError, error.message);
	} else {
		yield put(logoutSuccess(result));
		yield put(changePopupProps({
			myAddressForm: ADDRESS_POPUP_FORMS.login,
		}));
	}
}

function* requestPasswordSaga({payload}) {
	const {result, error} = yield call(api.customer.requestPassword, payload);
	if (error) {
		yield fork(showPopupError, error.message);
	} else {
		yield put(changePopupProps({
			myAddressForm: ADDRESS_POPUP_FORMS.login,
		}));
		yield fork(showPopupWarning, result);
	}
}

function* changePasswordSaga({payload}) {
	const {result, error} = yield call(api.customer.changePassword, payload);

	if (result) {
		yield put(changePasswordSuccess(result));
		yield put(changePopupProps({
			myAddressForm: ADDRESS_POPUP_FORMS.selectAddress,
		}));
		yield fork(showPopupNotification, result);

	} else {
		yield put(changePasswordFailed(error));
		yield fork(showPopupError, error.message);
	}
}

function* deleteAddressSaga({payload}) {
	const {result, error} = yield call(api.customer.deleteAddress, payload);
	if (error) {
		yield put(deleteAddressFailed(error));
		yield fork(showPopupError, error);
	} else {
		yield put(deleteAddressSuccess(payload.address_id));
		yield put(changePopupProps({
			myAddressForm: ADDRESS_POPUP_FORMS.selectAddress,
		}));
	}
}

const loaderSettings = {
	me: true,
};

function* loader(settings = loaderSettings) {
	settings = {...loaderSettings, ...settings};
	yield all(generateCalls(
		{
			fetchMe: settings.me,
		},
		{
			fetchMe,
		},
	))
}

export const customerSaga = featureSagaCreator({
	init: loader,
	workers: function* () {
		yield all([
			takeEvery(LOGIN, loginSaga),
			takeEvery(LOGOUT, logoutSaga),
			takeEvery(CHANGE_PASSWORD, changePasswordSaga),
			takeEvery(DELETE_ADDRESS, deleteAddressSaga),
			takeEvery(REQUEST_PASSWORD, requestPasswordSaga),
		])
	},
});
