import Immutable from "immutable";


const BepaidOrderModelRecord = Immutable.Record({
  deliveryDate: '',
  deliveryTimeFrom: '',
  deliveryTimeTo: '',
  selfDeliveryTime: '',
  createdAt: '',
  isPreorder: null,
  isDelivery: null,
  delivery45: null,
  fullAddress: null,
  orderLoaded: false,
});


export class BepaidOrderModel extends BepaidOrderModelRecord {
}
