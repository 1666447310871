import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { cartSelectors } from "../../_core/cartSelectors";
import { customerSelectors } from "../../../customer/_core/customerSelectors";
import { settingSelectors } from "../../../settings/_core/settingSelectors";
import { InputRadioButton } from "./InputRadioButton";
import { Warning } from "../../../common/notifications/warning/Warning";
import { updareCartPayment } from "../../_core/cartActions";


export const PAYMENT_METHOD = {
  'bank_card': {
    id: 'bank_card',
    title: 'картой'
  },
  cash: {
    id: 'cash',
    title: 'наличными'
  },
  online: {
    id: 'online',
    title: 'онлайн',
  },
  halva: {
    id: 'online_halva',
    title: 'халва онлайн'
  }
};


export const PaymentInput = () => {
  const dispatch = useDispatch();
  const cart = useSelector(customerSelectors.cart);
  const isPreorder = useSelector(cartSelectors.isPreorder);
  const settings = useSelector(settingSelectors.settings);

  const applyPaymentMethod = (method) => () => {
    dispatch(updareCartPayment({
      payment: method,
    }));
  };

  const {paymentType} = cart.priceSpecification;

  const cashDisabled = cart.shipping.noContactOnDelivery;
  const onlineDisabled = settings.onlineDisabled || (isPreorder && settings.onlinePreorderDisabled);
  const chargeDisabled = settings.paymentDyCardDisabled || cart.shipping.noContactOnDelivery;
  const halvaDisabled = settings.halvaDisabled || (isPreorder && settings.halvaPreorderDisabled);

  return (
    <div className="form-payments-and-total__payment-type">
      <div className="form-group">
        <ul className="form-list-radio">

          <InputRadioButton
            onClick={applyPaymentMethod(PAYMENT_METHOD.cash.id)}
            title={PAYMENT_METHOD.cash.title}
            name="payment-method"
            checked={paymentType === PAYMENT_METHOD.cash.id}
            disabled={cashDisabled}
          />

          <InputRadioButton
            onClick={applyPaymentMethod(PAYMENT_METHOD.bank_card.id)}
            title={PAYMENT_METHOD.bank_card.title}
            name="payment-method"
            checked={paymentType === PAYMENT_METHOD.bank_card.id}
            disabled={chargeDisabled}
          />

          <InputRadioButton
            onClick={applyPaymentMethod(PAYMENT_METHOD.online.id)}
            title={PAYMENT_METHOD.online.title}
            name="payment-method"
            checked={paymentType === PAYMENT_METHOD.online.id}
            disabled={onlineDisabled}
          />

          <InputRadioButton
            onClick={applyPaymentMethod(PAYMENT_METHOD.halva.id)}
            title={PAYMENT_METHOD.halva.title}
            name="payment-method"
            checked={paymentType === PAYMENT_METHOD.halva.id}
            disabled={halvaDisabled}
          />

        </ul>
        {
          (paymentType === PAYMENT_METHOD.online.id || paymentType === PAYMENT_METHOD.halva.id) && (
            <Warning className="fs-m" description="
								после нажатия кнопки Оформить, не будет
								возможности изменить заказ или способ оплаты
							"/>
          )
        }

        {cart.shipping.noContactOnDelivery && (
          <Warning className="fs-m"
                   description="По умолчанию бесконтактная доставка осуществляется курьером до двери.<br>
                    После звонка в домофон курьер размещает заказ рядом с дверью, <br>
                    телефонным звонком предупреждает Клиента об осуществленной доставке и уезжает.<br>
                    При необходимости оставить заказ в  другом месте – подробно укажите  в комментариях к заказу"/>
        )}
      </div>
    </div>
  );
}
