import { Record } from "immutable";
import {ManufactureWorkTimeInfoDataModel} from "./ManufactureWorkTimeInfoDataModel";


const ManufactureWorkTimeInfoRecord = Record({
	isWorkDay: null,
	pickup: new ManufactureWorkTimeInfoDataModel(),
	delivery: new ManufactureWorkTimeInfoDataModel(),
});

export class ManufactureWorkTimeInfoModel extends ManufactureWorkTimeInfoRecord {
  //
}
