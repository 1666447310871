import React from "react";

import { GarnishListItem } from "./GarnishListItem";
import { StickListItem } from "../stick/StickListItem";
import { sortByPosition } from "../../../../utils/utils";


export const GarnishList = ({ productList, stick }) => {
  return (
    <ul className="secondary-goods__list" data-size="3">
      {productList.toList().sort(sortByPosition).map(product => (
        <GarnishListItem garnish={product} key={product}/>
      ))}
      <StickListItem stick={stick}/>
    </ul>
  );
};
