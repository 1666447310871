import React from "react";
import { useSelector } from "react-redux";
import { settingSelectors } from "../settings/_core/settingSelectors";
import { YandexMapConstructor } from "./YandexMapConstructor";


export const PickupMap = () => {
    const content = useSelector(settingSelectors.content);

    return (
		<YandexMapConstructor constructorURL={content.pickup_map} />
    )
};

