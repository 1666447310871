import { Record } from "immutable";

import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";


const DrinkModelRecord = Record({
	active: null,
	ageLimit: null,
	announce: null,
	isOnTrade: null,
	isSuitableForCurrentCart: null,
	position: null,
	price: 0,
	publicImages: {},
    id: null,
    title: '',
    type: PRODUCT_TYPES.drink.id,
	producerCountry: '',
});

export default class DrinkModel extends DrinkModelRecord {
  //
}
