import React from "react";
import { useSelector } from "react-redux";

import { settingSelectors } from "../../../settings/_core/settingSelectors";
import { nbsp } from "../../../../utils/titleFormatter";
import {Link} from "react-router-dom";


export const ContactBar = () => {
  const {delivery45, workTimeFrom} = useSelector(settingSelectors.settings);

  return (
    <div className="py-2 mx-auto min-h-[104px] tab:min-h-[133px] tab:max-w-[528px] pc:max-w-[528px] flex justify-around text-textBlack">

      <div className="w-1/2 pc:w-[35%] px-2 pc:px-0 text-center">
        <div className="w-full font-bold text-t25 tab:text-t37 leading-[25px] tab:leading-[37px]">
          <a className="hover:text-textHover" href="tel:+375296040000">604-00-00</a>
        </div>

        <div className="text-t12 tab:text-t18 leading-[0.8rem] tab:leading-[1rem]">
          <div className="">а1 мтс лайф<br/></div>
          <div className="font-bold leading-[1.5]">{workTimeFrom}<br/></div>
          {/*{!!workTimeFrom && <span className="font-bold text-t12 leading-[1.25]">{workTimeFrom}<br/></span>}*/}
          {/*{!!workTimeTo && <span className="font-bold text-t12 leading-[1.25]">{workTimeTo}<br/></span>}*/}
        </div>
      </div>

      <div className="w-1/2 pc:w-[65%] pr-1 text-center grow-0 flex-shrink-0">
        <div
          className="text-t25 tab:text-t37 leading-[25px] tab:leading-[37px] font-bold">{delivery45 ? 45 : 60}&nbsp;минут
        </div>

        <div className="text-t12 tab:text-t18 tab:px-4 pc:px-0 leading-[0.8rem] tab:leading-[1rem]">
          <Link className="hover:text-textHover" to={'/information#special-price-25'} >
            Если мы не{nbsp}доставим заказ
            за{nbsp}{delivery45 ? 45 : 60}{nbsp}минут, вы{nbsp}получите
            самое дорогое блюдо
            из{nbsp}заказа за{nbsp}одну копейку. Сет по специальной цене.
          </Link>
        </div>
      </div>

    </div>
  )
};
