export const ROUTES = {
  main: {
    path: '/',
    anchors: {
      rollsets: 'rollsets',
      rolls: 'rolls',
      garnishes: 'garnishes',
      sauces: 'sauces',
      sticks: 'sticks',
      drinks: 'drinks',
      desserts: 'desserts',
    },
  },
  popups: {
    rollset: '/rollset-detail/:id',
    roll: '/rolls-detail/:id',
    poke: '/poke-detail/:id',
    garnish: '/garnish-detail/:id',
    sauce: '/sauce-detail/:id',
    soup: '/soup-detail/:id',
    dessert: '/dessert-detail/:id',
    shipping: '/shipping-settings'
  },
  cart: {
    path: '/cart'
  },
  deliveryZone: {
    path: '/delivery-zone',
  },
  pickup: {
    path: '/pickup',
  },
  privatPolicy: {
    path: '/privacy-policy',
    anchors: {
      cookie: 'cookie',
    },
  },
  audioAndVideoPolicy: {
    path: '/audio-and-video-policy',
  },
  information: {
    path: '/information'
  },
  payments: {
    path: '/payments'
  },
  rules: {
    path: '/rules'
  },
  vacancies: {
    path: '/vacancies',
  },
  paymentSuccessResult: {
    path: '/online/success',
  },
  paymentFailureResult: {
    path: '/online/fail',
  },

  // With params
  order: {
    path: '/o/:orderHash',
    params: {
      orderHash: '*'
    },
  },
  socialMedia: {
    path: '/m/:network',
    params: {
      network: {
        pattern: {
          viber: 'vb',
          telegram: 'tg',
          whatsapp: 'wa',
        },
      },
    },
  },

  // Embedded / Popups
  embedded: {
    productPopup: {
      path: '/p/product/:productType/:productId',
      params: {
        productType: {
          pattern: {
            roll: 'roll',
            rollset: 'rollset',
            garnish: 'garnish',
            drink: 'drink',
            dessert: 'dessert',
            soup: 'soup',
            poke: 'poke',
          },
        },
        productId: {
          pattern: '*'
        },
      },
      creator: (productType, productId) => `/p/product/${productType}/${productId}`,
    },
    addressesPopup: {
      path: '/p/address/:tab',
      params: {
        tab: {
          pattern: {
            delivery: 'delivery',
            pickup: 'pickup',
            custom: 'custom',
          },
        },
      },
    },
  },
  // Redirects
  redirects: {

  },
  // Errors
  notFound: {
    path: '/not-found',
  },
};
