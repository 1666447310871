import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    streetChanged,
    updateCartAddress,
    updateCartEntrance,
    updateCartFlat,
    updateCartFloor,
    updateCartIntercom
} from "../../_core/cartActions";
import { changePopupProps } from "../../../common/popups/_core/popupActions";
import { cartSelectors } from "../../_core/cartSelectors";
import { AddressAdditionalInput } from "./AddressAdditionalInput";
import { AddressHouseSelectInput } from "../../../address/addressFormComponents/AddressHouseSelectInput";
import { AddressStreetSelectInput } from "../../../address/addressFormComponents/AddressStreetSelectInput";
import { catalogSelectors } from "../../../catalogs/_core/catalogSelectors";
import { ROUTES } from "../../../../system/router/routeConstants";
import { useHistory } from "react-router-dom";


const SELECT_STYLES = {
    control: styles => {
        return ({
            ...styles,
            border: '2px solid #637c6c',
        })
    },
};


export const AddressInput = React.memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const address = useSelector(cartSelectors.address);
    const isPickup = useSelector(cartSelectors.isPickup);
    const manufactures = useSelector(catalogSelectors.pickupManufactures);

    const handleStreetChange = ({label, value}) => {
        dispatch(streetChanged({
            street: label,
            streetId: value
        }));
    };

    const handleHouseChange = ({label, value}) => {
        dispatch(updateCartAddress({
            house: label,
            house_id: value,
            street: address.street,
            street_id: address.streetId,
        }));
    };


    const handlePickupClick = (e) => {
        e.preventDefault();
        history.push(ROUTES.popups.shipping)
        dispatch(changePopupProps({
            props: {
                tab: 'pickup'
            }
        }))
    };


    return (
        <div className="form-group">
            <div className="form-grid form-grid--sps-normal form-grid--address">

                <div className="form-grid__cell form-size-normal form-required">
                    <div className="form-label">улица</div>
                    <AddressStreetSelectInput
                        styles={SELECT_STYLES}
                        onChange={handleStreetChange}/>
                    {isPickup ? (
                        <>
                            {
                                !!manufactures.size && (
                                    <p className="form-note">Самовывоз.
                                        <a href="" onClick={handlePickupClick}> Выбрать другой адрес самовывоза</a>
                                    </p>
                                )
                            }
                        </>
                    ) : (
                        <p className="form-note">Доставка.
                            <a href="" onClick={handlePickupClick}> Изменить на самовывоз?</a>
                        </p>
                    )}
                </div>

                <div className="form-grid__cell form-grid__cell--house form-required">
                    <div className="form-label">дом</div>
                    <AddressHouseSelectInput
                        style={SELECT_STYLES}
                        onChange={handleHouseChange}
                    />
                </div>

                <AddressAdditionalInput value={address.flat}
                                        title="квартира"
                                        propName="flat"
                                        dispatchMethod={updateCartFlat}/>

                <AddressAdditionalInput value={address.entrance}
                                        title="подъезд"
                                        propName="entrance"
                                        dispatchMethod={updateCartEntrance}/>

                <AddressAdditionalInput value={address.floor}
                                        title="этаж"
                                        propName="floor"
                                        dispatchMethod={updateCartFloor}/>

                <AddressAdditionalInput value={address.intercom}
                                        title="домофон"
                                        propName="intercom"
                                        dispatchMethod={updateCartIntercom}/>
            </div>
        </div>
    );
});
