import React from "react";

import { SectionTitle } from "../common/sectionTitle/SectionTitle";


export const EmptyCart = () => {
  return (
    <div className="content-page">
      <SectionTitle title="ваша корзина пуста" id="order-list"/>
    </div>
  );
};
