import React, {useState} from "react";
import Carousel, {Dots, slidesToShowPlugin, arrowsPlugin} from "@brainhubeu/react-carousel";


import "@brainhubeu/react-carousel/lib/style.css";
import "./SuggestedProductsSlider.css";
import {useSelector} from "react-redux";

import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";
import {suggestedSelectors} from "../_core/suggestedProductSelectors";
import {SuggestedProductItem} from "../suggestedProducts/SuggestedProductItem";
import {cartSelectors} from "../../cart/_core/cartSelectors";


const prepareSlides = (suggestedProducts) => {
    let slides = []
    suggestedProducts.valueSeq().sort((a, b) => a.position > b.position ? 1 : -1)
        .groupBy(model => model.position).forEach(group => {
            group.sort((model1, model2) => 0.5 - Math.random()).forEach(suggestedProduct => {
                if (suggestedProduct && suggestedProduct.product) {
                    const cartItem = useSelector(state => cartSelectors.cartItem(state, suggestedProduct.product));
                    slides.push(<SuggestedProductItem product={suggestedProduct.product} cartItem={cartItem}/>)
                }
            })
        })

    return slides
}

const ArrowLeft = () => (
    (<div className="arrow-wrapper">
        <div className="arrow-left"/>
    </div>)
);

const ArrowRight = () => (
    (<div className="arrow-wrapper">
        <i className="arrow-right"/>
    </div>)
);

export const SuggestedProductsSlider = () => {
    const slides = useSelector(suggestedSelectors[PRODUCT_TYPES.suggestedProduct.id]);
    const [state, setState] = useState({
        value: 0,
        dotsValue: 0,
        slides: prepareSlides(slides)
    });

    const sliderDiv = window.innerWidth > 760 ? 3 : 2

    const onChange = (value) => {
        if (state.value !== value && !isNaN(value)) {
            setState({
                value: state.value + (state.value > value ? - (sliderDiv) : (sliderDiv)),
                dotsValue: state.dotsValue + (state.value > value ? -1 : 1),
                slides: state.slides
            })
        }
    };

    const onChangeDots = (value) => {
        if (state.value !== value && !isNaN(value)) {
            setState({
                value: sliderDiv * value,
                dotsValue: value,
                slides: state.slides
            })
        }
    };

    if (!slides || slides.size === 0) {
        return null;
    }
    const arrowPluginConfig = {
        resolve: arrowsPlugin,
        options: {
            arrowLeft: <ArrowLeft/>,
            arrowLeftDisabled: <ArrowLeft/>,
            arrowRight: <ArrowRight/>,
            arrowRightDisabled: <ArrowRight/>,
            addArrowClickHandler: true,
        }
    }

    const breakpoints = {
        760: {
            plugins: [
                'infinite',
                arrowPluginConfig,
                {
                    resolve: slidesToShowPlugin,
                    options: {
                        numberOfSlides: 2
                    }
                },
            ]
        },
        2500: {
            plugins: [
                'infinite',
                arrowPluginConfig,
                {
                    resolve: slidesToShowPlugin,
                    options: {
                        numberOfSlides: 3
                    }
                },
            ]
        }
    }

    return (
        <div className="hide-on-4cards" data-type="slider">
            <div className='suggested-product-slider-title-wrapper'>
                <p className='s-text'>добавить к заказу</p>
                {<Dots value={state.dotsValue} onChange={onChangeDots} number={Math.ceil(slides.size / sliderDiv)}/>}
            </div>
            <div className="main-slider">
                <div className="main-slider-area">
                    <div className="main-slider-area__item">
                        <Carousel breakpoints={breakpoints}
                                  value={state.value}
                                  slides={state.slides}
                                  onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
