import { createAction } from "@reduxjs/toolkit";

import {
  LOAD_HOUSES,
  HOUSES_LOADED,
  MANUFACTURES_LOADED,
  STREETS_LOADED,
  VACANCIES_LOADED,
  RESOLVE_MANUFACTURE,
  BLOCK_PRODUCTS_LOADED,
} from "./catalogConstants";


export const manufacturesLoaded = createAction(MANUFACTURES_LOADED);
export const streetsLoaded = createAction(STREETS_LOADED);
export const housesLoaded = createAction(HOUSES_LOADED);
export const vacanciesLoaded = createAction(VACANCIES_LOADED);
export const blockProductsLoaded = createAction(BLOCK_PRODUCTS_LOADED);

export const fetchHouses = createAction(LOAD_HOUSES);
export const resolveManufacture = createAction(RESOLVE_MANUFACTURE);
