import { Record } from "immutable";


const FeedbackTroublesRecord = Record({
    sections: {},
    list: {}
});


export class FeedbackTroublesModel extends FeedbackTroublesRecord {
    //
}
