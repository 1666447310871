import React from "react";

import { Infobox } from "../notifications/infobox/Infobox";

import "./section-title.css";


export const SectionTitle = ({ title, count, description, style }) => (
  <>
    <h2 className="s-title" style={style}>
			{title}
      {count ? <small className="s-title__count">{count}</small> : null}
    </h2>

    <Infobox description={description}/>
  </>

);
