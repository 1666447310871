const decorator = str => 'cart/' + str;


export const LOGIN = decorator('LOGIN');
export const LOGIN_SUCCESS = decorator('LOGIN_SUCCESS');
export const LOGIN_FAILED = decorator('LOGIN_FAILED');

export const LOGOUT = decorator('LOGOUT');
export const LOGOUT_SUCCESS = decorator('LOGOUT_SUCCESS');
export const LOGOUT_FAILED = decorator('LOGOUT_FAILED');

export const DELETE_ADDRESS = decorator('DELETE_ADDRESS');
export const DELETE_ADDRESS_SUCCESS = decorator('DELETE_ADDRESS_SUCCESS');
export const DELETE_ADDRESS_FAILED = decorator('DELETE_ADDRESS_FAILED');

export const CHANGE_PASSWORD = decorator('CHANGE_PASSWORD');
export const CHANGE_PASSWORD_SUCCESS = decorator('CHANGE_PASSWORD_SUCCESS');
export const CHANGE_PASSWORD_FAILED = decorator('CHANGE_PASSWORD_FAILED');

export const REQUEST_PASSWORD = decorator('REQUEST_PASSWORD');

