import { Record } from "immutable";

import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";


const SoupInfoRecord = Record({
  amount: null,
  calories: null,
  carbohydrates: null,
  fats: null,
  price: 0,
  proteins: null,
  priceAvoided: 0
});

export class SoupInfo extends SoupInfoRecord {
  //
}

const SoupModelRecord = Record({
  active: null,
  ageLimit: null,
  announce: null,
  isOnTrade: null,
  isSuitableForCurrentCart: null,
  position: null,
  price: 0,
  publicImages: {},
  id: null,
  title: '',

  info: new SoupInfo(),
  type: PRODUCT_TYPES.soup.id,
});

export default class RollsetModel extends SoupModelRecord {
  //
}
