import { CS } from "../../../utils/modules/reselect";
import { sharedSelectors } from "../../shared/core/sharedSelectors";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";

export const productSelectors = {
  [PRODUCT_TYPES.roll.id]: CS(sharedSelectors.products, products => products.get(PRODUCT_TYPES.roll.id)),
  [PRODUCT_TYPES.rollset.id]: CS(sharedSelectors.products, products => products.get(PRODUCT_TYPES.rollset.id)),
  [PRODUCT_TYPES.poke.id]: CS(sharedSelectors.products, products => products.get(PRODUCT_TYPES.poke.id)),
  [PRODUCT_TYPES.soup.id]: CS(sharedSelectors.products, products => products.get(PRODUCT_TYPES.soup.id)),
  [PRODUCT_TYPES.bento.id]: CS(sharedSelectors.products, products => products.get(PRODUCT_TYPES.bento.id)),
  [PRODUCT_TYPES.drink.id]: CS(sharedSelectors.products, products => products.get(PRODUCT_TYPES.drink.id)),
  [PRODUCT_TYPES.dessert.id]: CS(sharedSelectors.products, products => products.get(PRODUCT_TYPES.dessert.id)),
  [PRODUCT_TYPES.suggestedProduct.id]: CS(sharedSelectors.products, products => products.get(PRODUCT_TYPES.suggestedProduct.id)),

  [PRODUCT_TYPES.poke.subtypes.standard.id]:
      CS(sharedSelectors.products,
          products => products.get(PRODUCT_TYPES.poke.id)[PRODUCT_TYPES.poke.subtypes.standard.id]),

  [PRODUCT_TYPES.poke.subtypes.light.id]:
      CS(sharedSelectors.products,
          products => products.get(PRODUCT_TYPES.poke.id)[PRODUCT_TYPES.poke.subtypes.light.id]),

  [PRODUCT_TYPES.poke.subtypes.sashimi.id]:
      CS(sharedSelectors.products,
          products => products.get(PRODUCT_TYPES.poke.id)[PRODUCT_TYPES.poke.subtypes.sashimi.id]),

  [PRODUCT_TYPES.dessert.subtypes.cake.id]:
      CS(sharedSelectors.products,
          products => products.get(PRODUCT_TYPES.dessert.id)[PRODUCT_TYPES.dessert.subtypes.cake.id]),

  [PRODUCT_TYPES.dessert.subtypes.cookie.id]:
      CS(sharedSelectors.products,
          products => products.get(PRODUCT_TYPES.dessert.id)[PRODUCT_TYPES.dessert.subtypes.cookie.id]),
          
  [PRODUCT_TYPES.dessert.subtypes.pie.id]:
      CS(sharedSelectors.products,
          products => products.get(PRODUCT_TYPES.dessert.id)[PRODUCT_TYPES.dessert.subtypes.pie.id]),

  [PRODUCT_TYPES.garnish.subtypes.garnish.id]:
    CS(sharedSelectors.products,
      products => products.get(PRODUCT_TYPES.garnish.id)[PRODUCT_TYPES.garnish.subtypes.garnish.id]),

  [PRODUCT_TYPES.garnish.subtypes.sauce.id]:
    CS(sharedSelectors.products,
      products => products.get(PRODUCT_TYPES.garnish.id)[PRODUCT_TYPES.garnish.subtypes.sauce.id]),

  [PRODUCT_TYPES.garnish.subtypes.stick.id]:
    CS(sharedSelectors.products,
      products => products.get(PRODUCT_TYPES.garnish.id)[PRODUCT_TYPES.garnish.subtypes.stick.id]),

  [PRODUCT_TYPES.warmer.subtypes.coffee.id]:
      CS(sharedSelectors.products,
          products => products.get(PRODUCT_TYPES.warmer.id)[PRODUCT_TYPES.warmer.subtypes.coffee.id]),

  [PRODUCT_TYPES.warmer.subtypes.tea.id]:
      CS(sharedSelectors.products,
          products => products.get(PRODUCT_TYPES.warmer.id)[PRODUCT_TYPES.warmer.subtypes.tea.id]),

  [PRODUCT_TYPES.warmer.subtypes.other.id]:
      CS(sharedSelectors.products,
          products => products.get(PRODUCT_TYPES.warmer.id)[PRODUCT_TYPES.warmer.subtypes.other.id]),
};
