import React from "react";
import marked from "marked";

import "./infobox.css";


export const Infobox = ({ description, className, children, style }) => {

  if (!description && !children) {
    return null;
  }

  if (!description) {
    return (
      <div className={`infobox ${className}`} style={style}>
        {children}
      </div>
    );
  }

  return (
    <div className={`infobox ${className}`} style={style}>
      <div className="infobox-content" dangerouslySetInnerHTML={{ __html: marked(description) }}/>
    </div>
  )
};
