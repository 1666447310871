import {all, call, put, takeEvery} from "redux-saga/effects";

import {api} from "../../../api/api";
import {featureSagaCreator} from "../../../utils/featureSagaCreator";
import {changePopupProps} from "../../common/popups/_core/popupActions";
import {removeAddressError, setAddressError} from "../../address/_core/addressActions";
import {RESOLVE_MANUFACTURE} from "../../catalogs/_core/catalogConstants";


function* resolveManufacture({payload})
{
	const {result, error} = yield call(api.manufactures.resolveManufacture, payload);
	if (error) {
		let message = "Не удалось получить информацию о доступности обслуживания данного адреса. " +
			"Cовершить заказ или получить подробную информацию можно у оператора колл-центра по телефону <br/>+375 (29/25/33) 604-00-00";
		yield put(setAddressError(error.message || message));
		yield put(changePopupProps({currentManufacture: null}));
	}
	else {
		yield put(removeAddressError());
		yield put(changePopupProps({currentManufacture: result}));
	}
}

export const manufactureSaga = featureSagaCreator({
	workers: function* () {
		yield all([
			takeEvery(RESOLVE_MANUFACTURE, resolveManufacture),
		])
	},
});
