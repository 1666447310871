import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { priceBYN } from "../../../../utils/formatHelpers";
import { cartSelectors } from "../../../cart/_core/cartSelectors";
import { ROUTES } from "../../../../system/router/routeConstants";


export const HeaderBar = () => {
  const history = useHistory();

  const address = useSelector(cartSelectors.address);
  const price = useSelector(cartSelectors.priceSpecification).orderTotal || 0;
  const count = useSelector(cartSelectors.rollCount) || 0;

  const openAdressPopup = () => {
    history.push(ROUTES.popups.shipping)
  };

  return (
    <div className="w-full flex justify-between items-center cursor-pointer">
      <div className="max-w-[39%] text-t10 tab:text-t18 hover:text-textHover leading-3 tab:leading-4 pc:leading-5 text-ellipsis overflow-hidden" onClick={openAdressPopup}>
        {address.street ? `${address.street},` : "адрес доставки"} {!!address.house && address.house}
      </div>

      <div className="px-4 font-Roboto">
        <span className="font-bold text-t25 tab:text-t37">{priceBYN(price)}</span>
        <span className="relative top-[-15px] tab:top-[-20px] text-t12 tab:text-t18">{count.toString()}</span>
      </div>

      <div className="px-2 tab:px-4 py-[1px] tab:py-[3px] rounded-md cursor-pointer group
              border-2 tab:border-[3px] border-textBlack
              text-t17 tab:text-t25 text-textBlack font-bold
              hover:bg-buttonHover hover:border-buttonHover">
        <Link className="group-hover:text-textWhite" to={{hash: '#order-list', pathname: '/cart'}}>
          корзина
        </Link>
      </div>

    </div>
  );
};
