import { CS } from "../../../utils/modules/reselect";
import { sharedSelectors } from "../../shared/core/sharedSelectors";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";

export const catalogSelectors = {
    streets: CS(sharedSelectors.catalogs, catalogs => catalogs.get('streets')),
    houses: CS(sharedSelectors.catalogs, catalogs => catalogs.get('houses')),
    manufactures: CS(sharedSelectors.catalogs, catalogs => catalogs.get('manufactures')),
    vacancies: CS(sharedSelectors.catalogs, catalogs => catalogs.get('vacancies')),
    answers: CS(sharedSelectors.catalogs, catalogs => catalogs.get('answers')),
    feedbackTroubles: CS(sharedSelectors.catalogs, catalogs => catalogs.get('feedbackTroubles')),

    pickupManufactures: CS(sharedSelectors.catalogs, catalogs => catalogs.get('manufactures').filter(p => p.withPickup)),

    blockProducts: CS(sharedSelectors.catalogs, catalogs => catalogs.get('blockProducts')),
    dessertSectionDescription: CS(sharedSelectors.catalogs, catalogs => catalogs.get('blockProducts')
        .filter(item => item.systemName === PRODUCT_TYPES.dessert.id).first()),
    rollSectionDescription: CS(sharedSelectors.catalogs, catalogs => catalogs.get('blockProducts')
        .filter(item => item.systemName === PRODUCT_TYPES.roll.id).first()),
    rollsetSectionDescription: CS(sharedSelectors.catalogs, catalogs => catalogs.get('blockProducts')
        .filter(item => item.systemName === PRODUCT_TYPES.rollset.id).first()),
    soupSectionDescription: CS(sharedSelectors.catalogs, catalogs => catalogs.get('blockProducts')
        .filter(item => item.systemName === PRODUCT_TYPES.soup.id).first()),
    pokeSectionDescription: CS(sharedSelectors.catalogs, catalogs => catalogs.get('blockProducts')
        .filter(item => item.systemName === PRODUCT_TYPES.poke.id).first()),
    drinkSectionDescription: CS(sharedSelectors.catalogs, catalogs => catalogs.get('blockProducts')
        .filter(item => item.systemName === PRODUCT_TYPES.drink.id).first()),
    sauceSectionDescription: CS(sharedSelectors.catalogs, catalogs => catalogs.get('blockProducts')
        .filter(item => item.systemName === PRODUCT_TYPES.garnish.subtypes.sauce.id).first()),
    garnishSectionDescription: CS(sharedSelectors.catalogs, catalogs => catalogs.get('blockProducts')
        .filter(item => item.systemName === PRODUCT_TYPES.garnish.id).first()),
};
