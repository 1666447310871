export const ROLL_SIZES = {
  big: {
    id: 'big',
    title: '1',
    short: '1',
    displayTitle: 'целый',
  },
  medium: {
    id: 'medium',
    title: '0.5',
    short: '0.5',
    displayTitle: 'половина',
  },
};

export const POKE_SIZES = {
  big: {
    id: 'big',
    title: '1',
    short: '1',
    displayTitle: 'большой',
  },
  medium: {
    id: 'medium',
    title: '0.5',
    short: '0.5',
    displayTitle: 'стандартный',
  },
};


export const PRODUCT_TYPES = {
  roll: {
    id: 'roll',
    name: 'Ролл',
    multy: 'Роллы',
  },
  rollset: {
    id: 'rollset',
    name: 'Сет',
    multy: 'Сеты',
  },
  poke: {
    id: 'poke',
    name: 'Поке',
    multy: 'Поке',

    subtypes: {
      standard: {
        id: 'standard',
        name: 'Стандарт',
        multy: 'Стандарт',
      },
      light: {
        id: 'light',
        name: 'Лайт',
        multy: 'Лайт',
      },
      sashimi: {
        id: 'sashimi',
        name: 'Сашими',
        multy: 'Сашими',
      },
    }
  },
  auxiliary: {
    id: 'auxiliary',
    name: 'Дополнительные приборы',
    multy: 'Дополнительные приборы',
  },
  soup: {
    id: 'soup',
    name: 'Суп',
    multy: 'Супы',
  },
  bento: {
    id: 'bento',
    name: 'Бенто',
    multy: 'Бенто',
  },
  garnish: {
    id: 'garnish',
    name: 'Гарнир, соус',
    multy: 'Гарниры и соусы',

    subtypes: {
      garnish: {
        id: 'garnish',
        name: 'Гарнир',
        multy: 'Гарниры',
      },
      sauce: {
        id: 'sauce',
        name: 'Соус',
        multy: 'Соусы',
      },
      stick: {
        id: 'stick',
        name: 'Палочки',
        multy: 'Палочки',
      },
    }
  },
  drink: {
    id: 'drink',
    name: 'Напиток',
    multy: 'Напитки',
  },
  dessert: {
    id: 'dessert',
    name: 'Десерт',
    multy: 'Десерты',

    subtypes: {
      cookie: {
        id: 'cookie',
        name: 'Печенье',
        multy: 'Печенье',
      },
      cake: {
        id: 'cake',
        name: 'Торт',
        multy: 'Торты',
      },
      pie: {
        id: 'pie',
        name: 'Пирог',
        multy: 'Пироги',
      },
    }
  },
  warmer: {
    id: 'warmer',
    name: 'Горячий напиток',
    multy: 'Горячие напитки',

    subtypes: {
      tea: {
        id: 'tea',
        name: 'Чай',
        multy: 'Чай',
      },
      coffee: {
        id: 'coffee',
        name: 'Кофе',
        multy: 'Кофе',
      },
      other: {
        id: 'other',
        name: 'Другое',
        multy: 'Другое',
      },
    }
  },
  suggestedProduct: {
    id: 'suggestedProduct',
    name: '',
    multy: '',
  },
}

export const PRODUCT_PSEUDO_TYPES = {
  filter: {
    id: 'filter',
    name: 'Фильтр',
    multy: 'Фильтры',
  },
  ingredient: {
    id: 'ingredients',
    name: 'Ингредиент',
    multy: 'Ингредиенты',
  },
};

export const PRODUCT_PACKED_TYPES = [
  PRODUCT_TYPES.roll.id,
  PRODUCT_TYPES.dessert.id,
];
