import { fromJS, OrderedMap, Record } from "immutable";

import {PRODUCT_PSEUDO_TYPES, PRODUCT_TYPES} from "../../../api/products/productBaseConstants";
import {
  LOADED_BENTOS,
  LOADED_DESSERTS,
  LOADED_DRINKS,
  LOADED_FILTERS,
  LOADED_GARNISHES,
  LOADED_INGREDIENTS,
  LOADED_POKES,
  LOADED_SOUPS,
  LOADED_ROLLS,
  LOADED_WARMERS,
  LOADED_ROLLSETS,
  LOADED_SUGGESTED_PRODUCTS,
} from "./productConstants";


const initialState = fromJS({
  [PRODUCT_TYPES.roll.id]: OrderedMap(),
  [PRODUCT_TYPES.rollset.id]: OrderedMap(),
  [PRODUCT_TYPES.poke.id]: OrderedMap(),
  [PRODUCT_TYPES.soup.id]: OrderedMap(),
  [PRODUCT_TYPES.bento.id]: OrderedMap(),
  [PRODUCT_TYPES.garnish.id]: Record({
  [PRODUCT_TYPES.garnish.subtypes.garnish.id]: OrderedMap(),
  [PRODUCT_TYPES.garnish.subtypes.sauce.id]: OrderedMap(),
  [PRODUCT_TYPES.garnish.subtypes.stick.id]: OrderedMap(),
  }),
  [PRODUCT_TYPES.drink.id]: OrderedMap(),
  [PRODUCT_TYPES.dessert.id]: OrderedMap(),
  [PRODUCT_TYPES.warmer.id]: OrderedMap(),

  [PRODUCT_PSEUDO_TYPES.filter.id]: OrderedMap(),
  [PRODUCT_PSEUDO_TYPES.ingredient.id]: OrderedMap(),

  [PRODUCT_TYPES.suggestedProduct.id]: OrderedMap(),
});

export const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADED_ROLLS:
      return state.set(PRODUCT_TYPES.roll.id, payload);
    case LOADED_ROLLSETS:
      return state.set(PRODUCT_TYPES.rollset.id, payload);
    case LOADED_POKES:
      return state.set(PRODUCT_TYPES.poke.id, payload);
    case LOADED_SOUPS:
      return state.set(PRODUCT_TYPES.soup.id, payload);
    case LOADED_BENTOS:
      return state.set(PRODUCT_TYPES.bento.id, payload);
    case LOADED_GARNISHES:
      return state.set(PRODUCT_TYPES.garnish.id, payload);
    case LOADED_DRINKS:
      return state.set(PRODUCT_TYPES.drink.id, payload);
    case LOADED_DESSERTS:
      return state.set(PRODUCT_TYPES.dessert.id, payload);
    case LOADED_WARMERS:
      return state.set(PRODUCT_TYPES.warmer.id, payload);

    case LOADED_FILTERS:
      return state.set(PRODUCT_PSEUDO_TYPES.filter.id, payload);
    case LOADED_INGREDIENTS:
      return state.set(PRODUCT_PSEUDO_TYPES.ingredient.id, payload);
    case LOADED_SUGGESTED_PRODUCTS:
      return state.set(PRODUCT_TYPES.suggestedProduct.id, payload);

    default:
      return state
  }
};
