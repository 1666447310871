import { Record } from "immutable";

import { PRODUCT_TYPES } from "../../../api/products/productBaseConstants";


const WarmerModelRecord = Record({
	announce: null,
	id: null,

	isSuitableForCurrentCart: null,
	isOnTrade: null,

	position: null,
	price: 0,
	title: null,

	type: PRODUCT_TYPES.warmer.id,
	subtype: null,
});

export class WarmerModel extends WarmerModelRecord {
  //
}
