import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {cartPageSelectors} from "./_core/cartPage/cartPageSelectors";
import {Anchor} from "../components/common/Anchor";
import {CartForm} from "../components/cart/cartForm/CartForm";
import {CartList} from "../components/cart/cartList/CartList";
import {EmptyCart} from "../components/cart/EmptyCart";
import {OrderDone} from "../components/cart/OrderDone";
import {SectionTitle} from "../components/common/sectionTitle/SectionTitle";
import {cartSelectors} from "../components/cart/_core/cartSelectors";
import { WarmerSection } from "../components/products/warmers/WarmerSection";
import { GarnishSection } from "../components/products/garnishes/GarnishSection";
import { Button } from "../components/common/button/Button";
import {CookieCartSection} from "../components/products/cookies/CookieCartSection";
import {SuggestedProductsSlider} from "../components/products/suggestedProductsSlider/SuggestedProductsSlider";
import {closePopup, openPopup} from "../components/common/popups/_core/popupActions";
import {POPUP_COMPONENTS} from "../components/common/popups/popupsConfig";
import {pageSelectors} from "./_core/pagesSelectors";


export const CartPage = () => {
	const isOrderSubmitted = useSelector(cartPageSelectors.isOrderSubmitted);
	const cartHasItems = useSelector(cartSelectors.hasItems);
	const isOrderProcessing = useSelector(cartPageSelectors.isOrderProcessing) || false;
	const dispatch = useDispatch();
	const history = useHistory();
	const popup  = useSelector(pageSelectors.popup);

	useEffect(() => {
		if (history.location.hash.includes('#open-modal')) {
			dispatch(openPopup({
				type: POPUP_COMPONENTS.privacyPolicyRulesPopup.type,
			}))
		} else {
			if (popup.type === POPUP_COMPONENTS.privacyPolicyRulesPopup.type) {
				dispatch(closePopup())
			}
		}
	}, [history.location.hash])

	return (
		<>
			<Anchor id="order-list" top={-32}/>

			{isOrderSubmitted && (<OrderDone/>)}
			{!isOrderSubmitted && !cartHasItems && (<EmptyCart/>)}

			{!isOrderSubmitted && cartHasItems && (
				<>
					<SectionTitle title="оформление заказа" id="order-list"/>
					<CartList/>

					<div style={{margin: '30px', display: 'flex', justifyContent: 'flex-end'}}>
						<Link to={{
							pathname: '/cart',
							hash: '#shipping-form'
						}}>
							<Button
								disabled={isOrderProcessing}
								className="btn-primary submit-order-button">оформить
							</Button>
						</Link>
					</div>
					<CookieCartSection/>
					<GarnishSection/>
					<WarmerSection/>
					<SuggestedProductsSlider/>
					<CartForm/>
				</>
			)}
		</>
	)
};


