import React from "react";
import { useSelector } from "react-redux";

import {cartSelectors} from "../../_core/cartSelectors";


export const ToEntranceWarnings = () => {
  const address = useSelector(cartSelectors.address);

  return (
    <>
      {address.toEntrance && (
        <p className="form-note">
          по данному адресу доставка осуществляется только до центрального входа
        </p>
      )}
    </>
  )
};
