import moment from "moment";

import { __, toBool } from "../../utils/utils";
import { BepaidOrderModel } from "../../models/BepaidOrder/BepaidOrderModel";


export const bepaidOrderCreators = data => {
  const deliveryMoment = moment(data.delivery_at, "YYYY-MM-DD HH:mm:ss");

  return new BepaidOrderModel({
    deliveryDate: deliveryMoment.format("YYYY-MM-DD"),
    deliveryTimeFrom: deliveryMoment.format("HH:mm"),
    deliveryTimeTo: deliveryMoment.add(30, 'minutes').format("HH:mm"),

    createdAt: __(data.created_at),
    isPreorder: toBool(data.is_preorder),
    isDelivery: toBool(data.is_delivery),
    delivery45: toBool(data.is_delivery_45),
    fullAddress: __(data.full_address),
    selfDeliveryTime: __(data.self_delivery_time)
  });
};
