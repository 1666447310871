import Immutable from "immutable";


const CartAddressModelRecord = Immutable.Record({
	street: null,
	streetId: null,
	house: null,
	houseId: null,
	flat: null,
	floor: null,
	entrance: null,
	intercom: null,
	toEntrance: null,

});

export default class CartAddressModel extends CartAddressModelRecord {
	//
}
