import Immutable from "immutable"


const HouseModelRecord = Immutable.Record({
  value: '',
  label: '',

	isDelayIncreased: null,
	publicComment: null,
	toEntrance: null,
});

export class HouseModel extends HouseModelRecord {
  //
}
