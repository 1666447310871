import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { closePopup } from "../../../../common/popups/_core/popupActions";
import { productSelectors } from "../../../_core/productSelectors";
import { popupSelector } from "../../../../common/popups/_core/popupSelectors";
import { CircleBackground } from "../../../../common/circleBackground/CircleBackground";
import { Image } from "../../../../common/image/Image";
import { PRODUCT_TYPES } from "../../../../../api/products/productBaseConstants";
import { ROUTES } from "../../../../../system/router/routeConstants";
import {priceBYN} from "../../../../../utils/formatHelpers";
import {GarnishProductQuantity} from "../GarnishProductQuantity";


export const GarnishPopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const productId = useSelector(popupSelector.props).productId;
  const garnishes = useSelector(productSelectors[PRODUCT_TYPES.garnish.subtypes.garnish.id]);

  const product = garnishes.get(productId);

  const handleClick = () => {
    history.push(ROUTES.main.path);
    dispatch(closePopup());
  };

  return (
    <div className="popup popup--product popup--show">

      <button
        onClick={handleClick}
        className="popup__close"
        type="button"
        title="закрыть">&times;
      </button>

      <div className="popup-outer">
        <div className="popup-product">
          <h1 className="popup-product__title">{product.title.toLowerCase()}</h1>

          <ul className="popup-product-types">
            <li className="main-goods-types__list-item">
              <div className="main-goods__price-box">
                <strong className="main-goods__price">{priceBYN(product.price)}</strong>
                {product.amount ? null : <span className="main-goods__portion">{product.amount}</span>}
              </div>
            </li>
          </ul>

          <Image className="popup-product__picture"
                 publicImages={product.publicImages}
                 type="popup"
                 height={600} width={600}/>

          <GarnishProductQuantity product={product}/>

          <article className="popup-product__chars">
          <p>{product.get('announce')}</p>
          </article>
          <ul className="popup-product-ingredients">
            <li className="popup-product-ingredients__list-item">вес {product.amount}</li>
            <li className="popup-product-ingredients__list-item">калории {product.calories} ккал</li>
            <li className="popup-product-ingredients__list-item">углеводы {product.carbohydrates} г</li>
            <li className="popup-product-ingredients__list-item">белки {product.proteins} г</li>
            <li className="popup-product-ingredients__list-item">жиры {product.fats} г</li>
          </ul>
        </div>
      </div>
      <CircleBackground className="hide-on-mobile"/>
    </div>
  );
};
