import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { cartSelectors } from "../components/cart/_core/cartSelectors";
import { DessertSection } from "../components/products/desserts/DessertSection";
import { DrinkSection } from "../components/products/drinks/DrinkSection";
import { RollSection } from "../components/products/rolls/RollSection";
import { GarnishSection } from "../components/products/garnishes/GarnishSection";
import { PokeSection } from "../components/products/poke/PokeSection";
import { SoupSection } from "../components/products/soups/SoupSection";
import {RollsetSection} from "../components/products/rollsets/RollsetSection";

import "../components/products/_core/styles/productStyles";
import {InfoSlider} from "../components/settings/InfoSlider";


export const MainPage = () => {
	const cartHasItems = useSelector(cartSelectors.hasItems);
	return (
		<>
			<InfoSlider/>
			<RollsetSection/>
			<RollSection/>
      {/*<BentoSection />*/}
			<GarnishSection/>
			<PokeSection/>
			<SoupSection/>
			<DessertSection/>
			<DrinkSection/>


			{cartHasItems && (
				<div className="form-finish-button">
					<div className="form-finish-button__action">
						<Link to={{
							pathname: '/cart',
							hash: 'order-list'
						}}>
							<button
								className="btn btn-primary submit-order-button"
								type="button">
								в корзину
							</button>
						</Link>
					</div>
				</div>
			)}
		</>
	);
};


