import React from "react";

import { SauceListItem } from "./SauceListItem";
import { sortByPosition } from "../../../../utils/utils";


export const SauceList = ({ productList }) => (
  <ul className="secondary-goods__list" data-size="3">
    {productList.toList().sort(sortByPosition).map(product => (
      <SauceListItem sauce={product} key={product.id}/>
    ))}
  </ul>
);
