import React from "react";


export const PopupLayoutTabButton = ({ title, active, onClick }) => {
  return (
    <li className="popup-nav__list-item" onClick={onClick}>
      <button className={`popup-nav__item ${active ? 'popup-nav__item--selected' : ''}`}
              type="button">{title}
      </button>
    </li>
  );
};
