import { fetchApiProcess } from "../../utils/http";
import { streetsProcess } from "./creators/streetCreators";
import { housesProcess } from "./creators/houseCreators";


export const addressCallers = {
  streets: () => fetchApiProcess(['addresses', 'streets'], streetsProcess, {
		version: 'v4.0.0'
	}),
  houses: (id) => fetchApiProcess(['addresses', 'streets', id ], housesProcess, {
		version: 'v4.0.0'
	}),
};
