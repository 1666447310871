import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {CartListItem} from "../CartListItem";
import {PRODUCT_TYPES, ROLL_SIZES} from "../../../../api/products/productBaseConstants";
import {openPopup} from "../../../common/popups/_core/popupActions";
import {POPUP_COMPONENTS} from "../../../common/popups/popupsConfig";
import {productSelectors} from "../../../products/_core/productSelectors";


export const CartRollProductList = ({roll}) => {
    const dispatch = useDispatch();
    const rollList = useSelector(productSelectors[PRODUCT_TYPES.roll.id]);
    const open = (rollId) => () => {
        dispatch(openPopup({
            type: POPUP_COMPONENTS.rollPopup.type,
            props: {rollId}
        }));
    };

    return (
        <>
            {roll.valueSeq().map(rollItem => {
                    const product = rollList.get(rollItem.id)
                    return <CartListItem key={rollItem.id + '_rollItem'}
                                         hideAnnounce
                                         onClick={open(rollItem.id)}
                                         item={rollItem}
                                         size={product.hasMedium ? ROLL_SIZES.medium.id : ROLL_SIZES.big.id}
                                         type={PRODUCT_TYPES.roll.id}
                    />
                }
            )}
        </>
    )
};
