import { Record } from "immutable";


const IngredientModelRecord = Record({
  id: '',
  title: '',
});

export default class IngredientModel extends IngredientModelRecord {
  //
}
