import React, {useEffect} from "react";
import plural from "plural-ru";
import {useDispatch, useSelector} from "react-redux";

import {Anchor} from "../../common/Anchor";
import {GarnishList} from "./garnish/GarnishList";
import {Infobox} from "../../common/notifications/infobox/Infobox";
import {SauceList} from "./sauce/SauceList";
import {SectionTitle} from "../../common/sectionTitle/SectionTitle";
import {productSelectors} from "../_core/productSelectors";
import {PRODUCT_TYPES} from "../../../api/products/productBaseConstants";
import {cartSelectors} from "../../cart/_core/cartSelectors";
import {catalogSelectors} from "../../catalogs/_core/catalogSelectors";
import {SectionDescription} from "../../common/sectionDescription/SectionDescription";
import {useHistory} from "react-router-dom";
import {pageSelectors} from "../../../pages/_core/pagesSelectors";
import {closePopup, openPopup} from "../../common/popups/_core/popupActions";
import {POPUP_COMPONENTS} from "../../common/popups/popupsConfig";


export function GarnishSection()
{
	const sauce = useSelector(productSelectors[PRODUCT_TYPES.garnish.subtypes.sauce.id]);
	const gaineshes = useSelector(productSelectors[PRODUCT_TYPES.garnish.subtypes.garnish.id]);
	const stick = useSelector(productSelectors[PRODUCT_TYPES.garnish.subtypes.stick.id]);
	const rollCount = useSelector(cartSelectors.rollCount);
	const freeItemsRemaining = useSelector(cartSelectors.additionalData).freeItemsRemaining;
	let sauceSectionDescription = useSelector(catalogSelectors.sauceSectionDescription)
	let garnishSectionDescription = useSelector(catalogSelectors.garnishSectionDescription)
	const dispatch = useDispatch();
	const history = useHistory();
	const popup  = useSelector(pageSelectors.popup);

	useEffect(() => {
		if (history.location.pathname.includes('garnish')) {
			const productId = history.location.pathname.split('/')[2];
			dispatch(openPopup({
				type: POPUP_COMPONENTS.garnishPopup.type,
				props: { productId }
			}))
		} else {
			if (popup.type === POPUP_COMPONENTS.garnishPopup.type) {
				dispatch(closePopup())
			}
		}

		if (history.location.pathname.includes('sauce')) {
			const productId = history.location.pathname.split('/')[2];
			dispatch(openPopup({
				type: POPUP_COMPONENTS.saucePopup.type,
				props: { productId }
			}))
		} else {
			if (popup.type === POPUP_COMPONENTS.saucePopup.type) {
				dispatch(closePopup())
			}
		}
	}, [history.location.pathname])

	const displayHint = !!freeItemsRemaining.get('garnish') || !!freeItemsRemaining.get('stick');
	const nbsp = '\xa0';

	return (
		<>
			<div className="secondary-goods">
				<Anchor id="garnish" top={-120}/>
				{garnishSectionDescription && garnishSectionDescription.description
					&& <SectionDescription description={garnishSectionDescription.description}/>}
				<SectionTitle title="гарнир " count={freeItemsRemaining.get('garnish')}/>

				{displayHint && (
					<Infobox className="notification notification--goods">
						{`в вашем заказе ${rollCount}${nbsp}${plural(rollCount, 'ролл', 'ролла', 'роллов')}, вы можете выбрать еще `}
						{!!freeItemsRemaining.get('garnish') &&
						`${freeItemsRemaining.get('garnish')}${nbsp}${plural(freeItemsRemaining.get('garnish'), 'любой', 'любых', 'любых')} ${plural(freeItemsRemaining.get('garnish'), 'гарнир', 'гарнира', 'гарниров')}`
						}

						{!!freeItemsRemaining.get('garnish') && !!freeItemsRemaining.get('stick') && (
							<span> и&nbsp;</span>)}

						{!!freeItemsRemaining.get('stick') &&
						`${freeItemsRemaining.get('stick')}${nbsp}${plural(freeItemsRemaining.get('stick'), 'комплект', 'комплекта', 'комплектов')} палочек`
						}

						{` по цене 1${nbsp}копейка`}
					</Infobox>
				)}

				<GarnishList
					productList={gaineshes}
					stick={stick.get(stick.keySeq().first())}
				/>
			</div>

			<div className="secondary-goods">
				<Anchor id="sauce" top={-120}/>
				<SectionTitle title="Соусы"/>
				{sauceSectionDescription && sauceSectionDescription.description
					&& <SectionDescription description={sauceSectionDescription.description}/>}
				<SauceList productList={sauce}/>
			</div>
		</>
	)
}
