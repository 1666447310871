import moment from "moment";

import { __ } from "../../utils/utils";

export const preorderCreator = data => {
    const disabledPickupDays = [];
    const disabledDeliveryDays = [];

    for (let dayKey in data) {
        if (dayKey !== 'ttl' && dayKey !== 'protected_at') {
            data[dayKey].disabledPickupHours = [];
            data[dayKey].disabledDeliveryHours = [];
            const [year, month, day] = dayKey.split('-');

            for (let hourKey in data[dayKey]) {
                for (let minuteKey in data[dayKey][hourKey]) {

                    if (data[dayKey][hourKey][minuteKey].delivery) {
                        data[dayKey].isDayAvailableForDelivery = true;
                    } else {
                        data[dayKey].disabledDeliveryHours.push(moment().year(+year).month(month).date(+day).hour(+hourKey).minute(+minuteKey).toDate());
                    }

                    if(data[dayKey][hourKey][minuteKey].pickup) {
                        data[dayKey].isDayAvailableForPickup = true;
                    } else {
                        data[dayKey].disabledPickupHours.push(moment().year(+year).month(month).date(+day).hour(+hourKey).minute(+minuteKey).toDate());
                    }
                }
            }
            if (!data[dayKey].isDayAvailableForDelivery) {
                disabledDeliveryDays.push(moment().year(+year).month(month).date(+day).toDate());
            }
            if (!data[dayKey].isDayAvailableForPickup) {
                disabledPickupDays.push(moment().year(+year).month(month).date(+day).toDate());
            }
        }
    }
    return {
        ttl: __(data.ttl),
        protectedAt: __(data.protected_at),
        data: {
            disabledPickupDays,
            disabledDeliveryDays,
            ...data,
        }
    };
}
