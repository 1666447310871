import { put, select } from "redux-saga/effects";

import { delay } from "../../../../utils/utils";
import { changePopupProps, closePopup } from "./popupActions";
import { pageSelectors } from "../../../../pages/_core/pagesSelectors";
import { popupSelector } from "./popupSelectors";
import { routerHistorySelector } from "../../../../system/router/routerHistoryReducer";
import { push } from "connected-react-router";


export const DEFAULT_WARNING_DELAY_MS = 5000;
export const DEFAULT_ERROR_DELAY_MS = 5000;
export const DEFAULT_NOTIFICATION_DELAY_MS = 5000;

export function* closePopupSafe()
{
  const popupParams = yield select(pageSelectors.popup);
  if (popupParams.type) {
    const routerHistory = yield select(routerHistorySelector);
    yield put(push(routerHistory.previousLocation.pathname || '/'));
    yield put(closePopup());
  }
}

export function* showPopupNotification(notification, ms = DEFAULT_NOTIFICATION_DELAY_MS)
{
	yield put(changePopupProps({notification}));
	if (ms) {
		yield delay(ms);
		const popupProps = yield select(popupSelector.props);

		if (popupProps.notification === notification) {
			yield put(changePopupProps({
				notification: null
			}));
		}
	}
}

export function* showPopupWarning(warning, ms = DEFAULT_WARNING_DELAY_MS)
{
	yield put(changePopupProps({warning}));
	if (ms) {
		yield delay(ms);
		const popupProps = yield select(popupSelector.props);

		if (popupProps.warning === warning) {
			yield put(changePopupProps({
				warning: null
			}));
		}
	}
}

export function* showPopupError(error, ms = DEFAULT_ERROR_DELAY_MS)
{
	yield put(changePopupProps({error}));
	if (ms) {
		yield delay(ms);
		const popupProps = yield select(popupSelector.props);

		if (popupProps.error === error) {
			yield put(changePopupProps({
				error: null
			}));
		}
	}
}
