import { OrderedMap } from "immutable";

import { __ } from "../../../utils/utils";
import IngredientModel from "../../../models/Products/Ingredients/IngredientModel";


export const ingredientCreator = data => new IngredientModel({
  id: __(data.id),
  title: data.title,
});

export const ingredientsProcess = (data) => {
  return ( new OrderedMap() ).withMutations(map => {
    if (data && Array.isArray(data)) {
      data.forEach(item => map.set(__(item.id), ingredientCreator(item)))
    }
  })
};
