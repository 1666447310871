import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { catalogSelectors } from "../catalogs/_core/catalogSelectors";
import { sortByPosition } from "../../utils/utils";
import { AnswersListItem } from "./AnswersListItem";
import { scrollToAnchor } from "../../utils/scrollToAnchor";
import { useLocation } from "react-router";


export const AnswersList = () => {
    const answers = useSelector(catalogSelectors.answers);
    const hash = useLocation().hash;

    useEffect(() => {
        scrollToAnchor(hash)
    }, [])

    if (!answers.size) {
        return (
            <>
                здесь пока ничего нет
            </>
        )
    }

    return (
        <>
            {
                answers
                    .valueSeq()
                    .toArray()
                    .sort(sortByPosition)
                    .map(answer => (
                        <AnswersListItem answer={answer} key={answer.get('id')}/>
                    ))
            }
            <br/>
        </>
    )
};
