import {createAction} from '@reduxjs/toolkit'
import {
	SUCCESS_ORDER_REQUESTING,
	SUCCESS_ORDER_LOADED_WITH_ERROR,
	SUCCESS_ORDER_LOADED,
	FAIL_ORDER_REQUESTING,
	FAIL_ORDER_LOADED,
	FAIL_ORDER_LOADED_WITH_ERROR,
} from "./bepaidPageConstants";


export const successOrderRequesting = createAction(SUCCESS_ORDER_REQUESTING);
export const successOrderLoadedWithError = createAction(SUCCESS_ORDER_LOADED_WITH_ERROR);
export const successOrderLoaded = createAction(SUCCESS_ORDER_LOADED);

export const failOrderRequesting = createAction(FAIL_ORDER_REQUESTING);
export const failOrderLoadedWithError = createAction(FAIL_ORDER_LOADED_WITH_ERROR);
export const failOrderLoaded = createAction(FAIL_ORDER_LOADED);
