import React from "react";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";


export const CartSoupProductList = ({ soup }) => {
  return (
    <>
      {soup.valueSeq().map(soupItem => {
        return (
          <CartListItem key={`soup_${soupItem.get('id')}`}
                        item={soupItem}
						            hideAnnounce
                        amount={soup.amount}
                        type={PRODUCT_TYPES.soup.id}
					/>
				)
			})}
		</>
	)
};
