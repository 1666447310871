import React from 'react';
import { useSelector } from "react-redux";

import { titleFormatter } from "../../../utils/titleFormatter";
import { cartSelectors } from "../../cart/_core/cartSelectors";
import { priceBYN } from "../../../utils/formatHelpers";
import { DrinkProductQuantity } from "../drinks/DrinkProductQuantity";


export const WarmerListItem = ({warmer}) => {
    const cartItem = useSelector(state => cartSelectors.cartItem(state, warmer));
    const productInCart = cartItem.count ? 'incart' : 'none';

    if (!warmer.isOnTrade || !warmer.isSuitableForCurrentCart) {
        return null;
    }

    return (
        <li className="additional-goods__list-item" data-item={productInCart}>
            <div className="additional-goods__item">

                <h3 className="warmer-goods__title">
                    <span>{titleFormatter(warmer.title)}</span>
                </h3>
                <p className="additional-goods__size">{warmer.announce}</p>
                <p className="additional-goods__price">{priceBYN(warmer.price)}</p>

                <DrinkProductQuantity product={warmer}/>
            </div>
        </li>
    )
}
