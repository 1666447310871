import { Record } from "immutable";

const SuggestedProductModelRecord = Record({
  id: null,
  productId: null,
  productType: null,
  position: null,
});

export default class SuggestedProductModel extends SuggestedProductModelRecord {
  //
}
