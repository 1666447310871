import React from 'react';
import {PRICE_PENNY, priceBYN} from "../../../utils/formatHelpers";

export const GarnishProductPrice = ({garnish, freeGarnishesCount}) => {
	return (
		<>
			{freeGarnishesCount ? (
					<div className="secondary-goods__price">
						<small className="secondary-goods__discount">{priceBYN(garnish.price)}</small>
						<div className="secondary-goods__title-box">
							<strong>{PRICE_PENNY}</strong>
							<small className="secondary-goods__amount">{garnish.amount}</small>
						</div>
					</div>
				)
				: (
					<div className="secondary-goods__price">
						<div className="secondary-goods__title-box">
							<strong>{priceBYN(garnish.price)}</strong>
							<small className="secondary-goods__amount">{garnish.amount}</small>
						</div>
					</div>
				)
			}
		</>)
}
