import React from "react";

import { CartListItem } from "../CartListItem";
import { PRODUCT_TYPES } from "../../../../api/products/productBaseConstants";


export const CartGarnishProductList = ({ garnish }) => {
  return (
    <>
      {garnish.valueSeq().map(garnishItem => (
        <CartListItem key={`garnish_${garnishItem.get('id')}`}
                      item={garnishItem}
					  hideAnnounce
                      type={PRODUCT_TYPES.garnish.id}
                      subtype={PRODUCT_TYPES.garnish.subtypes.garnish.id}
				/>
			))}
		</>
	)
};
