import { Record } from "immutable";


const SettingsModelRecord = Record({
  baskedDisabled: null,

  halvaDisabled: null,
  halvaPreorderDisabled: null,
  delivery45: null,
  isWorkingTime: null,

  minimalPrice: null,
	minimalPriceAction: null,
	minimalPriceActionTimeFrom: null,
	minimalPriceActionTimeTo: null,

  nonContactDeliveryDisabled: null,
  onlineDisabled: null,
  onlinePreorderDisabled: null,
  paymentDyCardDisabled: null,
  showTestControls: null,
  stopInformer: null,
  workTimeFrom: null,
  workTimeTo: null,

  isFeedbackEnabled: null,
  promoCookieDisabled: true,
});

export default class SettingsModel extends SettingsModelRecord {
  //
}
