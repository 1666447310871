import React, { useState } from "react";

import { SectionTitle } from "../../common/sectionTitle/SectionTitle";
import { SubmitSection } from "./formComponents/SubmitSection";
import { Anchor } from "../../common/Anchor";
import { NameInput } from "./formComponents/NameInput";
import { PhoneInput } from "./formComponents/PhoneInput";
import { AddressInput } from "./formComponents/AddressInput";
import { PreorderInput } from "./formComponents/preorderInput/PreorderInput";
import { CommentInput } from "./formComponents/CommentInput";
import { PaymentInput } from "./formComponents/PaymentInput";
import "./shipping-form.css";
import { TotalInput } from "./formComponents/TotalInput";
import { NoContactDeliveryInput } from "./formComponents/NoContactDeliveryInput";


export const CartForm = () => {
  const [deliveryTimeSelected, setDeliveryTime] = useState(true);

  return ( <>
    <SectionTitle title="адрес доставки"/>
    <Anchor id="shipping-form" top={-135}/>

    <div className="cart-shipping">
      <NameInput/>
      <PhoneInput/>
			<AddressInput/>
            <CommentInput/>
			<PreorderInput deliveryTimeSelected={deliveryTimeSelected} setDeliveryTime={setDeliveryTime}/>
		</div>

    <NoContactDeliveryInput/>

    <SectionTitle title="способ оплаты"/>
    <div className="form-payments-and-total">
      <PaymentInput/>
      <TotalInput/>
    </div>

    <SubmitSection deliveryTimeSelected={deliveryTimeSelected}/>
  </> )
};
