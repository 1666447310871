import { OrderedMap } from "immutable";

import { __, toBool } from "../../../utils/utils";
import { HouseModel } from "../../../models/Address/House/HouseModel";
import { houseComparator } from "../../../components/address/addressFormComponents/houseComparator";


export const housesCreator = data => new HouseModel({
  value: __(data.id),
  label: data.title,

	isDelayIncreased: toBool(data.is_delay_increased),
	publicComment: __(data.public_comment),
	toEntrance: toBool(data.to_entrance),
});

export const housesProcess = data => OrderedMap().withMutations(houseMap => {
	Object.entries(data).sort(houseComparator).forEach(([id, item]) => {
		return houseMap.set(id, housesCreator(item))
	})
});
