import React from "react";
import marked from "marked";

import "./error.css";


export const Error = ({ description, title, className, style, descriptionClassName, children}) => {
  if (!description && !title) {
    return null;
  }

  return (
      <div className={`error-notification ${className}`} style={style}>
          <div className="error-notification__title">
              {title}
          </div>
          <div className="error-description">
              {description && (
                  <div className={descriptionClassName} dangerouslySetInnerHTML={{__html: marked(description)}} />
              )}
              {children}
          </div>
      </div> )
};
