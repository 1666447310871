import { CS } from "../../utils/modules/reselect";


const pages = state => state.get('pages');

export const pageSelectors = {
  cartPage: CS(pages, shared => shared.get('cartPage')),
  popup: CS(pages, shared => shared.get('popup')),
  bePaidPage: CS(pages, shared => shared.get('bePaidPage')),
};
